import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import { createData, deleteData, fetchData, fetchEditData } from "../../generators/generateThunks";

const SLICE_NAME = 'warOfCl';

export const fetchWarOfCls = fetchData.generateThunk(SLICE_NAME,SLICE_NAME);
export const fetchWarOfCl = fetchEditData.generateThunk(SLICE_NAME,SLICE_NAME);
export const createWarOfCl = createData.generateThunk(SLICE_NAME,SLICE_NAME);
export const deleteWarOfCl = deleteData.generateThunk(SLICE_NAME,SLICE_NAME);

const warOfClSlice = createSlice({
    name : SLICE_NAME,
    initialState : generateStateSingle(SLICE_NAME),
    extraReducers : {
        ...fetchData.generateExtraReducers( fetchWarOfCls , SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchWarOfCl ,SLICE_NAME),
        ...createData.generateExtraReducers(createWarOfCl, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteWarOfCl, SLICE_NAME)
    }
})

export const getWarOfCls = (state) => state.warOfCl;

export default warOfClSlice.reducer;