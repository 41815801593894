import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';

const ViewDetailsOfParticipants = ({
    requestData,
    showApprovedModal,
    setShowApprovedModal,
}) => {
    return (
        <ModalBasic
            modalOpen={showApprovedModal}
            setModalOpen={setShowApprovedModal}
        >
           
            <div className="flex sm:flex-row flex-col gap-4">
                {/* <div className="p-4 w-1/4">
                    <h1 className="font-bold text-red-500 mb-2">
                        Team
                    </h1>
                    <div className="flex flex-row gap-4 text-sm">
                        <p className='text-slate-200'>
                            <span className="font-medium text-red-500"> Team Name : </span>
                            {`${requestData?.collegeCode}`}
                        </p>
                    </div>
                </div> */}
                <div className="p-4 w-3/4">
                    <h1 className="font-bold text-red-500 mb-2">
                    {`${requestData?.collegeCode}`}{" "}Participants Details
                    </h1>
                    {requestData?.participants?.map((member) => (
                        <div className="flex flex-col sm:flex-row sm:gap-4 mb-2 text-sm" key={member?._id}>
                            <p className='w-full text-slate-200'>
                                <span className="font-medium text-red-500"> Name : </span>
                                {`${member?.firstName} ${member?.lastName}`}
                            </p>
                            <p className="font-medium w-full text-red-500">Phone No. :
                                <a
                                    href={`tel:${member?.contactNumber}`}
                                    className='font-normal text-slate-200'
                                >
                                     {` ${member?.contactNumber}`}
                                </a>
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </ModalBasic>
    );
};

export default ViewDetailsOfParticipants;
