import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../../utils/axiosConfig';
import qs from 'qs';
const initialState = {
    assets: [],
    folders: [],
    selectedAssets: [],
    mediaLibraryOpen: false,
    addAssetsModalOpen: false,
    listAssetsModalOpen: false,
    loading: false,
    baseUrl: '',
    mediaLibraryPage: 'image',
    addFolderModalOpen: false,
    isFolderDeleted: false,
};
export const fetchAssets = createAsyncThunk(
    'mediaLibrary/fetchAssets',
    async (data, { rejectWithValue }) => {
        try {
            const string = qs.stringify(data);
            console.log(data);
            const resp = await authAxiosInstance.get(`mediaLibrary?${string}`);
            console.log(resp.data);
            return resp.data;
        } catch (error) {
            console.error(error);
            toast.error('failed to fetch assets');
            return rejectWithValue(JSON.stringify(error));
        }
    }
);
export const fetchAssetsRaw = createAsyncThunk(
    'mediaLibrary/fetchAssetsRaw',
    async (data, { rejectWithValue }) => {
        try {
            const string = qs.stringify(data);
            console.log(data);
            const resp = await authAxiosInstance.get(
                `mediaLibrary/search?${string}`
            );
            console.log(resp.data);
            return resp.data;
        } catch (error) {
            console.error(error);
            toast.error('failed to fetch assets');
            return rejectWithValue(JSON.stringify(error));
        }
    }
);
export const createFolder = createAsyncThunk(
    'mediaLibrary/createFolder',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const resp = await authAxiosInstance.post(
                `/mediaLibrary/addFolder`,
                data
            );
            dispatch(openFolderModal(false));
            dispatch(fetchAssets({ currentFolder: data.currentFolder }));
            console.log(resp.data);
            return resp.data;
        } catch (error) {
            console.error(error);
            toast.error('failed to create folder');
            return rejectWithValue(JSON.stringify(error));
        }
    }
);
export const createObject = createAsyncThunk(
    'mediaLibrary/createObject',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const resp = await authAxiosInstance.post(`/mediaLibrary`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log(resp.data);
            return resp.data;
        } catch (error) {
            console.error(error);
            toast.error('failed to create object');
            return rejectWithValue(JSON.stringify(error));
        }
    }
);
export const deleteObject = createAsyncThunk(
    'mediaLibrary/deleteObject',
    async (data, { rejectWithValue, dispatch, getState }) => {
        try {
            const confirm = window.confirm(
                'are you sure you want to delete this object'
            );
            if (confirm) {
                console.log(getState());
                const string = qs.stringify(data);
                const resp = await authAxiosInstance.delete(
                    `/mediaLibrary?${string}`
                );
                dispatch(fetchAssets({ currentFolder: data.currentFolder }));
                toast.success('object deleted');
                return resp.data;
            }
        } catch (error) {
            console.error(error);
            toast.error('failed to create folder');
            return rejectWithValue(JSON.stringify(error));
        }
    }
);
export const deleteSingleObject = createAsyncThunk(
    'mediaLibrary/deleteObject',
    async (data, { rejectWithValue, dispatch, getState }) => {
        try {
            const confirm = window.confirm(
                'are you sure you want to delete this object'
            );
            if (confirm) {
                console.log(getState());
                const string = qs.stringify(data);
                const resp = await authAxiosInstance.delete(
                    `/mediaLibrary?${string}`
                );
                toast.success('object deleted');
                return resp.data;
            }
        } catch (error) {
            console.error(error);
            toast.error('failed to create folder');
            return rejectWithValue(JSON.stringify(error));
        }
    }
);
const mediaLibrarySlice = createSlice({
    name: 'mediaLibrary',
    initialState,
    reducers: {
        openFolderModal: (state, action) => {
            state.addFolderModalOpen = action.payload;
        },
        openAssetsModal: (state, action) => {
            state.addAssetsModalOpen = action.payload;
        },
        openListAssetsModal: (state, action) => {
            state.listAssetsModalOpen = action.payload;
        },
        setMediaPage: (state, action) => {
            state.mediaLibraryPage = action.payload;
        },
        selectAsset: (state, action) => {
            state.selectedAssets.push(action.payload);
        },
        deselectAsset: (state, action) => {
            const newAssets = state.selectedAssets.filter(
                (asset) => asset !== action.payload
            );
            state.selectedAssets = newAssets;
        },
        clearAssets: (state, action) => {
            state.selectedAssets = [];
        },
    },
    extraReducers: {
        [fetchAssets.pending]: (state, action) => {
            state.isFolderDeleted = false;
            state.loading = true;
        },
        [fetchAssets.fulfilled]: (state, action) => {
            state.loading = false;

            state.baseUrl = action.payload.data.baseUrl;
            state.assets = action.payload.data.resp.Contents;
            state.folders = action.payload.data.resp.CommonPrefixes.map(
                (prefix) => prefix.Prefix.slice(0, -1)
            );
        },
        [fetchAssets.rejected]: (state, action) => {
            state.loading = false;
            if (JSON.parse(action.payload).status === 404) {
                state.isFolderDeleted = true;
            }
        },
        [fetchAssetsRaw.pending]: (state, action) => {
            state.isFolderDeleted = false;
            state.loading = true;
        },
        [fetchAssetsRaw.fulfilled]: (state, action) => {
            console.log(action.payload);
            state.loading = false;
            state.assets = action.payload.data;
        },
        [fetchAssetsRaw.rejected]: (state, action) => {
            state.loading = false;
            if (JSON.parse(action.payload).status === 404) {
                state.isFolderDeleted = true;
            }
        },

        [createFolder.pending]: (state, action) => {
            state.loading = true;
        },
        [createFolder.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [createFolder.rejected]: (state, action) => {
            state.loading = false;
        },
        [createObject.pending]: (state, action) => {
            state.loading = true;
        },
        [createObject.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [createObject.rejected]: (state, action) => {
            state.loading = false;
        },
        [deleteObject.pending]: (state, action) => {
            state.loading = true;
        },
        [createFolder.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [createFolder.rejected]: (state, action) => {
            state.loading = false;
        },
    },
});

export const getMediaLibrary = (state) => state.mediaLibrary;
export const {
    openFolderModal,
    openAssetsModal,
    setMediaPage,
    openListAssetsModal,
    selectAsset,
    deselectAsset,
    clearAssets,
} = mediaLibrarySlice.actions;
export default mediaLibrarySlice.reducer;
