import { useFormik } from 'formik';
import { object, string } from 'yup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { FEATURES_TYPE } from '../../utils/dropdownOptions';
import { useDispatch, useSelector } from 'react-redux';
import {
    createFeature,
    fetchFeatures,
} from '../../app/reducers/Features/featuresSlice';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { generateOptions } from '../../utils/Utils';
import {
    fetchRole,
    fetchRoles,
    getRoles,
} from '../../app/reducers/Role/roleSlice';
import { useEffect } from 'react';

const AddFeatures = () => {
    const { role } = useSelector(getRoles);
    const dispatch = useDispatch();
    useEffect(() => dispatch(fetchRoles({ type: 'Internal',sort :{name:1} })), []);
    const formik = useFormik({
        initialValues: {
            name: '',
            type: '',
            head: '',
            allowedRoles: [],
            status: 'active',
        },
        validationSchema: object({
            name: string().required(),
            type: string().required(),
        }),
        onSubmit: (values, { resetForm }) => {
            console.log('values', values);
            dispatch(createFeature(values));
            resetForm();
        },
    });

    return (
        <PageWithCard heading="Add Features">
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md"
            >
                <FormikInputGroup
                    name="name"
                    label=" Feature Name"
                    formik={formik}
                    required
                />
                <FormikSelectGroup
                    name="type"
                    label="Type"
                    formik={formik}
                    options={FEATURES_TYPE}
                />
                <FormikInputGroup
                    name="head"
                    label="Head"
                    required
                    formik={formik}
                />
                <FormikMultiSelect
                    name="allowedRoles"
                    label="Allowed Roles"
                    formik={formik}
                    options={generateOptions({
                        array: role ? role?.docs : [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                />
                <div>
                    <PrimaryButton type="submit">Add Feature</PrimaryButton>
                </div>
            </form>
        </PageWithCard>
    );
};

export default AddFeatures;
