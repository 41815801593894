import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    editData,
    deleteData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';

const SLICE_NAME = 'college';

export const fetchColleges = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createCollege = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchCollege = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editCollege = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteCollege = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);

const collegeSlice = createSlice({
    initialState: generateStateSingle(SLICE_NAME),
    name: SLICE_NAME,
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchColleges, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchCollege, SLICE_NAME),
        ...createData.generateExtraReducers(createCollege, SLICE_NAME),
        ...editData.generateExtraReducers(editCollege, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteCollege, SLICE_NAME),
    },
});

export const getColleges = (state) => state.colleges;

export default collegeSlice.reducer;
