import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { array, number, object, string } from 'yup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { createCollege } from '../../app/reducers/College/collegeSlice';
import { generateOptions } from '../../utils/Utils';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { LEVEL_TYPE, ROLE_TYPE } from '../../utils/dropdownOptions';
import {
    createRole,
    fetchRoles,
    getRoles,
} from '../../app/reducers/Role/roleSlice';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import FormikCheckbox from '../../components/formik/FormikCheckbox';

const AddRole = () => {
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            name: '',
            type: '',
            sharedRole: false,
        },
        validationSchema: object({
            name: string().required(),
            type: string().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            dispatch(createRole(values));
            resetForm();
        },
    });
    const { role, loading } = useSelector(getRoles);
    const data = useMemo(() => (role?.docs ? role.docs : []), [role]);
    useEffect(() => {
        dispatch(fetchRoles({ type: 'Internal' }));
    }, []);
    return (
        <PageWithCard heading="Add Role">
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md"
            >
                <FormikInputGroup
                    name="name"
                    label="Role Name"
                    formik={formik}
                    required
                />
                <FormikSelectGroup
                    name="type"
                    label="Type"
                    formik={formik}
                    options={ROLE_TYPE}
                />
                <FormikCheckbox
                    name="sharedRole"
                    label="Shared Role"
                    formik={formik}
                />
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </PageWithCard>
    );
};

export default AddRole;
