import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFeatures } from '../app/reducers/Features/featuresSlice';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const OpenAccess = ({ allowedFeatures }) => {
    const location = useLocation();
    const { features } = useSelector(getFeatures);

    const [canShowPage, setCanShowPage] = useState(true);
    const computeActiveCondition = () => {
        console.log('computeActiveCondition');
        if (features?.docs?.length) {
            let canShow = true;
            allowedFeatures.forEach((ele) => {
                const feature = features.docs.find(
                    (feature) => feature._id === ele
                );
                console.log(feature);
                canShow = canShow && feature.status === 'active';
            });
            setCanShowPage(canShow);
        }
    };
    useEffect(() => {
        console.log('use effect');
        computeActiveCondition();
    }, [features, location.pathname]);

    return <>{canShowPage ? <Outlet /> : <Navigate to={'/notActivated'} />}</>;
};

export default OpenAccess;
