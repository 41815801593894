import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { createUser, getUsers } from '../../app/reducers/Users/usersSlice';
import FormikFileInput from '../../components/formik/FormikFileInput';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import {
    createEmployee,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import {
    fetchColleges,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import {
    fetchRole,
    fetchRoles,
    getRoles,
} from '../../app/reducers/Role/roleSlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { ClipLoader } from 'react-spinners';
import {
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { COURSES_TYPE, YEAR_TYPE } from '../../utils/dropdownOptions';
import { SUPER_USER_ROLE } from '../../utils/constants';
import FormikINPhoneInput from '../../components/formik/FormikINPhoneInput';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const AddEmployee = () => {
    const { user } = useSelector(getAuth);
    const { loading } = useSelector(getEmployees);
    const { college, loading: collegeLoading } = useSelector(getColleges);
    const { role, loading: roleLoading } = useSelector(getRoles);
    const { committee, loading: committeeLoading } = useSelector(getCommittees);

    const dispatch = useDispatch();

    const collegeData = useMemo(
        () => (college?.docs ? college.docs : []),
        [college]
    );
    const roleData = useMemo(() => (role?.docs ? role.docs : []), [role]);
    const committeeData = useMemo(
        () => (committee?.docs ? committee.docs : []),
        [committee]
    );

    useEffect(() => {
        dispatch(fetchColleges());
        dispatch(fetchRoles());
        dispatch(fetchCommittees());
    }, []);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            username: '',
            // rollNo: '',
            // college: '',
            // courses: '',
            // year: '',
            // role: '',
            // committee: '',
            status: true,
            isSuperUser: false,
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .matches(/^[A-Z][a-z]*$/, 'invalid format')
                .required(),
            lastName: Yup.string()
                .matches(/^[A-Z][a-z]*$/, 'invalid format')
                .required(),
            email: Yup.string().required(),
            password: Yup.string().required(),
            confirmPassword: Yup.string()
                .required()
                .oneOf([Yup.ref('password')], 'Password must be same'),
            username: Yup.string('Should be uniq').min(10).max(10).required(),
            // rollNo: Yup.string().required(),
            // college: Yup.string().required(),
            // courses: Yup.string().required(),
            // year: Yup.string().required(),
            // role: Yup.string(),
            // committee: Yup.string(),
        }),
        onSubmit: async (values, { resetForm }) => {
            dispatch(
                createEmployee({
                    ...values,
                    contactNumber: values?.username,
                    role: SUPER_USER_ROLE,
                })
            );
            resetForm();
        },
    });
    return (
        <PageWithCard heading="Add Super User">
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4  bg-black p-4 rounded sm:rounded-md"
            >
                <div className="flex flex-col gap-4 sm:flex-row w-full">
                    <div className="w-full">
                        <FormikInputGroup
                            name="firstName"
                            label="First Name"
                            formik={formik}
                            required
                        />
                    </div>
                    <div className="w-full">
                        <FormikInputGroup
                            name="lastName"
                            label="Last Name"
                            formik={formik}
                            required
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-4 sm:flex-row w-full">
                    <div className="w-full">
                        <FormikInputGroup
                            name="email"
                            label="Email"
                            formik={formik}
                            required
                            autocomplete="new-email"
                        />
                    </div>
                    <div className="w-full">
                        <FormikINPhoneInput
                            name="username"
                            label="Contact Number"
                            formik={formik}
                            required
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-4 sm:flex-row w-full">
                    <div className="w-full">
                        <FormikInputGroup
                            autocomplete="new-password"
                            name="password"
                            type="password"
                            label="Password"
                            formik={formik}
                            required
                        />
                    </div>
                    <div className="w-full">
                        <FormikInputGroup
                            name="confirmPassword"
                            formik={formik}
                            label="Confirm Password"
                            required
                            type="password"
                        />
                    </div>
                </div>

                <FormikCheckbox name="status" label="Status" formik={formik} />

                <FormikCheckbox
                    name="isSuperUser"
                    label="isDeveloper"
                    formik={formik}
                />

                {['Super User', 'Developer'].includes(user?.role?.name) && (
                    <div className="text-center">
                        <PrimaryButton type="submit" className="w-[25%]">
                            Submit
                        </PrimaryButton>
                    </div>
                )}

                {/* <FormikInputGroup
                    name="rollNo"
                    label="Roll No"
                    formik={formik}
                    required
                />
                {collegeLoading ? (
                    <ClipLoader color="#FFFFFF" size={18} />
                ) : (
                    <FormikSelectGroup
                        name="college"
                        label="College"
                        formik={formik}
                        options={generateOptions({
                            array: collegeData,
                            valueField: '_id',
                            labelField: 'name',
                        })}
                    />
                )}
                <FormikSelectGroup
                    name="courses"
                    label="Courses"
                    formik={formik}
                    required
                    options={COURSES_TYPE}
                />
                <FormikSelectGroup
                    name="year"
                    label="Year"
                    formik={formik}
                    required
                    options={YEAR_TYPE}
                />
                {committeeLoading ? (
                    <ClipLoader color="#FFFFFF" size={18} />
                ) : (
                    <FormikSelectGroup
                        name="committee"
                        label="Committee"
                        formik={formik}
                        options={generateOptions({
                            array: committeeData,
                            valueField: '_id',
                            labelField: 'name',
                        })}
                    />
                )}
                {roleLoading ? (
                    <ClipLoader color="#FFFFFF" size={18} />
                ) : (
                    <FormikSelectGroup
                        name="role"
                        label="Role"
                        formik={formik}
                        options={generateOptions({
                            array: roleData,
                            valueField: '_id',
                            labelField: 'name',
                        })}
                    />
                )} */}
            </form>
        </PageWithCard>
    );
};

export default AddEmployee;
