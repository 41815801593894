import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';

const UniqCodeModal = ({ data, showCodeModal, setShowCodeModal }) => {
    return (
        <ModalBasic
            modalOpen={showCodeModal}
            setModalOpen={setShowCodeModal}
            max_width="max-w-[500px]"
            zIndex={100}
        >
            <div className="p-4">
                <h1 className="font-bold text-red-500 mb-2">NOTE</h1>
                <p className="text-sm text-slate-200 text-justify">
                    You have Successfully registered. Thank you for joining us
                    !. Your team code is :
                    <span className="text-red-500 text-lg font-semibold block text-center">
                        {' '}
                        {data?.collegeCode}
                    </span>
                </p>
            </div>
        </ModalBasic>
    );
};

export default UniqCodeModal;
