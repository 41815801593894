import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tab from '../../components/infrastructure/Tabs/Tab';
import Tabs from '../../components/infrastructure/Tabs/Tabs';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { editFeature, fetchFeatures, getFeatures } from '../../app/reducers/Features/featuresSlice';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import { ChevronRight, Edit2 } from 'react-feather';
import { Link, json, useNavigate } from 'react-router-dom';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import ShowDropdownForSchedule from '../../components/infrastructure/ShowDropdownForSchedule';
import TableForScheduleEvent from '../../components/Table/TableForShceduleEvent';
import TableWithHeading from '../../components/Table/TableWithHeading';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';

const ViewFeatures = () => {
    
    // console.log('features', features)
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [featureGroup, setFeatureGroup] = useState({
        headTitle: {}, subTitle: []
    });
    const [featureData, setFeatureData] = useState([]);
    const navigate = useNavigate();
    const { user } = useSelector(getAuth);
    const [page, setPage] = useState(1);

    const computeFeatureGroup = () => {
        if(featureData?.docs?.reduce){
        const headGroupData = featureData?.docs?.reduce(
            (acc, ele) => {
                if(selectedTabIndex === 0){
                    if (ele.type === 'frontend') {
                        const { head } = ele;
                        if (acc.headTitle[head]) {
                            acc.headTitle[head] = [...acc.headTitle[head], ele];
                        } else {
                            acc.headTitle[head] = [ele];
                        }
                    } else {
                        acc.subTitle = [...acc.subTitle, ele];
                    }
                }
                if(selectedTabIndex === 1){
                    if (ele.type === 'backend') {
                        const { head } = ele;
                        if (acc.headTitle[head]) {
                            acc.headTitle[head] = [...acc.headTitle[head], ele];
                        } else {
                            acc.headTitle[head] = [ele];
                        }
                    } else {
                        acc.subTitle = [...acc.subTitle, ele];
                    }
                }
                
                return acc;
            },
            { headTitle: {}, subTitle: [] }
        )
        console.log('head group', headGroupData)
        setFeatureGroup(headGroupData)
    }
    }

    const featuresData = async (index) => {
        try {
            console.log('index',index)

            if (index === 0) {
                const string = QueryString.stringify({
                    populate: true,
                    page,
                    type: 'frontend',
                    sort : {head : 1,name : 1}
                });
                const response = await authAxiosInstance.get(
                    `/features?${string}`
                );
                console.log('resp', response);
                setFeatureData(response?.data?.data);
            }
            if (index === 1) {
                const string = QueryString.stringify({
                    populate: true,
                    page,
                    type: 'backend',
                    sort : {head : 1, name : 1}
                });
                const response = await authAxiosInstance.get(
                    `/features?${string}`
                );
                console.log('resp2', response);
                setFeatureData(response?.data?.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const data = useMemo(() => {
        if (!featureData?.docs?.length) return [];

        return featureData?.docs?.reduce((acc, crr) => {
            if (user?.isSuperUser) {
                return [...acc, crr];
            } else {
                const allowedRole = crr.allowedRoles.find(
                    (el) => el.name === user?.role?.name
                );
                if (allowedRole) {
                    return [...acc, crr];
                } else {
                    return acc;
                }
            }
        }, []);
    }, [featureData, selectedTabIndex]);

    console.log(data, 'data');

    useEffect(() => {
        featuresData(selectedTabIndex);
    }, [page, selectedTabIndex ]);

    useEffect(()=>{
        computeFeatureGroup()
    },[featureData,selectedTabIndex])

    const cols = useMemo(() => [
        {
            Header: 'Feature Name',
            accessor: 'name',
            cellStyle: { width: '30%' },
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-2 items-center">
                        {['inactive'].includes(row?.original?.status) && (
                            (row?.original?.allowedRoles?.some((ele)=> ele?._id === user?.role?._id) || user?.isSuperUser) &&
                            <SecondaryButton onClick={async (e, ) => {
                                e.stopPropagation();
                                const string = QueryString.stringify({
                                    ...row?.original,
                                    status : 'active',
                                });
                                await authAxiosInstance.patch(`/features`, `${string}`);
                                featuresData(selectedTabIndex);
                            }
                            }
                            >
                                Activate
                            </SecondaryButton>
                        )}
                        {['active'].includes(row?.original?.status) && (
                            (row?.original?.allowedRoles?.some((ele)=> ele?._id === user?.role?._id) || user?.isSuperUser) &&
                            <DangerButton onClick={
                                async (e, ) => {
                                    e.stopPropagation();
                                    const string = QueryString.stringify({
                                        ...row?.original,
                                        status : 'inactive',
                                    });
                                    await authAxiosInstance.patch(`/features`, `${string}`);
                                    featuresData(selectedTabIndex);
                                }
                            }>
                                Deactivate
                            </DangerButton>
                        )}
                        { ['Super User', 'Developer'].includes(user?.role?.name) && <div className="cursor-pointer">
                            <PrimaryButton onClick={() => navigate(`/features/edit/${row?.original?._id}`)}>
                                Edit
                            </PrimaryButton>
                        </div>}
                    </div>
                );
            },
            cellStyle: { width: '30%' },
        },
        {
            Header : 'Status',
            accessor : 'status',
            cellStyle: { width: '10%' },
        },
        {
            Header: 'System ID',
            accessor: '_id',
            cellStyle: { width: '30%' },
        },
    ], [featuresData, selectedTabIndex, navigate]);

    return (
        <PageWithCard>
            <Tabs
                headers={['Frontend', 'Backend']}
                onTabChange={(index) => {
                    setSelectedTabIndex(index);
                }}
            >
                <Tab>
                {Object.keys(featureGroup.headTitle).map((ele) => (
                        <div key={ele} className="mb-2">
                            <ShowDropdown
                                heading={
                                    <div className="mb-2">
                                        <h1 className="text-slate-200 text-2xl font-medium px-2">
                                            {ele}
                                        </h1>
                                    </div>
                                }
                                allowedRoles={[
                                    'HOD',
                                    'Super User',
                                    'Developer',
                                ]}
                            >
                                <TableWithHeading
                                    columns={cols}
                                    data={featureGroup.headTitle[ele]}
                                />
                            </ShowDropdown>
                        </div>
                    ))}
                    <PaginationClassic
                        setPage={setPage}
                        paginationDetails={featureData}
                    />
                </Tab>
                <Tab>
                    {Object.keys(featureGroup.headTitle).map((ele) => (
                        <div key={ele} className="mb-2">
                            <ShowDropdown
                                heading={
                                    <div className="mb-2">
                                        <h1 className="text-slate-200 text-2xl font-medium px-2">
                                            {ele}
                                        </h1>
                                    </div>
                                }
                                allowedRoles={[
                                    'HOD',
                                    'Super User',
                                    'Developer',
                                ]}
                            >
                                <TableWithHeading
                                    columns={cols}
                                    data={featureGroup.headTitle[ele]}
                                />
                            </ShowDropdown>
                        </div>
                    ))}
                    <PaginationClassic
                        setPage={setPage}
                        paginationDetails={featureData}
                    />
                </Tab>
            </Tabs>
        </PageWithCard>
    );
};

export default ViewFeatures;
