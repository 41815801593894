import React, { useEffect, useMemo } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { object, string, array } from 'yup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCollege,
    fetchColleges,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import { toast } from 'react-toastify';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import TableWithHeading from '../../components/Table/TableWithHeading';
import {
    createParticipant,
    editParticipant,
    fetchParticipant,
    fetchParticipants,
    getParticipants,
    resetParticipant,
} from '../../app/reducers/Participant/teamParticipantSlice';
import { Link, useParams } from 'react-router-dom';
import {
    fetchEvent,
    fetchEvents,
    getEvents,
} from '../../app/reducers/Event/eventSlice';
import SetButtons from '../../components/infrastructure/SetButtons';
import { useState } from 'react';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import EditParticipant from './EditParticipant';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import TertiaryButton from '../../components/infrastructure/Buttons/TertiaryButton';
import OtseParticipant from './OtseParticipant';
import EditOtseParticipant from './EditOtseParticipant';
import EditNcpParticipant from './EditNcpParticipant';
import FormikINPhoneInput from '../../components/formik/FormikINPhoneInput';
import { ChevronRight } from 'react-feather';
import DescriptionModal from './DescriptionModal';
import DetailsModal from './DetailsModal';
import { LayoutGroup } from 'framer-motion';
import UniqCodeModal from './UniqCodeModal';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';
import {
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { clearConfigCache } from 'prettier';

const NcpParticipant = ({ actionFeatures }) => {
    const { user } = useSelector(getAuth);
    console.log('user', user);
    const { id, eventId } = useParams();
    const { features } = useSelector(getFeatures);
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const [showApprovedModal, setShowApprovedModal] = useState(false);
    const [showDescriptionModal, setShowDescriptionModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [requestData, setRequestData] = useState({});
    const [singleEvent, setSingleEvent] = useState({});
    const [collegeName, setCollegeName] = useState({});
    const [subtitleName, setSubtitleName] = useState({});
    const [data, setData] = useState({});
    const [showCodeModal, setShowCodeModal] = useState(true);
    const [eventParticipants, setEventParticipants] = useState([]);
    const [existingContactNumbers, setExistingContactNumbers] = useState([]);
    const [canShowEditParticipants, setCanShowEditParticipants] =
        useState(true);
    const { committee } = useSelector(getCommittees);
    const [canShowRegistration, setCanShowRegistration] = useState(true);
    // console.log('event parti', eventParticipants)
    const { teamParticipant } = useSelector(getParticipants);
    // console.log('ncp team', teamParticipant)
    const { event, elementEditData } = useSelector(getEvents);
    // console.log('element data', elementEditData)
    const eventData = event?.docs?.filter((ele) => ele?.committee?._id == id);
    const dispatch = useDispatch();

    const actionsBtn = ['Edit Participants', 'Participants Registration'];
    const createFeatureActionsConfig = (actionFeatures, actions) => {
        const config = {};
        actionFeatures?.forEach((ids, index) => {
            config[ids] = actions[index];
        });
        return config;
    };

    const computeActiveFeature = () => {
        const buttonStateSetter = {
            'Edit Participants': setCanShowEditParticipants,
            'Participants Registration': setCanShowRegistration,
        };
        const actionButtonMap = createFeatureActionsConfig(
            actionFeatures,
            actionsBtn
        );
        console.log(' obj action map', actionButtonMap);
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item) => {
                    if (actionFeatures.includes(item?._id)) {
                        const action = actionButtonMap[item?._id];
                        const status = item?.status;
                        if (buttonStateSetter[action]) {
                            buttonStateSetter[action](status === 'active');
                        }
                    }
                });
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn) => btn(canShow));
        }
    };

    const collegeNameId = async () => {
        const collegePayload = { username: user?.username };
        const collegeString = QueryString.stringify(collegePayload);
        const resp = await authAxiosInstance.get(
            `/users/employee?${collegeString}`
        );
        const collegeResp = resp?.data?.data?.docs[0];
        console.log('college', collegeResp);
        setCollegeName(collegeResp);
    };
    const computeNcpParticipants = async () => {
        const eventPayload = {
            event: eventId,
            type: 'ncp',
            ncpCollege: user?.ncpCollege,
        };
        const stringData = QueryString.stringify(eventPayload);
        const resp = await authAxiosInstance.get(
            `/teamParticipant?${stringData}`
        );
        const eventDataSingle = resp?.data?.data?.docs[0]?.participants;
        const eventSingle = resp?.data?.data?.docs[0];
        setSingleEvent(eventSingle);
        setEventParticipants(eventDataSingle);
    };
    useEffect(() => {
        const fetchExistingParticipants = async () => {
            const participantPayload = {
                event: eventId,
                ncpCollege: user?.ncpCollege,
                type: 'ncp',
            };
            const stringParticipantData =
                QueryString.stringify(participantPayload);
            const respParticipant = await authAxiosInstance.get(
                `/teamParticipant?${stringParticipantData}`
            );
            const participants = respParticipant?.data?.data?.docs || [];
            setExistingContactNumbers(participants.map((p) => p.contactNumber));
        };

        fetchExistingParticipants();
    }, [eventId]);

    const formik = useFormik({
        initialValues: {
            participants: [
                {
                    firstName: '',
                    lastName: '',
                    contactNumber: '',
                },
            ],
        },
        validationSchema: object({
            participants: array().of(
                object({
                    firstName: string()
                        .matches(
                            /^[A-Z][a-z]*$/,
                            'Case sensitive & can not contain spaces. i.e. Hello'
                        )
                        .label('First Name')
                        .required(),
                    lastName: string()
                        .matches(
                            /^[A-Z][a-z]*$/,
                            'Case sensitive & can not contain spaces. i.e. Hello'
                        )
                        .label('Last Name')
                        .required(),
                    contactNumber: string()
                        .matches(
                            /^[0-9]{10}$/,
                            'Contact Number must be numbers (exact 10 digits)'
                        )
                        .min(10, 'Min 10 digits')
                        .max(10, 'Max 10 digits')
                        .label('Contact Number')
                        .required(),
                })
            ),
        }),
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                console.log('ncp participants', values);

                const newContactNumbers = values.participants.map(
                    (p) => p.contactNumber
                );
                const duplicateNewContacts = newContactNumbers.filter(
                    (item, index) => newContactNumbers.indexOf(item) !== index
                );
                if (duplicateNewContacts.length > 0) {
                    toast.error(
                        'Some contact numbers are duplicated in the added participants .'
                    );
                    setSubmitting(false);
                    return;
                }

                const duplicateContacts = values.participants.filter(
                    (participant) =>
                        existingContactNumbers.includes(
                            participant.contactNumber
                        )
                );

                if (duplicateContacts.length > 0) {
                    toast.error(
                        'Some contact numbers are already registered for this event.'
                    );
                    setSubmitting(false);
                    return;
                }

                const eventPayload = { _id: eventId };
                const stringData = QueryString.stringify(eventPayload);
                const resp = await authAxiosInstance.get(
                    `/event?${stringData}`
                );
                const eventSingleData = resp?.data?.data?.docs[0];
                const participantPayload = {
                    event: eventId,
                    status: 'approved',
                };
                const stringParticipantData =
                    QueryString.stringify(participantPayload);
                const respParticipant = await authAxiosInstance.get(
                    `/teamParticipant?${stringParticipantData}`
                );

                const totalParticipants = respParticipant?.data?.data?.docs;
                if (elementEditData?.teamMinCapacity === null) {
                    toast.error(`Min Participants capacity require`);
                    return;
                }

                if (
                    values?.participants?.length >=
                    elementEditData?.teamMinCapacity
                ) {
                    if (
                        eventSingleData?.teamMaxRegistration >
                        totalParticipants?.length
                    ) {
                        let obj = {
                            collegeCode: '',
                            ncpCollege: collegeName?.ncpCollege,
                            event: eventId,
                            type: 'ncp',
                            participants: values?.participants,
                            status: 'approved',
                        };
                        console.log('values obj', obj);
                        const resp = await dispatch(createParticipant(obj));
                        await dispatch(
                            fetchParticipants({
                                event: eventId,
                                type: 'ncp',
                                ncpCollege: user?.ncpCollege,
                            })
                        );
                        await dispatch(fetchEvent({ _id: eventId }));
                        resetForm();
                        console.log('resp', resp);
                        if (resp?.payload?.success) {
                            console.log('data', resp?.payload?.data?.data);
                            setData(resp?.payload?.data?.data);
                            setShowCodeModal(true);
                        }
                    } else {
                        toast.error(`Team Slots are full !!!`);
                    }
                } else {
                    toast.error(
                        ` Alert! It seems you haven't met the minimum participant (${elementEditData?.teamMinCapacity}) 
                    requirement for this event. Please double-check and 
                    ensure all criteria are fulfilled accordingly.`
                        // `Min Participants ${elementEditData?.teamMinCapacity} require`
                    );
                }
            } catch (error) {
                console.log('error', error);
            } finally {
                setSubmitting(false);
            }
        },
    });
    useEffect(() => {
        if (eventId) {
            dispatch(fetchEvent({ _id: eventId }));
            dispatch(
                fetchEvents({
                    populate: true,
                    'committee._id': id,
                    sort: { order: 1 },
                })
            );
            dispatch(
                fetchParticipants({
                    event: eventId,
                    type: 'ncp',
                    ncpCollege: user?.ncpCollege,
                })
            );
        } else {
            return toast.error('Events Not founds.');
        }
        if (id) {
            dispatch(fetchCommittees({ populate: true }));
        }

        computeNcpParticipants();
        collegeNameId();
    }, [id, eventId, JSON.stringify(formik?.values?.participants)]);
    useEffect(() => {
        computeActiveFeature();
    }, [features]);
    useEffect(() => {
        if (eventId) {
            const index = eventData?.findIndex((ele) => ele?._id === eventId);
            if (index !== -1) {
                setActiveButtonIndex(index);
            }
        }
        const descriptionName = () => {
            const eventObj = eventData?.find((ele) => ele?._id === eventId);
            console.log('object', eventObj);
            setSubtitleName(eventObj);
        };
        descriptionName();
    }, [eventId, eventData]);
    const cols = [
        {
            Header: 'Name',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row?.original?.firstName} {row?.original?.lastName}
                    </span>
                );
            },
        },
        {
            Header: 'Phone No.',
            accessor: 'contactNumber',
        },
    ];

    const participantMemo = useMemo(() => {
        if (teamParticipant && teamParticipant?.docs) {
            const regularData = teamParticipant?.docs?.filter(
                (ele) => ele?.type === 'ncp'
            );
            return regularData?.flatMap((ele) => ele?.participants);
        } else {
            return [];
        }
    }, [teamParticipant, activeButtonIndex]);

    const committeeNameData = committee?.docs?.find(
        (single) => single?._id === id
    );

    return (
        <PageWithCard
            heading={
                <>
                    <Link to={'/committee/event'}>Events</Link>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />{' '}
                    </span>
                    <span>{committeeNameData?.name}</span>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />{' '}
                    </span>
                    <span>{'Participants'}</span>
                    {eventId && (
                        <>
                            <span className="text-slate-200 inline-block px-1">
                                <ChevronRight size={14} className="-mb-0.5" />{' '}
                            </span>
                            <span>{elementEditData?.name}</span>
                        </>
                    )}
                </>
            }
        >
            <EditNcpParticipant
                requestData={requestData}
                showApprovedModal={showApprovedModal}
                setShowApprovedModal={setShowApprovedModal}
                computeNcpParticipants={computeNcpParticipants}
            />
            <DescriptionModal
                requestData={requestData}
                showDescriptionModal={showDescriptionModal}
                setShowDescriptionModal={setShowDescriptionModal}
            />
            <DetailsModal
                requestData={requestData}
                showDetailsModal={showDetailsModal}
                setShowDetailsModal={setShowDetailsModal}
            />
            <UniqCodeModal
                data={data}
                showCodeModal={showCodeModal}
                setShowCodeModal={setShowCodeModal}
            />
            {eventId ? (
                <>
                    <SetButtons>
                        <div className="flex items-center justify-start divide-x divide-slate-600">
                            {eventData?.map((ele, index) => (
                                <Link
                                    key={ele?._id}
                                    to={`/committee/event/ncpParticipant/${id}/${ele?._id}`}
                                >
                                    <button
                                        className={
                                            activeButtonIndex === index
                                                ? `btn  text-red-500 px-2 p-px text-xs sm:text-sm md:text-base  min-w-[100px] md:min-w-[0px]`
                                                : `btn text-slate-200 px-2 p-px text-xs sm:text-sm md:text-base min-w-[100px] md:min-w-[0px] `
                                        }
                                        onClick={() => {
                                            setActiveButtonIndex(index);
                                        }}
                                    >
                                        {ele?.name}
                                    </button>
                                </Link>
                            ))}
                        </div>
                    </SetButtons>
                    {
                        <div className="flex flex-row gap-4 mb-4">
                            <PrimaryButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const eventObj = eventData?.find(
                                        (ele) => ele?._id === eventId
                                    );
                                    console.log('object', eventObj);
                                    setRequestData(eventObj);
                                    setShowDescriptionModal(true);
                                }}
                            >
                                {subtitleName?.subTitle}
                            </PrimaryButton>
                            <PrimaryButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const eventObj = eventData?.find(
                                        (ele) => ele?._id === eventId
                                    );
                                    console.log('object', eventObj);
                                    setRequestData(eventObj);
                                    setShowDetailsModal(true);
                                }}
                            >
                                Details
                            </PrimaryButton>
                        </div>
                    }
                </>
            ) : (
                ''
            )}
            {user?.role?.name === 'NCP Representative' &&
                eventParticipants?.length > 0 && (
                    <div className="border border-indigo-200 p-2 rounded mb-2">
                        <TableWithHeading
                            columns={cols}
                            data={participantMemo}
                            heading={`${teamParticipant?.docs[0]?.collegeCode} (${participantMemo?.length}) `}
                        />
                        <div className="flex flex-row gap-4 mb-4">
                            {singleEvent?.status === 'approved' && (
                                <div className="flex flex-row gap-4">
                                    {participantMemo.length > 0 &&
                                        canShowEditParticipants && (
                                            <PrimaryButton
                                                type="button"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    const editDataObj =
                                                        teamParticipant?.docs?.find(
                                                            (ele) =>
                                                                ele?.event ===
                                                                    eventId &&
                                                                ele?.type ===
                                                                    'ncp'
                                                        );
                                                    setRequestData(editDataObj);
                                                    setShowApprovedModal(true);
                                                }}
                                            >
                                                Edit Participants
                                            </PrimaryButton>
                                        )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            {user?.role?.name === 'NCP Representative' &&
            elementEditData?.teamMinCapacity !== null &&
            elementEditData?.teamMinCapacity > 0
                ? !(
                      elementEditData?.teamMinCapacity <=
                      eventParticipants?.length
                  ) && (
                      <form
                          onSubmit={formik.handleSubmit}
                          className="flex flex-col gap-4 pb-4 bg-black p-4 rounded sm:rounded-md"
                      >
                          <FormikProvider value={formik}>
                              <FieldArray
                                  name="participants"
                                  render={(arrayHelpers) => {
                                      return (
                                          <div className="flex flex-col gap-2">
                                              <div>
                                                  {formik.values.participants.map(
                                                      (ele, index) => (
                                                          <div
                                                              className="relative mb-2 flex flex-col gap-4"
                                                              style={{
                                                                  border: '1px solid #475569',
                                                                  borderRadius:
                                                                      '5px',
                                                                  padding:
                                                                      '16px',
                                                              }}
                                                              key={index}
                                                          >
                                                              <div className="flex flex-col gap-4 sm:flex-row w-full">
                                                                  <div className="w-full">
                                                                      <FormikInputGroup
                                                                          required
                                                                          formik={
                                                                              formik
                                                                          }
                                                                          label="First Name"
                                                                          name={`participants.${index}.firstName`}
                                                                      />
                                                                  </div>
                                                                  <div className="w-full">
                                                                      <FormikInputGroup
                                                                          required
                                                                          formik={
                                                                              formik
                                                                          }
                                                                          label="Last Name"
                                                                          name={`participants.${index}.lastName`}
                                                                      />
                                                                  </div>
                                                                  <div className="w-full">
                                                                      <FormikINPhoneInput
                                                                          required
                                                                          formik={
                                                                              formik
                                                                          }
                                                                          name={`participants.${index}.contactNumber`}
                                                                          label="Phone No."
                                                                      />
                                                                  </div>
                                                              </div>
                                                              {index != 0 && (
                                                                  <div>
                                                                      <DangerButton
                                                                          className="mt-3"
                                                                          onClick={(
                                                                              e
                                                                          ) => {
                                                                              e.stopPropagation();
                                                                              arrayHelpers.remove(
                                                                                  index
                                                                              );
                                                                          }}
                                                                          type="button"
                                                                      >
                                                                          Remove
                                                                      </DangerButton>
                                                                  </div>
                                                              )}
                                                          </div>
                                                      )
                                                  )}
                                              </div>
                                              {elementEditData?.teamMaxCapacity >
                                                  1 &&
                                                  formik?.values?.participants
                                                      ?.length !==
                                                      elementEditData?.teamMaxCapacity &&
                                                   (
                                                      <div>
                                                          <PrimaryButton
                                                              onClick={(e) => {
                                                                  e.stopPropagation();
                                                                  arrayHelpers.push(
                                                                      {}
                                                                  );
                                                              }}
                                                              type="button"
                                                          >
                                                              Add More Participant
                                                          </PrimaryButton>
                                                      </div>
                                                  )}
                                          </div>
                                      );
                                  }}
                              />
                          </FormikProvider>
                         {canShowRegistration && (
                            elementEditData.runningStatus === 'upcoming' && <div>
                              <PrimaryButton
                                  type="submit"
                                  disabled={formik.isSubmitting}
                              >
                                  Submit
                              </PrimaryButton>
                          </div>)}
                      </form>
                  )
                : ''}
        </PageWithCard>
    );
};

export default NcpParticipant;
