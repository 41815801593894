import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import {
    fetchCommittee,
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import {
    editShortlistRegistrationRequest,
    fetchRegistrationRequests,
} from '../../app/reducers/RegistrationRequest/registrationRequestSlice';
const computeInitialValue = (data) => {
    let final = data;
    if (data?.committee1?._id) {
        final = { ...final, committee1: data?.committee1?._id };
    }
    return final;
};
const EditPending = ({
    filterValues,
    requestData,
    showApproveModal,
    setShowApproveModal,
}) => {
    // console.log('req.com', requestData);
    console.log('req.values', filterValues);
    const { committee } = useSelector(getCommittees);
    const dispatch = useDispatch();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...computeInitialValue(requestData),
        },
        // validationSchema: {},
        onSubmit: async (values) => {
            console.log('edit shortlist', values);
            await dispatch(
                editShortlistRegistrationRequest({
                    _id: values?._id,
                    committee1: values?.committee1,
                })
            );

            if (filterValues?.committee?.length > 0) {
                await dispatch(
                    fetchRegistrationRequests({
                        status: 'shortlisted',
                        populate: true,
                        'committee1._id': requestData?.committee1?._id,
                        sort : {'committee1.name' : 1, firstName : 1}
                    })
                );
            } 
            if(filterValues?.committee?.length === 0) {
                await dispatch(fetchRegistrationRequests({
                    status: 'pending',
                    populate: true,
                    sort : {'committee1.name' : 1, firstName : 1}
                    // 'committee1._id': requestData?.committee1?._id ,
                }));
            }
            setShowApproveModal(false);
        },
    });
    useEffect(() => {}, [requestData, JSON.stringify(formik.values)]);
    return (
        <ModalBasic
            modalOpen={showApproveModal}
            setModalOpen={setShowApproveModal}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 p-4"
            >
                <FormikInputGroup
                    label="First Name"
                    name="firstName"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="Last Name"
                    name="lastName"
                    formik={formik}
                    readOnly
                />
                <FormikSelectGroup
                    required
                    formik={formik}
                    label="Committee"
                    name="committee1"
                    options={generateOptions({
                        array: committee ? committee?.docs : [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                />
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default EditPending;
