import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchColleges,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import {
    fetchEmployee,
    fetchEmployees,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import PageCardForACL from '../../components/infrastructure/PageCardForACL';
import { ACL_ROLE_ID } from '../../utils/constants';
import { createAclEmployee } from '../../app/reducers/CollegeParticipant/collegeParticipantSlice';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import { Edit2, Trash } from 'react-feather';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import EditAclRegistration from './EditAclRegistration';
import TableWithHeading from '../../components/Table/TableWithHeading';
import FormikINPhoneInput from '../../components/formik/FormikINPhoneInput';
import FormikImageCropper from '../../components/formik/FormikImageCropper';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import { getWebsiteCreatives } from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';

const AclRegistration = ({actionFeatures}) => {
    const { user } = useSelector(getAuth);
    console.log('user', user);
    const {features} = useSelector(getFeatures);
    const [showApprovedModal, setShowApprovedModal] = useState(false);
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    console.log('creative data', websiteCreatives);
    const dynamicDefaultData = websiteCreatives[0];
    const [requestData, setRequestData] = useState({});
    const { college } = useSelector(getColleges);
    console.log('colleges', college)
    const { employees } = useSelector(getEmployees);
    const [canShowAclRegistration, setCanShowAclRegistration] = useState(true)
    const [canShowEditParticipants, setCanShowEditParticipants] = useState(true)
    // console.log('employee1', employees)
    const dispatch = useDispatch();
    const actionsBtn = ['Acl Registration','Edit Participants'];
    const createFeatureActionsConfig = (actionFeatures, actions)=>{
        const config = {};
        actionFeatures?.forEach((ids, index)=>{
            config[ids] = actions[index]
        });
        return config
    }
    
    const computeActiveFeature = () => {
        const buttonStateSetter = {
            'Acl Registration' : setCanShowAclRegistration,
            'Edit Participants' : setCanShowEditParticipants,
        }
        const actionButtonMap = createFeatureActionsConfig(actionFeatures, actionsBtn);
        console.log(' obj action map', actionButtonMap);
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item)=>{
                    if(actionFeatures.includes(item?._id)){
                        const action = actionButtonMap[item?._id];
                        const status = item?.status;
                        if(buttonStateSetter[action]){
                            buttonStateSetter[action](status === 'active')
                        }
                    }
                })
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn)=> btn(canShow))
        }
    };
    useEffect(() => {
        dispatch(fetchColleges({ populate: true,limit: 500, }));
        dispatch(
            fetchEmployees({
                role: ACL_ROLE_ID,
                college: user?.college?._id,
            })
        );
        computeActiveFeature();
    }, [features]);
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            username: '',
            userPhotoId :'',
            collegeIdPhoto :'',

        },
        validationSchema: object({
            firstName: string()
                .matches(/^[A-Z][a-z]*$/, 'Case sensitive & can not contain spaces. i.e. Hello').label('First Name')
                .required(),
            lastName: string()
                .matches(/^[A-Z][a-z]*$/, 'Case sensitive & can not contain spaces. i.e. Hello').label('Last Name')
                .required(),
            email: string().email('Invalid email').label('Email').required(),
            username: string()
                .matches(
                    /^[0-9]{10}$/,
                    'Contact Number must be numbers (exact 10 digits)'
                )
                .min(10,'Min 10 digits')
                .max(10, 'Max 10 digits').label('Contact Number')
                .required(),
            userPhotoId: string().label('Photo ID').required(),
            collegeIdPhoto: string().label('College ID Photo').required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('ACL register', values);
            const payload = {
                role: ACL_ROLE_ID,
                college: user?.college?._id,
            };

            const string = QueryString.stringify(payload);
            const allUsers = await authAxiosInstance.get(
                `/users/employee?${string}`
            );

            const collegeUsers = allUsers?.data?.data?.docs;
            console.log('collegeUsers', collegeUsers);
            const collegeOption = college?.docs?.find(
                (ele) => ele?._id == user?.college?._id
            );
            console.log('clg', collegeOption)
            const seatOption = collegeOption?.volunteers?.find(
                (ele) => ele?.role?._id === ACL_ROLE_ID
            );
            console.log('seat', seatOption);
            if (collegeUsers.length >= seatOption.seats) {
                toast.error(`ACL Seats are full your College `);
            } else {
                const obj = {
                    ...values,
                    role: ACL_ROLE_ID,
                    partOfO2: 'No',
                    contactNumber: values?.username,
                    college: user?.college?._id,
                };
                console.log('acl user obj', obj);
                await dispatch(createAclEmployee(obj));
                dispatch(
                    fetchEmployees({
                        role: ACL_ROLE_ID,
                        college: user?.college?._id,
                    })
                );
            }
           
            resetForm({
                firstName: '',
            lastName: '',
            email: '',
            username: '',
            userPhotoId :'',
            collegeIdPhoto :'',
            });
        },
    });
    const cols = [
        {
            Header: 'Name',
            Cell: ({ row }) => {
                console.log('row', row?.original)
                return (
                    <span>
                        {row?.original?.firstName} {row?.original?.lastName}
                    </span>
                );
            },
        },
        {
            Header: 'Phone No.',
            accessor: 'contactNumber',
        },
        // {
        //     Header: 'CC Code',
        //     Cell: ({ row }) => {
        //         // const collegeName = college?.docs?.find(
        //         //     (ele) => ele?._id === row?.original?.college
        //         // );
        //         return <span>{user?.username}</span>;
        //         // return <span>{collegeName?.name}</span>;
        //     },
        // },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row gap-2 items-center cursor-pointer">
                        { canShowEditParticipants && <PrimaryButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setRequestData(row?.original);
                                setShowApprovedModal(true);
                            }}
                        >
                            Edit
                        </PrimaryButton>}
                    </div>
                );
            },
        },
    ];

    const dataMemo = useMemo(
        () => (employees?.docs ? employees?.docs : []),
        [employees]
    );
    return (
        <>
            <PageCardForACL heading={
                <span>ACL Registration _ <span 
                style={{
                    color: dynamicDefaultData?.highlightedText ? dynamicDefaultData?.highlightedText : '',
                }} >{user?.username}</span></span>
            }>
                <EditAclRegistration
                    requestData={requestData}
                    showApprovedModal={showApprovedModal}
                    setShowApprovedModal={setShowApprovedModal}
                />
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md mb-6"
                >
                    <div className="flex flex-col gap-4 sm:flex-row w-full">
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                name="firstName"
                                label="First Name"
                                required
                            />
                        </div>
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                name="lastName"
                                label="Last Name"
                                required
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 sm:flex-row w-full">
                        <div className="w-full">
                            <FormikINPhoneInput
                                formik={formik}
                                name="username"
                                label="Contact Number"
                                required
                            />
                        </div>
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                name="email"
                                label="Email"
                                required
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 sm:flex-row w-full">
                        <div className="w-full">
                            <FormikImageCropper
                                name="userPhotoId"
                                label="Photo For ID"
                                formik={formik}
                                location={`1648_entertainment/externalRegistered/acl/`}
                                required
                                randomize
                                dynamicDefaultData={dynamicDefaultData}
                            />
                        </div>
                        <div className="w-full">
                            <FormikDirectImageUpload
                                name="collegeIdPhoto"
                                label="College ID Photo"
                                formik={formik}
                                location={`1648_entertainment/externalRegistered/acl/`}
                                required
                                randomize
                            />
                        </div>
                    </div>
                    {canShowAclRegistration &&
                     (<div className='text-center mt-2'>
                        <PrimaryButton type="submit"
                             style={{
                                backgroundColor: dynamicDefaultData?.highlightedText ? dynamicDefaultData?.highlightedText : '',
                            }}
                        >Register</PrimaryButton>
                    </div>)}
                </form>
                <TableWithHeading
                    heading={'ACL'}
                    columns={cols}
                    data={dataMemo}
                />
            </PageCardForACL>
        </>
    );
};

export default AclRegistration;
