import React from 'react';
import MediaLibraryAssets from '../../pages/MediaLibrary/MediaLibraryAssets';

const PageCardForEvents = ({ children, heading = '' }) => {
    return (
        <>
            <div
                className="px-4 sm:px-6 lg:px-8 py-8 w-full min-h-screen"
                style={{ backgroundColor: '#141414' }}
            >
                {/* Page header */}
                <header className="">
                    {typeof heading === 'string' ? (
                        <h2 className="text-sm md:text-base text-slate-200 font-medium mb-4">
                            {heading}
                        </h2>
                    ) : (
                        <div className="text-sm md:text-base text-slate-200 font-medium mb-4">
                            {heading}
                        </div>
                    )}
                </header>

                {/* Content */}
                <div
                    className="rounded-sm mb-8 pb-8"
                    style={{ backgroundColor: '#141414' }}
                >
                    <div className="w-full ">
                        <div>{children}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageCardForEvents;
