import { useEffect, useRef, useState } from 'react';
import ImageCropper from '../../pages/GlobalPopUp/ImageCropper';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteSingleObject,
    getMediaLibrary,
} from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import { X } from 'react-feather';
import PrimaryButton from '../infrastructure/Buttons/PrimaryButton';
import { openConfirmPopUp } from '../../app/reducers/ConfirmPopUp/condirmPopUpSlice';
const FormikImageCropper = ({
    name,
    formik,
    label = '',
    required,
    location,
    randomize = false,
    height,
    width,
    dynamicDefaultData,
    ...props
}) => {
    const [open, setModelOpen] = useState(false);
    const dispatch = useDispatch();
    const ref = useRef();
    const [finalString, setFinalString] = useState('');
    const { baseUrl } = useSelector(getMediaLibrary);
    useEffect(() => {
        if (_.at(formik.values, name)?.[0]) {
            setFinalString(_.at(formik.values, name)[0]);
        }
        else{
            setFinalString("")
        }
    }, [_.at(formik.values, name)?.[0]]);
    console.log('final',finalString)
    console.log('url',baseUrl)
    console.log('base',process.env.REACT_APP_S3_URL)
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <label className="block text-sm text-slate-200 font-medium mb-1" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>
            {finalString ? (
                <div className="flex">
                    <img
                        src={`${process.env.REACT_APP_S3_URL}${baseUrl}${finalString}`}
                        style={{ width: '100px' }}
                    />
                    <X
                        color="red"
                        onClick={async () => {
                            await dispatch(
                                deleteSingleObject({
                                    keys: [finalString],
                                })
                            );

                            setFinalString('');
                            formik.setFieldValue(name, '');
                        }}
                    />
                </div>
            ) : (
                <PrimaryButton
                    type="button"
                    style={{
                        backgroundColor: dynamicDefaultData?.highlightedText ? dynamicDefaultData?.highlightedText : '',
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setModelOpen(true);
                    }}
                >
                    Select Photo
                </PrimaryButton>
            )}
            <ImageCropper
                isOpen={open}
                name={name}
                setModelOpen={setModelOpen}
                location={location}
                formik={formik}
                setFinalString={setFinalString}
                randomize
            />

            {formik.errors[name] && formik.touched[name] ? (
                <p className="text-xs text-red-500">{formik.errors[name]}</p>
            ) : null}
        </div>
    );
};

export default FormikImageCropper;
