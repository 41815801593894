import React, { useEffect } from 'react';
import PageWithCard from './PageWithCard';
import { useFormik } from 'formik';
import { object, ref, string } from 'yup';
import PrimaryButton from './Buttons/PrimaryButton';
import FormikInputGroup from '../formik/FormikInputGroup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployee, getEmployees, updateEmployeePassword } from '../../app/reducers/Users/EmployeeSlice';
import { authAxiosInstance } from '../../utils/axiosConfig';

const UpdateUserPassword = () => {
    const { userId } = useParams();
    const { elementEditData } = useSelector(getEmployees);
    console.log('update user pass', elementEditData)

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(fetchEmployee({_id : userId}))
    },[userId]);

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            newConfirmPassword: '',
        },
        validationSchema: object({
            currentPassword: string()
                .matches(/^\S+$/, 'Space in not allowed')
                .label('Current Password')
                .required(),
            newPassword: string()
                .matches(/^\S+$/, 'Space in not allowed')
                .label('New Password')
                .required(),
            newConfirmPassword: string()
                .label('New Confirm Password')
                .required()
                .oneOf([ref('newPassword')], 'Password must be same'),
        }),
        onSubmit:async (values, { resetForm }) => {
            console.log('pass values', values);
            try {
                // dispatch(updateEmployeePassword({...values, _id : userId}))
                const resp = await authAxiosInstance.patch('/users/employee/updatePassword',{...values, _id : userId});
                console.log('resp', resp)
                if(resp?.data?.success){
                    localStorage.removeItem('access_token');
                    console.log('Token before deletion:', localStorage.getItem('jwtToken'));
                    alert(`${resp?.data?.message}`);
                    window.location.href="/login"
                }
            } catch (error) {
                if (error.response) {
                    toast.error(`${error?.response?.data?.message}`);
                }
            }
        },
    });
    return (
        <PageWithCard heading="Update Password">
            <form
                onSubmit={formik.handleSubmit}
                className="w-full md:w-1/2 flex flex-col gap-4 pb-4 bg-black p-4 rounded sm:rounded-md"
            >
                <div className="w-full">
                    <FormikInputGroup
                        formik={formik}
                        label="Current Password"
                        name="currentPassword"
                        type="password"
                    />
                </div>
                <div className="w-full">
                    <FormikInputGroup
                        formik={formik}
                        label="New Password"
                        name="newPassword"
                        type="password"
                    />
                </div>
                <div className="w-full">
                    <FormikInputGroup
                        formik={formik}
                        label="Confirm New Password"
                        name="newConfirmPassword"
                        type="password"
                    />
                </div>
                <div className="mt-2">
                    <PrimaryButton type="submit">Update</PrimaryButton>
                </div>
            </form>
        </PageWithCard>
    );
};

export default UpdateUserPassword;
