import React, { useEffect, useState } from 'react'
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic'
import { useDispatch } from 'react-redux'
import { fetchScoringTemplates } from '../../app/reducers/ScoringTemplate/scoringTemplateSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SubstituteModal from './SubstituteModal';

const DisclaimerSubstituteModal = ({
    requestData,
    showDisclaimerModal,
    setShowDisclaimerModal,
    participantTeamData,
    eventElementData,
    pointsData
}) => {
    const [requestModalData,setRequestModalData] = useState({})
    const [participantTeamModalData,setParticipantTeamModalData] = useState({})
    const [eventElementModalData,setEventElementModalData] = useState({})
    const [showSubstituteModal,setShowSubstituteModal] = useState(false)
    
    return (
        <>
        
        <SubstituteModal
                requestData={requestModalData}
                participantTeamData={participantTeamModalData}
                showSubstituteModal={showSubstituteModal}
                setShowSubstituteModal={setShowSubstituteModal}
                eventElementData={eventElementModalData}
            />      
        <ModalBasic
            modalOpen={showDisclaimerModal}
            setModalOpen={setShowDisclaimerModal}
        >
            <div className="p-4">
                <h1 className="text-2xl text-red-500 font-medium mb-4">
                    Disclaimer :
                </h1>
                <p className="text-slate-200 text-xl">
                    {`Substituting more than ${eventElementData?.noOfSubstitution} participants
                will result in ${pointsData?.points} on each substitution.`}
                </p>
                <div className='mt-4'>
                    <PrimaryButton
                        onClick={async(e)=>{
                            e.stopPropagation();
                            setShowDisclaimerModal(false)
                            setParticipantTeamModalData(participantTeamData)
                            setRequestModalData(requestData)
                            setShowSubstituteModal(true)
                            setEventElementModalData(eventElementData)
                        }}
                    >
                        Yes
                    </PrimaryButton>
                </div>
            </div>
        </ModalBasic>
        </>
    );
}

export default DisclaimerSubstituteModal
