import React, { useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';

const ViewDetails = ({
    requestData,
    showApprovedModal,
    setShowApprovedModal,
}) => {
    console.log('pending req data', requestData);
    const [reqData, setReqData] = useState({});
    const [showImageModal, setShowImageModal] = useState(false);
    // const formik = useFormik({
    //     enableReinitialize: true,
    //     initialValues: {
    //         firstName: requestData && requestData?.firstName,
    //         lastName: requestData && requestData?.lastName,
    //         contactNumber: requestData && requestData?.contactNumber,
    //     },
    // });
    return (
        <>
            <ModalBasic
                modalOpen={showApprovedModal}
                setModalOpen={setShowApprovedModal}
                zIndex={50}
            >
                <div className="w-full p-4">
                    <h1 className="text-red-500 text-sm mb-4">CL Details :</h1>
                    <div className="flex flex-col md:flex-row gap-4">
                        <div className="w-full  md:w-[20%] ">
                            <div
                                className="w-[100px] h-[100px] overflow-hidden"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const obj = {
                                        name: requestData?.firstName,
                                        url: requestData?.userPhotoId,
                                    };
                                    setReqData(obj);
                                    setShowImageModal(true);
                                }}
                            >
                                <img
                                    className="w-full h-full rounded-full"
                                    src={`${process.env.REACT_APP_S3_URL}${requestData?.userPhotoId}`}
                                    alt={requestData?.firstName}
                                    style={{
                                        objectPosition: 'center center',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w-full  md:w-[40%]">
                            <h2 className="text-red-500 text-[10px] py-1">
                                Name :{' '}
                                <span className="text-slate-200">
                                    {requestData?.firstName}{' '}
                                    {requestData?.lastName}
                                </span>
                            </h2>
                            <h2 className="text-red-500 text-[10px] py-1">
                                Phone Number :{' '}
                                <a
                                    href={`tel:${requestData?.contactNumber}`}
                                    className="font-normal text-slate-200"
                                >
                                    {` ${requestData?.contactNumber}`}
                                </a>
                            </h2>
                            <h2 className="text-red-500 text-[10px] py-1">
                                Email :{' '}
                                <span className="text-slate-200">
                                    {requestData?.email}
                                </span>
                            </h2>
                        </div>
                        <div className="w-full  md:w-[40%] ">
                            <h3 className="text-red-500  text-[10px] mb-1">
                                College ID :
                            </h3>
                            <div
                                className="w-full h-[150px] overflow-hidden "
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const obj = {
                                        name: requestData?.firstName,
                                        url: requestData?.collegeIdPhoto,
                                    };
                                    setReqData(obj);
                                    setShowImageModal(true);
                                }}
                            >
                                <img
                                    className="w-full h-full rounded-lg"
                                    src={`${process.env.REACT_APP_S3_URL}${requestData?.collegeIdPhoto}`}
                                    alt={requestData?.firstName}
                                    style={{
                                        objectPosition: 'center center',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBasic>
            <ModalBasic
                modalOpen={showImageModal}
                setModalOpen={setShowImageModal}
                zIndex={100}
                title={"Photo Preview"} 
                max_width='max-w-[1000px]'
            >
                
                    <img
                        className="w-full"
                        src={`${process.env.REACT_APP_S3_URL}${reqData?.url}`}
                        alt={reqData?.name}
                        
                    />
              
            </ModalBasic>
        </>
    );
};

export default ViewDetails;
