import React, { useEffect, useState } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchSchedules,
    getSchedules,
} from '../../app/reducers/schedule/scheduleSlice';
import './eventCalendar.css';

const localizer = momentLocalizer(moment);

const EventCalender = () => {
    
    const { schedule } = useSelector(getSchedules);
    const [startDates , setStartDates] = useState([]);
    
    const events = schedule?.docs?.map((item) => ({
        title: item.venue,
        start: item.startTime,
        end: item.endTime,
    }));
    console.log('events', events);
    const dispatch = useDispatch();
    useEffect(()=>{
      if (schedule?.docs) {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        const dayAfterTomorrow = new Date(today);
        dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

        setStartDates([today, tomorrow, dayAfterTomorrow]);
    }
    },[schedule]);
    console.log('start dates', startDates);
    const handleEventSelection = (e) => {
        console.log(e, 'Event data');
        alert('can edit event');
    };
    const CustomEventComponent = ({ event }) => {
        const start = moment(event.start).format('YYYY-MM-DD hh:mm A');
        const end = moment(event.end).format('YYYY-MM-DD hh:mm A');
        return (
            <div
                style={{
                    display: 'inline-block',
                    width: '20%', 
                    marginRight: '2px',
                    backgroundColor: '#e2f205', 
                    color: '#fff', 
                    borderRadius: '4px',
                    padding: '2px',
                    cursor: 'pointer',
                }}
                onClick={() => handleEventSelection(event)}
            >
                <div>{event.title}</div>
            </div>
        );
    };
   
    return (
        <div >
            { startDates.length > 0 && startDates.map((startDate, index) => (
              <>
                <h1
                  className='text-2xl font-bold mt-4 mb-2'
                >
                  {moment(startDate).format("MMMM Do YYYY")}
                </h1>
                <Calendar
                    key={index}
                    localizer={localizer}
                    events={events}
                    defaultView={Views.DAY}
                    startAccessor={(event) => new Date(event.start)}
                    endAccessor={(event) => new Date(event.end)}
                    style={{ height: '650px' }}
                    onSelectEvent={handleEventSelection}
                    components={{
                        event: CustomEventComponent,
                    }}
                    defaultDate={startDate}
                    min={new Date().setHours(9, 0, 0)}
                    max={new Date().setHours(22, 0, 0)}                 
                    toolbar={false}
                />
              </>
             ))}
        </div>
    );
};

export default EventCalender;
