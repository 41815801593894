import React from 'react';
import PageWithCard from '../components/infrastructure/PageWithCard';
import PrimaryButton from '../components/infrastructure/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';

const FeatureNotActivate = () => {
    const navigate = useNavigate();
    return (
        <PageWithCard>
            <h1 className='text-slate-200'>Comming Soon</h1>
            <div className='mt-4'>
                <a href='https://o2thefest.com/'>
                <PrimaryButton >
                    Back to Home
                </PrimaryButton>
                </a>
            </div>
        </PageWithCard>
    );
};

export default FeatureNotActivate;
