import React, { useEffect, useState } from 'react';
import PageWithCard from '../components/infrastructure/PageWithCard';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCollegeParticipant,
    fetchCollegeParticipants,
    getCollegeParticipant,
} from '../app/reducers/CollegeParticipant/collegeParticipantSlice';
import {
    fetchEmployee,
    fetchEmployees,
    getEmployees,
} from '../app/reducers/Users/EmployeeSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../utils/axiosConfig';
import PrimaryButton from '../components/infrastructure/Buttons/PrimaryButton';
import { UNAPPROVED_CL_ROLE_ID } from '../utils/constants';
import { getWebsiteCreatives } from '../app/reducers/WebsitesCreatives/websitesCreativeSlice';

const ExternalGreetPage = () => {
    const { id } = useParams();
    const { elementEditData } = useSelector(getEmployees);
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    console.log('creative data', websiteCreatives);
    const dynamicDefaultData = websiteCreatives[0];
    const navigate = useNavigate();
    console.log('elementEditData', elementEditData);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchEmployee({ _id: id, role: UNAPPROVED_CL_ROLE_ID }));
    }, [id]);
    return (
        <div className="md:w-1/2 md:flex md:flex-col md:item-center ">
            <div className="w-full md:w-[90%] lg:w-[80%] xl:w-[70%] mx-auto px-4 py-8">
                <h1 className="text-slate-200 text-2xl text-center lg:text-4xl mb-6">
                    Registration Successful ! 🎉
                </h1>
                <p className="text-center text-lg md:text-xl text-slate-200">
                    Your unique CC code is{' '}
                    <p
                        className="font-medium text-3xl block"
                        style={{
                            color: dynamicDefaultData?.highlightedText
                                ? dynamicDefaultData?.highlightedText
                                : '#DE3636',
                        }}
                    >
                        {elementEditData?.username}
                    </p>
                    Your Username is your CC code.
                    Thank you for joining us !
                </p>
                <div className="text-center mt-6">
                    <a href="https://o2thefest.com/">
                        <PrimaryButton
                        style={{
                            backgroundColor: dynamicDefaultData?.highlightedText ? dynamicDefaultData?.highlightedText : '',
                        }}
                        >Back to Home</PrimaryButton>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ExternalGreetPage;
