import React, { useEffect } from 'react';
import PageWithCard from '../components/infrastructure/PageWithCard';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from '../components/infrastructure/Buttons/PrimaryButton';
import { getWebsiteCreatives } from '../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchEmployee,
    getEmployees,
} from '../app/reducers/Users/EmployeeSlice';
import { NCP_REPRESENTATIVE_ROLE } from '../utils/constants';

const NcpGreetPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { elementEditData } = useSelector(getEmployees);
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    console.log('creative data', websiteCreatives);
    const dynamicDefaultData = websiteCreatives[0];
    useEffect(() => {
        dispatch(fetchEmployee({ _id: id, role: NCP_REPRESENTATIVE_ROLE }));
    }, [id]);
    return (
        <div className="md:w-1/2 md:flex md:flex-col md:item-center ">
        <div className="w-full md:w-[90%] lg:w-[80%] xl:w-[70%] mx-auto px-4 py-8">
            <h1 className="text-slate-200 text-2xl text-center lg:text-4xl mb-6">
                Registration Successfully 🎉
            </h1>
            <p className="text-center text-lg md:text-xl text-slate-200">
                    Congratulations! You've successfully
                    registered for NCP. Get ready for an
                    incredible journey ahead. Your <span
                        className=""
                        style={{
                            color: dynamicDefaultData?.highlightedText
                                ? dynamicDefaultData?.highlightedText
                                : '#DE3636',
                        }}
                    >
                       {" "} Phone Number {" "}
                    </span>
                     has been set as  your username.
                    You will receive an NCP code once you
                    register for any event. Stay tuned for
                    further updates !
                </p>
                <div className="text-center mt-12">
                    <a href="https://o2thefest.com/">
                        <PrimaryButton 
                        style={{
                            backgroundColor: dynamicDefaultData?.highlightedText ? dynamicDefaultData?.highlightedText : '',
                        }}
                        >Back to Home</PrimaryButton>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default NcpGreetPage;
