import React, { useEffect, useMemo } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Layout from './components/infrastructure/Layout';
import PersistLogin from './components/infrastructure/PersistLogin';

import './css/style.scss';
import Dashboard from './pages/Dashboard';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import PageNotFound from './pages/PageNotFound';
import Logout from './pages/Auth/Logout';

import RouteWithHeaderAndSidebar from './components/infrastructure/RouteWithHeaderAndSidebar';
import 'react-datepicker/dist/react-datepicker.css';
import MediaLibrary from './pages/MediaLibrary/MediaLibrary';
import AddEmployee from './pages/Users/AddEmployee';
import MediaLibraryAssets from './pages/MediaLibrary/MediaLibraryAssets';
import ViewEmployees from './pages/Users/ViewEmployees';
import EditEmployee from './pages/Users/EditEmployee';
import EditEmployeePage from './pages/Users/EditEmployeePage';
import { routes } from './utils/routes';
import RoleAccess from './pages/RoleAccess';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchFeatures,
    getFeatures,
} from './app/reducers/Features/featuresSlice';
import ViewRegistrationRequest from './pages/RegistrationRequest/ViewRegistrationRequest';
import { getAuth } from './app/reducers/Auth/authSlice';
import OpenAccess from './pages/OpenAccess';
import FeatureNotActivate from './pages/FeatureNotActive';
import ExternalRegistrationRequest from './pages/ExternalRegistartionRequest/ExternalRegistrationRequest';
import GreetPage from './pages/ExternalGreetPage';
import ExternalGreetPage from './pages/ExternalGreetPage';
import InternalGreetPage from './pages/InternalGreetPage';
import NcpRegistration from './pages/NcpRegistration/NcpRegistration';
import NcpGreetPage from './pages/NcpGreetPage';
import RegisterContainer from './components/infrastructure/RegisterContainer';

function App() {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        document.querySelector('html').style.scrollBehavior = 'auto';
        window.scroll({ top: 0 });
        document.querySelector('html').style.scrollBehavior = '';
    }, [location.pathname]); // triggered on route change

    useEffect(() => dispatch(fetchFeatures()), []);
    return (
        <>
            <MediaLibraryAssets />
            <Routes>
                <Route
                    path="/externalRegistrationGreetPage/:id"
                    element={
                        <RegisterContainer>
                            <ExternalGreetPage />
                        </RegisterContainer>
                    }
                />
                <Route
                    path="/internalRegistrationGreetPage/:id"
                    element={
                        <RegisterContainer>
                            <InternalGreetPage />
                        </RegisterContainer>
                    }
                />
                <Route
                    path="/ncpGreetPage/:id"
                    element={
                        <RegisterContainer>
                            <NcpGreetPage />
                        </RegisterContainer>
                    }
                />
                <Route path="/" element={<Layout />}>
                    <Route element={<PersistLogin />}>
                        <Route exact path="/logout" element={<Logout />} />
                        <Route path="*" element={<PageNotFound />} />
                        <Route path="/" element={<RouteWithHeaderAndSidebar />}>
                            {routes.map((ele) => (
                                <Route
                                    element={
                                        <RoleAccess
                                            roles={ele.allowedRoles}
                                            allowedFeatures={
                                                ele?.allowedFeatures
                                                    ? ele.allowedFeatures
                                                    : []
                                            }
                                        />
                                    }
                                >
                                    <Route
                                        path={ele.route}
                                        element={ele.element}
                                    />
                                </Route>
                            ))}
                        </Route>
                    </Route>
                    <Route
                        element={
                            <OpenAccess
                                allowedFeatures={['65f14861c9d73a599d816e57']}
                            />
                        }
                    >
                        <Route exact path="/register" element={
                            <RegisterContainer>
                                <Register />
                            </RegisterContainer>
                        } />
                    </Route>
                    <Route
                        element={
                            <OpenAccess
                                allowedFeatures={['65fadd155ee0eea0e5230c35']}
                            />
                        }
                    >
                        <Route
                            exact
                            path="/externalRegister"
                            element={
                                <RegisterContainer>
                                    <ExternalRegistrationRequest />
                                </RegisterContainer>
                            }
                        />
                    </Route>
                    <Route
                        element={
                            <OpenAccess
                                allowedFeatures={['6671799d3a7d1f1f9d6b28af']}
                            />
                        }
                    >
                        <Route exact path="/ncpRegister" element={
                            <RegisterContainer>
                                <NcpRegistration />
                            </RegisterContainer>
                        } />
                    </Route>
                    <Route exact path="/login" element={<Login />} />
                    <Route
                        exact
                        path="/notActivated"
                        element={<FeatureNotActivate />}
                    />
                </Route>
            </Routes>
        </>
    );
}

export default App;
