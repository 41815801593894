import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import { createData, deleteData, editData, fetchData, fetchEditData } from "../../generators/generateThunks";
import { Slide } from "react-toastify";
import { resetData } from "../../generators/generateReducers";

const SLICE_NAME = 'teamParticipant';

export const createParticipant = createData.generateThunk( SLICE_NAME, SLICE_NAME );

export const fetchParticipants = fetchData.generateThunk( SLICE_NAME, SLICE_NAME );

export const fetchParticipant = fetchEditData.generateThunk( SLICE_NAME, SLICE_NAME );

export const editParticipant = editData.generateThunk( SLICE_NAME, SLICE_NAME );

export const editParticipantSubstitute = editData.generateThunk( SLICE_NAME, '/teamParticipant/addSubstitute')

export const deleteParticipant = deleteData.generateThunk(
     SLICE_NAME, SLICE_NAME
);

const teamParticipantSlice = createSlice({
    name : SLICE_NAME,
    initialState : generateStateSingle(SLICE_NAME),
    extraReducers :{
        ...createData.generateExtraReducers(createParticipant, SLICE_NAME),
        ...fetchData.generateExtraReducers( fetchParticipants,SLICE_NAME),
        ...fetchEditData.generateExtraReducers( fetchParticipant, SLICE_NAME ),
        ...editData.generateExtraReducers( editParticipant, SLICE_NAME ),
        ...deleteData.generateExtraReducers(deleteParticipant, SLICE_NAME),
        ...editData.generateExtraReducers(editParticipantSubstitute, SLICE_NAME),
    }
});

export const getParticipants = (state)=> state.teamParticipant;

export default teamParticipantSlice.reducer;