import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import {
  ArrowLeft,
  ChevronDown,
  ChevronRight,
  ChevronsLeft,
  CloudLightning,
  MoreHorizontal,
} from "react-feather";
import { useSelector } from "react-redux";
import { getWebsiteCreatives } from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';

const NavLinks = ({ link, setOpenNavbar, openNavbar, ...props }) => {
  const { websiteCreatives} = useSelector(getWebsiteCreatives);
  console.log('creatives', websiteCreatives);
  const isDynamicData = websiteCreatives[0];
  const location = useLocation();
  const { pathname } =location;
  const [isActiveMobile, setIsActiveMobile] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 1000 });
 
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const activePath = (path) => {
    if(pathname === path){
      return {
        color : `${isDynamicData ? isDynamicData.highlightedText : '#ef4444'}`,
        fontWeight : "bold"
      }
    }else{
      return {
        color : '#ffffff'
      }
    }
  };
  const openDropdown = () => {
    setDropdownOpen(true);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };
  return (
    <>
      {isMobile ? (
        <div className="div flex flex-col bg-black">
          <div className=" dropdown cursor-pointer font-normal text-sm uppercase tracking-wide ">
            <div className="flex items-center gap-2 py-[11px] pl-2">
              <Link to={link?.href ? link.href : "#!"} passHref>
                <a
                  onClick={() => setOpenNavbar(false)}
                  className={`text-sm md:text-sm my-4 font-normal whitespace-nowrap `}
                  style={activePath(link.href)}
                  target={link.target === "new_page" ? "_blank" : "_self"}
                >
                  {link.title}
                </a>
              </Link>
            </div>

            {isActiveMobile && (
              <div className="absolute dropdown-menu  font-normal h-screen w-full top-0 p-2 bg-black pr-8   ">
                <div className="flex  gap-2 pt-1 pb-1 border-b border-t ">
                  <hr />
                  <ChevronsLeft
                    onClick={() => {
                      setIsActiveMobile(false);
                    }}
                    className="hover:text-[#a2c4e0]"
                  />

                  <p className="text-xl md:text-base hover:text-[#a2c4e0] ">
                    {link?.title}
                  </p>
                  <hr />
                </div>
                {link.links.length > 0 &&
                  link.links.map((el) => (
                    <ul key={el._id} className="list-none top-0 ">
                     
                      <li className="top-0 transition  delay-150 hover:translate-x-4  duration-300">
                        <Link to={el?.href ? el.href : "#!"} passHref>
                          <a
                            onClick={() => setOpenNavbar(false)}
                            className={`text-md md:text-md  md:my-0 transition-colors duration-300 `}
                            target={
                              el.target === "new_page" ? "_blank" : "_self"
                            }
                            style={activePath(el.href)}
                          >
                            {el.title}
                          </a>
                        </Link>
                        <hr />
                      </li>
                    </ul>
                  ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="div flex flex-col ">
          <div
            className={`group py-2 xl:px-2 relative dropdown cursor-pointer font-normal text-sm uppercase tracking-wide ${
              isDropdownOpen ? "dropdown-open" : ""
            }`}
            onMouseEnter={openDropdown}
            onMouseLeave={closeDropdown}
          >
            <Link to={link?.href ? link.href : "#!"} passHref>
              <a
                className={`text-sm md:text-sm my-4  whitespace-nowrap `}
                style={activePath(link.href)}
                target={link.target === "new_page" ? "_blank" : "_self"}
              >
                {link.title && link  ? (
                  <span className="flex flex-row whitespace-nowrap">
                    {link.title}
                  </span>
                ) : (
                  link.title
                )}
              </a>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default NavLinks;
