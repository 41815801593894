import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Edit2, Trash } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import {
    deleteCollege,
    fetchColleges,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import { useNavigate } from 'react-router-dom';
import {
    deleteFestival,
    fetchFestival,
    fetchFestivals,
    getFestivals,
} from '../../app/reducers/Festival/festivalSlice';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';

const ViewFestival = () => {
    const { festival, loading } = useSelector(getFestivals);
    const { baseUrl } = useSelector(getMediaLibrary);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            console.log('searching');
            dispatch(fetchFestivals({ search: search, populate: true }));
        }, 300),
        []
    );
    const [page, setPage] = useState(1);

    const cols = [
        {
            Header: 'Festival Photo',
            Cell: ({ row }) => {
                return (
                    <div>
                        {row.original.photos && (
                            <img
                                src={baseUrl + row.original.photos[0]}
                                height={70}
                                width={100}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'Festival Name',
            Cell: ({ row }) => {
                return <span>{row.original.name}</span>;
            },
        },
        {
            Header: 'College Name',
            Cell: ({ row }) => {
                return <span>{row.original.college?.name}</span>;
            },
        },
        {
            Header: 'Start Date',
            Cell: ({ row }) => {
                return (
                    <span>
                        {moment(row.original.startDate).format('DD/MM/YYYY')}
                    </span>
                );
            },
        },
        {
            Header: 'End Date',
            Cell: ({ row }) => {
                return (
                    <span>
                        {moment(row.original.endDate).format('DD/MM/YYYY')}
                    </span>
                );
            },
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 items-center cursor-pointer">
                        <Edit2
                            onClick={async () => {
                                navigate(`/festival/edit/${row.original._id}`);
                            }}
                            color="blue"
                        />
                        <Trash
                            onClick={async () => {
                                await dispatch(
                                    deleteFestival({ id: row.original._id })
                                );
                                dispatch(fetchFestivals());
                            }}
                            color="red"
                        />
                    </div>
                );
            },
        },
    ];
    const data = useMemo(
        () => (festival?.docs ? festival.docs : []),
        [festival]
    );
    useEffect(() => {
        dispatch(fetchFestivals({ populate: true }));
    }, [page]);
    return (
        <div className="p-4">
            <TableWithHeadingAndGlobalSearch
                loading={loading}
                heading={'View Festivals'}
                data={data}
                searchFunction={debouncedSearch}
                columns={cols}
            />
            <PaginationClassic setPage={setPage} paginationDetails={festival} />
        </div>
    );
};

export default ViewFestival;
