import { createSlice } from "@reduxjs/toolkit"
import { generateStateSingle } from "../../generators/generateState";
import { createData, deleteData, editData, fetchData, fetchEditData } from "../../generators/generateThunks";

const SLICE_NAME = 'course';

export const createCourse = createData.generateThunk(SLICE_NAME, SLICE_NAME);

export const fetchCourses = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);

export const fetchCourse = fetchEditData.generateThunk(SLICE_NAME. SLICE_NAME);

export const editCourse = editData.generateThunk(SLICE_NAME,SLICE_NAME);

export const deleteCourse = deleteData.generateThunk(SLICE_NAME,SLICE_NAME);

const courseSlice = createSlice({
    name : SLICE_NAME,
    initialState : generateStateSingle(SLICE_NAME),
    extraReducers :{
        ...createData.generateExtraReducers(createCourse,SLICE_NAME),
        ...fetchData.generateExtraReducers(fetchCourses,SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchCourse,SLICE_NAME),
        ...editData.generateExtraReducers(editCourse,SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteCourse,SLICE_NAME),
    }
});
export const getCourses= (state) => state.course;
export default courseSlice.reducer;