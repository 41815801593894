import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import { createData, deleteData, editData, fetchData, fetchEditData } from "../../generators/generateThunks";


const SLICE_NAME = 'collegeParticipant';

export const fetchCollegeParticipants = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createCollegeParticipant = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createAclEmployee = createData.generateThunk(SLICE_NAME, '/collegeParticipant/aclEmployee');
export const fetchCollegeParticipant = fetchEditData.generateThunk(SLICE_NAME,SLICE_NAME);
export const editCollegeParticipant = editData.generateThunk(SLICE_NAME,SLICE_NAME);
export const deleteCollegeParticipant= deleteData.generateThunk(SLICE_NAME,SLICE_NAME);

const collegeParticipateSlice = createSlice({
    initialState : generateStateSingle(SLICE_NAME),
    name : SLICE_NAME,
    extraReducers:{
        ...fetchData.generateExtraReducers(fetchCollegeParticipants, SLICE_NAME),
        ...createData.generateExtraReducers(createCollegeParticipant, SLICE_NAME),
        ...createData.generateExtraReducers(createAclEmployee, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchCollegeParticipant,SLICE_NAME),
        ...editData.generateExtraReducers(editCollegeParticipant,SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteCollegeParticipant,SLICE_NAME),
    }
})

export const getCollegeParticipant = (state)=> state.collegeParticipant;

export default collegeParticipateSlice.reducer;