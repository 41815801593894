import { useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Link, useParams } from 'react-router-dom';
import {
    editFeature,
    fetchFeature,
    getFeatures,
} from '../../app/reducers/Features/featuresSlice';
import { array, object, string } from 'yup';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { FEATURES_TYPE } from '../../utils/dropdownOptions';
import { generateOptions } from '../../utils/Utils';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';
import { ChevronRight } from 'react-feather';

const EditFeature = () => {
    const { id } = useParams();
    console.log('Params_id', id);
    const { elementEditData, elementEditDataLoading, features } =
        useSelector(getFeatures);
    const { role } = useSelector(getRoles);
    console.log('elementData', elementEditData);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchFeature({ _id: id }));
        dispatch(fetchRoles({type: 'Internal',sort :{name:1}}));
    }, []);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...elementEditData,
        },
        validationSchema: object({
            name: string().required(),
            type: string().required(),
            head: string().required(),
            allowedRoles: array(),
        }),
        onSubmit: (values, { resetForm }) => {
            console.log('FeatureValues', values);
            dispatch(editFeature(values));
            dispatch(fetchFeature({ _id: id }));
        },
    });

    return (
        <PageWithCard heading={
            <>
            <Link to={'/features/view'}>Features</Link>
            <span className="text-slate-200 inline-block px-1"><ChevronRight size={14} className='-mb-0.5' /> </span>
            <span>{'Edit Features'}</span>
            </>
        }>
            {elementEditDataLoading ? (
                <ClipLoader color="#FFFFFF" size={18} />
            ) : (
                <form
                    className="flex flex-col gap-4  bg-black p-4 rounded sm:rounded-md"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikInputGroup
                        name="name"
                        label="Feature Name"
                        required
                        formik={formik}
                    />
                    <FormikSelectGroup
                        name="type"
                        label="Feature Type"
                        formik={formik}
                        options={FEATURES_TYPE}
                    />
                    <FormikInputGroup
                        name="head"
                        label="Feature Head"
                        required
                        formik={formik}
                    />
                    <FormikMultiSelect
                        name="allowedRoles"
                        label="Allowed Roles"
                        formik={formik}
                        options={generateOptions({
                            array: role.docs ? role?.docs : [],
                            valueField: '_id',
                            labelField: 'name',
                        })}
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </PageWithCard>
    );
};

export default EditFeature;
