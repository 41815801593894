import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    createWithFile,
    deleteData,
    fetchData,
} from '../../generators/generateThunks';

const SLICE_NAME = 'users';

export const fetchUsers = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createUser = createWithFile.generateThunk(
    SLICE_NAME,
    'users/register'
);
export const deleteUser = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);

const userSlice = createSlice({
    initialState: generateStateSingle(SLICE_NAME),
    name: SLICE_NAME,
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchUsers, SLICE_NAME),
        ...createWithFile.generateExtraReducers(createUser, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteUser, SLICE_NAME),
    },
});

export const getUsers = (state) => state.users;

export default userSlice.reducer;
