import React from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useFormik } from "formik";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import { useDispatch } from "react-redux";
import { editCommittee } from "../../app/reducers/Committee/committeeSlice";

const AssignCommitteeColor = (
    {
        requestData,
        showColorModal,
        setShowColorModal,
    }
)=>{
    console.log('color', requestData)
    const dispatch = useDispatch();
    const formik = useFormik({
        enableReinitialize : true,
        initialValues : {
            ...requestData
        },
        onSubmit:(values)=>{
            console.log('hexcode', values)
            dispatch(editCommittee(values))
            dispatch(
                fetchCommittees({
                    type: 'Event',
                    populate: true,
                    sort : { name : 1 },
                })
            );
            setShowColorModal(false);
        }
    })

    return(
        <ModalBasic
            modalOpen={showColorModal}
            setModalOpen={setShowColorModal}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4  bg-black p-4 rounded sm:rounded-md"
            >
                <FormikInputGroup
                    label="Color Code"
                    name="hexCode"
                    formik={formik}
                    required
                />
                <div>
                    <PrimaryButton type="submit">add</PrimaryButton>
                </div>
            </form>
        </ModalBasic>

    )
}

export default AssignCommitteeColor;