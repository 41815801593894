import { useEffect } from 'react';
/* React Validator */
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
    import { useForceUpdate } from '../../Common/Function';
    import AuthImage from '../../images/O2 banner.png';
import AuthDecoration from '../../images/auth-decoration.png';
import {
    checkJwt,
    getAuth,
    postLogin,
} from '../../app/reducers/Auth/authSlice';
import { useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { object, string } from 'yup';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import NavbarLeft from '../../components/Navbar/NavbarLeft';
import { link_data } from '../../utils/constants';
import { fetchWebsiteCreativesData, getWebsiteCreatives } from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';
const computeFrom = (location) => {
    const from = location?.state?.from;
    if (from && from !== '/logout') {
        return from;
    } else {
        return '/';
    }
};
const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const from = computeFrom(location);
    const { websiteCreatives } = useSelector(getWebsiteCreatives)
    const dynamicDefaultData = websiteCreatives[0];

    const forceUpdate = useForceUpdate();
    const { loading, jwt, error } = useSelector(getAuth);
    // const [allValues, setValues] = useState({ phone: "", password: "" });
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: object({
            // username: string().min(10).max(10).required(),
            username: string().required(),
            password: string().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            dispatch(postLogin(values)).then(async (res) => {
                if (res.payload.success) {
                    navigate(from, { replace: true });
                    resetForm();
                }
            });
        },
    });

    useEffect(() => {
        console.log('========== from in Login js==============');
        console.log(from);
        console.log('========== location in Login js==============');
        console.log(location);
        dispatch(checkJwt());
        if (jwt) {
            navigate(from);
        }
    }, [jwt]);
    useEffect(()=>{
        dispatch(fetchWebsiteCreativesData({ name : 'Default'}))
    },[])

    return (
        <>
            {loading ? (
                <h1>Loading...</h1>
                ) : ( 
                <>
                <NavbarLeft props={link_data}/>
                <main className="bg-black">
                    <div className="relative md:flex pt-[50px] h-screen xl:min-h-screen">
                        <div
                            className="hidden  md:w-1/2 md:flex "
                            aria-hidden="true"
                            style={{
                                backgroundColor: '#000000',
                            }}
                        >
                            <div className='w-full h-full'>
                                <img
                                    className="object-cover w-full h-full"
                                    style={{
                                        objectPosition : 'top center'
                                    }}
                                    src={`${process.env.REACT_APP_S3_URL}${dynamicDefaultData?.banner}`}
                                    // width="760"
                                    // height="1024"
                                    alt="Authentication"
                                />
                            </div>
                        </div>
                        <div className="md:w-1/2 md:flex md:flex-col md:item-center">
                            {/* <div className="  h-full flex flex-col after:flex-1"> */}
                                <div className="w-full md:w-2/3 lg:w-1/2 mx-auto px-4 py-8">
                                    <h1 className="text-2xl xl:text-3xl text-slate-200 font-bold mb-6 text-left">
                                     Welcome back 👋
                                    </h1>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="space-y-4">
                                            <p className="text-red-500 text-xs text-center">
                                                {error}
                                            </p>
                                            <FormikInputGroup
                                                name="firstName"
                                                label="Name"
                                                formik={formik}
                                                required
                                            />
                                            <FormikInputGroup
                                                name="username"
                                                required
                                                formik={formik}
                                                label="Username"
                                            />
                                            <FormikInputGroup
                                                label="Password"
                                                required
                                                name="password"
                                                type="password"
                                                formik={formik}
                                            />
                                            <div className="text-center">
                                                <PrimaryButton type="submit"
                                                style={{
                                                    backgroundColor : dynamicDefaultData?.highlightedText ? dynamicDefaultData?.highlightedText : '',
                                                }}
                                                >
                                                    Login
                                                </PrimaryButton>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </main>
                </>
            )}
        </>
    );
};

export default Login;
