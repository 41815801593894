import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { array, mixed, object, string } from 'yup';
import { createUser, getUsers } from '../../app/reducers/Users/usersSlice';
import FormikFileInput from '../../components/formik/FormikFileInput';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import {
    createEmployee,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import {
    createCollege,
    fetchColleges,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import moment from 'moment';
import { generateOptions } from '../../utils/Utils';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { ClipLoader } from 'react-spinners';
import { createFestival } from '../../app/reducers/Festival/festivalSlice';

const AddFestival = () => {
    // const { loading } = useSelector(getEmployees);
    const dispatch = useDispatch();
    const { college, loading } = useSelector(getColleges);

    useEffect(() => {
        dispatch(fetchColleges({ limit: 300 }));
    }, []);

    const collegeData = useMemo(
        () => (college?.docs ? college.docs : []),
        [college]
    );
    const formik = useFormik({
        initialValues: {
            name: '',
            startDate: null,
            endDate: null,
            status: true,
            college: '',
            photos: [''],
        },
        validationSchema: object({
            name: string().required(),
            startDate: mixed().required(),
            endDate: mixed().required(),
            status: string().required(),
            college: string().required(),
            photos: array(),
        }),
        onSubmit: async (values, { resetForm }) => {
            dispatch(createFestival(values));
            formik.setFieldValue('photos', []);
            resetForm();
        },
    });
    return (
        <PageWithCard heading="Add Festival">
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4"
            >
                <FormikInputGroup
                    name="name"
                    label="Festival Name"
                    formik={formik}
                    required
                />
                <FormikInputDateGroup
                    name="startDate"
                    label="Start Date"
                    formik={formik}
                    required
                />
                <FormikInputDateGroup
                    name="endDate"
                    label="End Date"
                    formik={formik}
                    required
                />
                {loading ? (
                    <ClipLoader color="#FFFFFF" size={18} />
                ) : (
                    <FormikSelectGroup
                        name="college"
                        label="College"
                        formik={formik}
                        options={generateOptions({
                            array: collegeData,
                            valueField: '_id',
                            labelField: 'name',
                        })}
                    />
                )}
                <FormikProvider value={formik}>
                    <div className="text-bold">Photos</div>
                    <FieldArray
                        name="photos"
                        render={(arrayHelpers) => {
                            return (
                                <div className="flex flex-col gap-2">
                                    {/* <p>Address</p> */}
                                    <div>
                                        {formik.values.photos.map(
                                            (ele, index) => (
                                                <div
                                                    className="relative p-4 mb-2"
                                                    style={{
                                                        border: '1px solid #d6c7c7',
                                                        borderRadius: '5px',
                                                    }}
                                                    key={index}
                                                >
                                                    <FormikDirectFileUpload
                                                        name={`photos.${index}`}
                                                        formik={formik}
                                                        randomize={true}
                                                        location={`1648_entertainment/festivals/`}
                                                    />
                                                    <div>
                                                        <DangerButton
                                                            className="mt-3"
                                                            onClick={() => {
                                                                arrayHelpers.remove(
                                                                    index
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            Remove
                                                        </DangerButton>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div>
                                        <SecondaryButton
                                            onClick={() => {
                                                arrayHelpers.push('');
                                            }}
                                            type="button"
                                        >
                                            Add More
                                        </SecondaryButton>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </FormikProvider>
                <FormikCheckbox name="status" label="Status" formik={formik} />
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </PageWithCard>
    );
};

export default AddFestival;
