import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useFormik } from 'formik';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import {
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';
import { generateOptions } from '../../utils/Utils';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import { createEmployee, editEmployee, fetchEmployees } from '../../app/reducers/Users/EmployeeSlice';
import { useParams } from 'react-router-dom';
import { VOLUNTEER_ROLE_ID } from '../../utils/constants';
import { editRegistrationRequest, editShortlistRegistrationRequest, fetchRegistrationRequest, fetchRegistrationRequests } from '../../app/reducers/RegistrationRequest/registrationRequestSlice';

const ShortlistPromote = ({
    filterValues,
    requestData,
    showApprovedModal,
    setShowApprovedModal,
}) => {
    console.log('req data',requestData)
    const { id }  = useParams();
    const { committee } = useSelector(getCommittees);
    const { role } = useSelector(getRoles);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCommittees({populate:true,sort :{name:1}}));
        dispatch(fetchRoles({type : 'Internal',populate:true,sort :{name:1}}));
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            userId: requestData && requestData?._id,
            firstName: requestData && requestData?.firstName,
            lastName: requestData && requestData?.lastName,
            assignCommittee:
                requestData && requestData?.committee1?.name,
            requestedRole: '',
            seats: '',
        },
        onSubmit: async (values) => {
            console.log('Promote submit', values);
            let canSubmit = false;
            let assignCommittee = requestData && requestData?.committee1;

            const roleOptions = committee?.docs?.find(
                (ele) => ele._id === assignCommittee?._id
            );
            console.log('roleOption', roleOptions)
            const seatAvailable = roleOptions?.members?.find(
                (ele) => ele.role?._id === VOLUNTEER_ROLE_ID
            );
            console.log('seats', seatAvailable.seats);
            const payload = {
                role: VOLUNTEER_ROLE_ID,
                committee: assignCommittee?._id,
            };

            const string = QueryString.stringify(payload);
            const allUsers = await authAxiosInstance.get(
                `/users/employee?${string}`
            );
            const userGet = allUsers?.data?.data?.docs;
            console.log('userGet', userGet)
            if (seatAvailable?.seats > userGet.length) {
                canSubmit = true;
                console.log('canSubmitTrue', canSubmit);
            } else {
                canSubmit = false;
                console.log('canSubmitFalse', canSubmit);
                toast.error(`Seats are not available for Volunteer Role`);
                return;
            }
            if (canSubmit) {
                const {committee1, committee2, committee3, _id, username, ...rest} = requestData
                // console.log('rest', {...rest})
                const newUser = {
                    ...rest,
                    userId : _id,
                    username : username,
                    role : VOLUNTEER_ROLE_ID,
                    committee : [ requestData?.committee1?._id ],
                    requestedRole : values?.requestedRole,
                    status : true,
                }
                let resp = await dispatch(editRegistrationRequest(newUser));
                console.log('resp',resp)
                if(resp?.payload?.success){
                    if (filterValues?.committee?.length > 0) {
                        await dispatch(
                            fetchRegistrationRequests({
                                status: 'shortlisted',
                                populate: true,
                                'committee1._id': requestData?.committee1?._id,
                                sort : {'committee1.name' : 1, firstName : 1}
                            })
                        );
                    } 
                    if(filterValues?.committee?.length === 0) {
                        await dispatch(fetchRegistrationRequests({
                            status: 'shortlisted',
                            populate: true,
                            sort : {'committee1.name' : 1, firstName : 1}
                            // 'committee1._id': requestData?.committee1?._id ,
                        }));
                    }
                    setShowApprovedModal(false);
                }
               
            }
        },
    });
    return (
        <ModalBasic
            modalOpen={showApprovedModal}
            setModalOpen={setShowApprovedModal}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 p-4"
            >
                <FormikInputGroup
                    label="User ID"
                    name="userId"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="First Name"
                    name="firstName"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="Last Name"
                    name="lastName"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="Committee"
                    name="assignCommittee"
                    formik={formik}
                    readOnly
                />
                <FormikSelectGroup
                    formik={formik}
                    label="Promoted to Role"
                    name="requestedRole"
                    options={generateOptions({
                        array: role
                            ? role?.docs?.filter(
                                  (ele) => !['Super User', 'Developer'].includes(ele?.name)
                              )
                            : [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                    onChange={async (selectedOption) => {
                        const sharedRoleOption = role?.docs?.find(
                            (ele) => ele._id == selectedOption.value
                        );
                        // console.log('shared promote', sharedRoleOption)
                        let assignCommittee =
                            requestData && requestData?.committee1;
                        // console.log('committee',assignCommittee)
                        const payload = {
                            _id: assignCommittee?._id,
                            populate: true,
                        };
                        const string = QueryString.stringify(payload);
                        const res = await authAxiosInstance.get(
                            `/committee?${string}`
                        );
                        const committeeData = res?.data?.data?.docs?.[0];
                        // console.log(committeeData, 'data');
                        const memberObj = committeeData?.members?.find(
                            (ele) => ele.role._id == selectedOption.value
                        );
                        console.log('memberObj', memberObj);
                        if(!memberObj?.seats){
                            toast.error('Seats are not allocated')
                            return
                        }
                        formik.setFieldValue('seats', memberObj?.seats);

                        formik.setFieldValue('requestedRole', selectedOption.value);
                    }}
                />
                <FormikInputGroup
                    name="seats"
                    readOnly
                    label="Seats"
                    formik={formik}
                />
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default ShortlistPromote;
