import React, { useEffect, useMemo } from 'react'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import { useFormik } from 'formik'
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch'
import { useDispatch, useSelector } from 'react-redux'
import { editEmployee, fetchEmployees, getEmployees } from '../../app/reducers/Users/EmployeeSlice'
import { getRoles } from '../../app/reducers/Role/roleSlice'
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton'
import DangerButton from '../../components/infrastructure/Buttons/DangerButton'
import { getAuth } from '../../app/reducers/Auth/authSlice'
const CoreApproval = () => {
    const { user } = useSelector(getAuth);
    console.log('core user', user)
    const { employees } = useSelector(getEmployees);
    console.log('employee', employees)
    const { role } = useSelector(getRoles);
    const dispatch = useDispatch();
    const coreApprovalCols = [
        {
            Header: 'Name',
            Cell: ({ row }) => {
                return (
                    <span>
                        {row?.original?.firstName} {row?.original?.lastName}
                    </span>
                );
            },
        },
        {
            Header : 'Committee',
            Cell : ( {row})=>{
                return (
                    <span>{row?.original?.committee?.map((ele)=>ele?.name)}</span>
                )
            }
        },
        {
            Header : 'Role',
            Cell : ({row})=>{
                return(
                    <span>{row?.original?.role?.name}</span>
                )
            }
        },
        {
            Header : 'Requested Role',
            accessor : 'requestedRole',
            Cell : ({row})=>{
                const roleName = role?.docs?.find((ele)=> ele?._id == row?.original?.requestedRole );
                return(
                    <span>{roleName?.name}</span>
                )
            }
        },
        {
            Header : 'Action',
            Cell : ({row})=>{
                return (
                    <div className="flex gap-2 items-center cursor-pointer">
                        {
                            <PrimaryButton
                                onClick = {async(e)=>{
                                    e.stopPropagation();
                                    const updateRoleObj = {
                                        _id : row?.original?._id,
                                        role : row?.original?.requestedRole,
                                        isApproved : "approved"
                                    }
                                    console.log('object', updateRoleObj)
                                    await dispatch(
                                        editEmployee(updateRoleObj)
                                    )
                                    await dispatch(fetchEmployees({isApproved : "pending",populate : true}))
                                }}
                            >
                                Approve
                            </PrimaryButton>
                        }
                        {  <DangerButton
                            onClick={async(e)=>{
                                
                                e.stopPropagation();
                                    const updateRoleObj = {
                                        _id : row?.original?._id,
                                        isApproved : "none"
                                    }
                                    console.log('object', updateRoleObj)
                                    await dispatch(
                                        editEmployee(updateRoleObj)
                                    )
                                    await dispatch(fetchEmployees({isApproved : "pending",populate : true}))
                            }}
                        >
                            Denied
                        </DangerButton>}
                    </div>
                )
            }
        }
    ];

    const requestDataMemo = useMemo(()=>( employees?.docs ?  employees?.docs : []),[employees])

    useEffect(()=>{
        dispatch(fetchEmployees({isApproved : "pending",populate : true}))
    },[])
  return (
    <PageWithCard heading='Core Approval'>
        <TableWithHeadingAndSearch 
            data = { requestDataMemo }
            columns={coreApprovalCols}
        />
    </PageWithCard>
  )
}

export default CoreApproval
