import React from 'react'
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic'

const DescriptionModal = ({
    requestData,
    showDescriptionModal,
    setShowDescriptionModal
}) => {
    console.log('req data', requestData)
  return (
    <ModalBasic
        modalOpen={showDescriptionModal}
        setModalOpen={setShowDescriptionModal}
    >
        <div className='p-4'>
            <h1 className='text-2xl text-red-500 font-medium mb-4'>Description :</h1>
            <p className='text-slate-200 text-xl'>{requestData ? requestData?.description : ''}</p>
        </div>
      
    </ModalBasic>
  )
}

export default DescriptionModal
