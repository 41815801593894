import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../../partials/SideBar/Sidebar';

const RouteWithHeaderAndSidebar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth < 1024 ? false : true);
    const sidebarWidth = '256px'; 

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth >= 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="flex h-screen overflow-hidden">
            {/* Sidebar */}
            <Sidebar
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />
            
            {/* Content area */}
            <div 
                className={`relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden pt-16 transition-all duration-300 ${isWideScreen ? 'ml-0' : ''}`}
                style={{
                    marginLeft: isWideScreen && sidebarOpen ? sidebarWidth : '0px',
                }}
            >
                {/* Page Content */}
                <main className="relative flex-1">
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

export default RouteWithHeaderAndSidebar;
