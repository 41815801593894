import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';

const SLICE_NAME = 'externalRegistrationRequest';

export const createExternalRegistrationRequest = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const fetchExternalRegistrationRequests = fetchData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editExternalRegistrationRequest = editData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const fetchExternalRegistrationRequest = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
const externalRegistrationRequestSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    extraReducers: {
        ...createData.generateExtraReducers(
            createExternalRegistrationRequest,
            SLICE_NAME
        ),
        ...fetchData.generateExtraReducers(
            fetchExternalRegistrationRequests,
            SLICE_NAME
        ),
        ...editData.generateExtraReducers(
            editExternalRegistrationRequest, SLICE_NAME),
        ...editData.generateExtraReducers(
            fetchExternalRegistrationRequest,
            SLICE_NAME
        ),
    },
});

export const getExternalRegistrationRequests = (state)=> state.externalRegistrationRequest;

export default externalRegistrationRequestSlice.reducer;
