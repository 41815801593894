import { createSlice } from '@reduxjs/toolkit';


const SLICE_NAME = 'confirmPopUp';
let initialData = {
    isOpen: false,
    message: '',
    buttons: [],
    data: {},
};

const confirmPopUp = createSlice({
    name: SLICE_NAME,
    initialState: { ...initialData },
    reducers: {
        openPopUp: (state, action) => {
            state.isOpen = action.payload.isOpen;
            state.message = action.payload.message;
            state.buttons = action.payload.buttons;
            state.data = action.payload.data;
        },
    },
    extraReducers: {},
});

export const getConfirmPopUp = (state) => state.confirmPopUp;
export const { openPopUp } = confirmPopUp.actions;

export const openConfirmPopUp = (data) => async (dispatch) => {
    await dispatch(openPopUp(data));
};

export default confirmPopUp.reducer;
