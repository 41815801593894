import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
import axios from 'axios';
import { authAxiosInstance } from '../../../utils/axiosConfig';
import QueryString from 'qs';

const SLICE_NAME = 'scoreBoard';

export const createScoreBoard = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const fetchScoreBoards = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchScoreBoard = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editScoreBoard = editData.generateThunk(SLICE_NAME, SLICE_NAME);

export const fetchScoreBoardsWithCommittees = createAsyncThunk(
    `${SLICE_NAME}/fetchWithCommittees`,
    async (data, { rejectWithValue }) => {
        try {
            console.log('data', data);
            const response = await authAxiosInstance.post(
                `/scoreBoard/getWithCommittees`,
                data
            );
            console.log('respose', response);
            return response?.data?.data?.data;
        } catch (error) {
            console.error(error);
            return rejectWithValue(JSON.stringify(error));
        }
    }
);
const scoreboardSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    extraReducers: (builder) => {
        builder
            .addCase(
                createScoreBoard.pending,
                createData.generateExtraReducers(createScoreBoard, SLICE_NAME)
                    .pending
            )
            .addCase(
                createScoreBoard.fulfilled,
                createData.generateExtraReducers(createScoreBoard, SLICE_NAME)
                    .fulfilled
            )
            .addCase(
                createScoreBoard.rejected,
                createData.generateExtraReducers(createScoreBoard, SLICE_NAME)
                    .rejected
            )
            .addCase(
                fetchScoreBoards.pending,
                fetchData.generateExtraReducers(fetchScoreBoards, SLICE_NAME)
                    .pending
            )
            .addCase(
                fetchScoreBoards.fulfilled,
                fetchData.generateExtraReducers(fetchScoreBoards, SLICE_NAME)
                    .fulfilled
            )
            .addCase(
                fetchScoreBoards.rejected,
                fetchData.generateExtraReducers(fetchScoreBoards, SLICE_NAME)
                    .rejected
            )
            .addCase(
                fetchScoreBoard.pending,
                fetchEditData.generateExtraReducers(fetchScoreBoard, SLICE_NAME)
                    .pending
            )
            .addCase(
                fetchScoreBoard.fulfilled,
                fetchEditData.generateExtraReducers(fetchScoreBoard, SLICE_NAME)
                    .fulfilled
            )
            .addCase(
                fetchScoreBoard.rejected,
                fetchEditData.generateExtraReducers(fetchScoreBoard, SLICE_NAME)
                    .rejected
            )
            .addCase(
                editScoreBoard.pending,
                editData.generateExtraReducers(editScoreBoard, SLICE_NAME)
                    .pending
            )
            .addCase(
                editScoreBoard.fulfilled,
                editData.generateExtraReducers(editScoreBoard, SLICE_NAME)
                    .fulfilled
            )
            .addCase(
                editScoreBoard.rejected,
                editData.generateExtraReducers(editScoreBoard, SLICE_NAME)
                    .rejected
            )
            .addCase(fetchScoreBoardsWithCommittees.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                fetchScoreBoardsWithCommittees.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.scoreBoard.data = action.payload;
                }
            )
            .addCase(
                fetchScoreBoardsWithCommittees.rejected,
                (state, action) => {
                    state.loading = false;
                    state.error = action.payload;
                }
            );
    },
    // extraReducers : {
    //     ...createData.generateExtraReducers(createScoreBoard, SLICE_NAME),
    //     ...fetchData.generateExtraReducers(fetchScoreBoards, SLICE_NAME),
    //     ...fetchEditData.generateExtraReducers(fetchScoreBoard, SLICE_NAME),
    //     ...editData.generateExtraReducers(editScoreBoard, SLICE_NAME),
    // }
});

export const getScoreBoards = (state) => state.scoreBoard;

export default scoreboardSlice.reducer;
