import React from 'react';

const PageCardForACL = ({ children, heading = '' }) => {
    return (
        <>
            <div
                className="px-4 sm:px-6 lg:px-8 py-8 w-full min-h-screen"
                style={{ backgroundColor: '#141414' }}
            >
                <header className="">
                    {typeof heading === 'string' ? (
                        <h2 className="text-sm md:text-base text-slate-200 font-medium mb-4">
                            {heading}
                        </h2>
                    ) : (
                        <div className="text-sm md:text-base text-slate-200 font-medium mb-4">
                            {heading}
                        </div>
                    )}
                </header>
                <div className=" rounded  sm:rounded-md mb-4 text-slate-200">
                    <div className=" w-full">
                        <div>{children}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageCardForACL;
