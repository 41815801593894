import PageWithCard from '../../components/infrastructure/PageWithCard';
import React, { useEffect, useMemo, useState } from 'react';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikEditorInput from '../../components/formik/FormikEditorInput';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { generateOptions } from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import {
    createEvent,
    editEvent,
    fetchEvent,
    fetchEvents,
    getEvents,
} from '../../app/reducers/Event/eventSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import {
    fetchScoringTemplates,
    getScoringTemplates,
} from '../../app/reducers/ScoringTemplate/scoringTemplateSlice';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Tabs from '../../components/infrastructure/Tabs/Tabs';
import Tab from '../../components/infrastructure/Tabs/Tab';
import SetButtons from '../../components/infrastructure/SetButtons';
import { PODIUM_TYPE, TYPE_LEVEL } from '../../utils/dropdownOptions';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';

const AddEvents = ({ actionFeatures }) => {
    const { id, eventId } = useParams();
    const { user } = useSelector(getAuth);
    const navigate = useNavigate();
    const { committee } = useSelector(getCommittees);
    const { features } = useSelector(getFeatures);
    const { event, elementEditData } = useSelector(getEvents);
    console.log('edit data', elementEditData);
    console.log(' data', elementEditData);
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const [canShowUpdate, setCanShowUpdate] = useState(true);
    const eventData = useMemo(
        () => event?.docs?.filter((ele) => ele?.committee?._id == id),
        [event]
    );
    // console.log('eventDate', eventData);
    const dispatch = useDispatch();
    // const truncateText = (text, charLimit) => {
    //     if (!text) return '';
    //     if (text.length > charLimit) {
    //         return text.slice(0, charLimit) + '...';
    //     }
    //     return text;
    // };
    
    const actionsBtn = ['Update'];
    const createFeatureActionsConfig = (actionFeatures, actions)=>{
        const config = {};
        actionFeatures?.forEach((ids, index)=>{
            config[ids] = actions[index]
        });
        return config
    }
    
    const computeActiveFeature = () => {
        const buttonStateSetter = {
            'Update' : setCanShowUpdate
        }
        const actionButtonMap = createFeatureActionsConfig(actionFeatures, actionsBtn);
        console.log(' obj action map', actionButtonMap);
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item)=>{
                    if(actionFeatures.includes(item?._id)){
                        const action = actionButtonMap[item?._id];
                        const status = item?.status;
                        if(buttonStateSetter[action]){
                            buttonStateSetter[action](status === 'active')
                        }
                    }
                })
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn)=> btn(canShow))
        }
    };
    const handleButtonClick = (index) => {
        setActiveButtonIndex(index);
    };

    useEffect(() => {
        if(eventId){
            dispatch(fetchEvent({ _id: eventId, populate: true }));
            dispatch(
                fetchEvents({
                    populate: true,
                    'committee._id': id,
                    sort: { order: 1 },
                })
            );
        }
        if (eventId && elementEditData) {
            formik.setValues(computeInitialValue(elementEditData));
        }
        if (!eventId) {
            formik.setValues({
                name: '',
                description: '',
                rules: '',
                committee: '',
                podium: '',
                level: '',
                teamMinCapacity: '',
                teamMaxCapacity: '',
                teamMaxRegistration: '',
                noOfSubstitution: '',
            });
        }
        dispatch(fetchCommittees({ type: 'Event' }));
        computeActiveFeature();
        // getUser();
    }, [eventId, id, activeButtonIndex,features]);

    useEffect(() => {
        if (eventId) {
            const index = eventData?.findIndex((ele) => ele?._id === eventId);
            if (index !== -1) {
                setActiveButtonIndex(index);
            }
        }
    }, [eventId, eventData ]);

    const computeInitialValue = (data) => {
        if (data) {
            return {
                ...data,
                name: data?.name ? String(data?.name) : '',
                podium: data?.podium ? String(data.podium) : '',
                subTitle: data?.subTitle ? String(data?.subTitle) : '',
                description: data?.description ? String(data?.description) : '',
                rules: data?.rules ? String(data?.rules) : '',
                level: data?.level ? String(data?.level) : '',
                teamMinCapacity: data?.teamMinCapacity
                    ? data?.teamMinCapacity
                    : '',
                teamMaxCapacity: data?.teamMaxCapacity
                    ? data?.teamMaxCapacity
                    : '',
                teamMaxRegistration: data?.teamMaxRegistration
                    ? data?.teamMaxRegistration
                    : '',
                noOfSubstitution: data?.noOfSubstitution
                    ? data?.noOfSubstitution
                    : '',
            };
        } else {
            return {
                name: '',
                subTitle: '',
                description: '',
                rules: '',
                committee: '',
                // photos : [''],
                podium: '',
                level: '',
                teamMinCapacity: '',
                teamMaxCapacity: '',
                teamMaxRegistration: '',
                noOfSubstitution: '',
                order:'',
            };
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(elementEditData),
        validationSchema: Yup.object().shape({
            name: Yup
                .string()
                .label('Title')
                .required()
                .max(20, 'Name must not contain more than 20 characters'),
            subTitle: Yup
                .string()
                .max(30, 'Sub Title must not contain more than 30 characters'),
            description: Yup
                .string()
                .max(280, 'Description must not contain more than 280 characters'),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('eventFormCreate', values);
            if (eventId) {
                const resp = await dispatch(editEvent(values));
                console.log('resp edit',resp)
                if(resp?.payload?.success){
                    toast.success('Event Edited successfully')
                }
                await dispatch(fetchEvents({ populate: true }));
                return;
            }
            //Event data
            const payload = { committee: values?.committee };
            const string = QueryString.stringify(payload);
            const createdEventData = await authAxiosInstance.get(
                `/event?${string}`
            );
            console.log('object', createdEventData?.data?.data?.docs);
            const createdEvents = createdEventData?.data?.data?.docs.length;

            //committee data
            const availableEvent = committee?.docs.find(
                (ele) => ele._id === values?.committee
            );
            console.log('available event', availableEvent);
            if (availableEvent?.numberOfEvents === null) {
                return toast.error('No. of events not define.');
            }
            if (createdEvents >= availableEvent?.numberOfEvents) {
                return toast.error('Event is full in selected committee');
            }
            const resp = await dispatch(createEvent(values));
            const newEventData = resp?.payload?.data;

            navigate(
                `/editEvent/${newEventData?.committee}/${newEventData?._id}`
            );
            dispatch(fetchEvents({ populate: true, sort: { name: 1 } }));
        },
    });
    return (
        <PageWithCard
            heading={
                eventId ? (
                    <>
                        <Link to={'/committee/event'}>Events</Link>
                        <span className="text-slate-200 inline-block px-1">
                            <ChevronRight size={14} className="-mb-0.5" />
                        </span>
                        <span>{elementEditData?.committee?.name}</span>
                        <span className="text-slate-200 inline-block px-1">
                            <ChevronRight size={14} className="-mb-0.5" />
                        </span>
                        <span>{'Event Setup'}</span>
                        <span className="text-slate-200 inline-block px-1">
                            <ChevronRight size={14} className="-mb-0.5" />
                        </span>
                        <span>{elementEditData?.name}</span>
                    </>
                ) : (
                    'Add Events'
                )
            }
        >
            {eventId ? (
                <>
                    <SetButtons>
                        {/* <div className="flex items-center justify-start divide-x divide-slate-600"> */}
                        {eventData?.map((ele, index) => (
                            <Link
                                key={ele?._id}
                                to={`/committee/event/editEvent/${id}/${ele?._id}`}
                            >
                                <button
                                    className={
                                        activeButtonIndex === index
                                            ? `btn  text-red-500 px-2 p-px text-xs sm:text-sm md:text-base min-w-[100px]  md:min-w-[0px]`
                                            : `btn text-slate-200 px-2 p-px text-xs sm:text-sm md:text-base  min-w-[100px] md:min-w-[0px]`
                                    }
                                    onClick={() => handleButtonClick(index)}
                                >
                                    {ele?.name} 
                                    {/* {truncateText(ele?.name, 10)} */}
                                </button>
                            </Link>
                        ))}
                        {/* </div> */}
                        {/* {
                            <Link to={`/event/add`}>
                                <PrimaryButton className="text-xs sm:text-sm md:text-base min-w-[100px] md:min-w-[0px]">
                                    Add Event
                                </PrimaryButton>
                            </Link>
                        }
                        {
                            <Link to={`/committee/event`}>
                                <DangerButton className="text-xs sm:text-sm md:text-base min-w-[100px] md:min-w-[0px]">
                                    Back
                                </DangerButton>
                            </Link>
                        } */}
                    </SetButtons>
                    <form
                        className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md"
                        onSubmit={formik.handleSubmit}
                    >
                        <div className="flex flex-col gap-4 sm:flex-row w-full">
                            <div className="w-full flex flex-col gap-4 sm:w-3/5">
                                <FormikInputGroup
                                    name="name"
                                    label="Title"
                                    required
                                    formik={formik}
                                />
                                <FormikInputGroup
                                    name="subTitle"
                                    label="Sub Title"
                                    formik={formik}
                                />
                            </div>
                            <div className="w-full flex flex-col gap-4 sm:w-2/5">
                            <div className="flex flex-col gap-4 md:flex-row w-full">
                                <div className="w-full">
                                <FormikInputGroup
                                    name = 'order'
                                    label = 'Order'
                                    formik={formik}
                                />
                                </div>
                                <div className="w-full">
                                <FormikSelectGroup
                                    name="podium"
                                    label="Podium"
                                    formik={formik}
                                    options={PODIUM_TYPE}
                                />
                                </div>
                            </div>
                                <FormikSelectGroup
                                    name="level"
                                    label="Level"
                                    formik={formik}
                                    options={TYPE_LEVEL}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 sm:flex-row w-full">
                            <div className="w-full flex flex-col gap-4 sm:w-3/5">
                                <FormikTextareaGroup
                                    name="description"
                                    label="Description"
                                    formik={formik}
                                />
                                <FormikTextareaGroup
                                    name="rules"
                                    label="Rules & Regulations"
                                    formik={formik}
                                />
                            </div>
                            <div className="w-full flex flex-col gap-4 sm:w-2/5">
                                <FormikInputGroup
                                    name="teamMinCapacity"
                                    label="Min. Participants"
                                    formik={formik}
                                />
                                <FormikInputGroup
                                    name="teamMaxCapacity"
                                    label="Max. Participants"
                                    formik={formik}
                                />
                                <FormikInputGroup
                                    name="teamMaxRegistration"
                                    label="No. of Slots"
                                    formik={formik}
                                />
                                <FormikInputGroup
                                    name="noOfSubstitution"
                                    label="No. of Substitution"
                                    formik={formik}
                                />
                            </div>
                        </div>

                        {/* <FormikSelectGroup
                            name="committee"
                            label="Committee"
                            required
                            formik={formik}
                            options={generateOptions({
                                array: committee ? committee?.docs : [],
                                labelField: 'name',
                                valueField: '_id',
                            })}
                        /> */}
                        {['Super User', 'HOD', 'Developer', 'Head'].includes(
                            user?.role?.name
                        ) && (
                            <div>
                                { (user?.committee[0]?._id === id || ['Super User', 'HOD', 'Developer'].includes(
                                            user?.role?.name
                                        ))  && (canShowUpdate && (
                                    <PrimaryButton type="submit">
                                        Update
                                    </PrimaryButton>
                                ))}
                            </div>
                        )}
                    </form>
                </>
            ) : (
                <form
                    className="flex flex-col gap-4  bg-black p-4 rounded sm:rounded-md"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="flex flex-col gap-4 sm:flex-row w-full">
                        <div className="w-full flex flex-col gap-4 sm:w-3/5">
                            <FormikInputGroup
                                name="name"
                                label="Title"
                                required
                                formik={formik}
                            />
                            <FormikInputGroup
                                name="subTitle"
                                label="Sub Title"
                                formik={formik}
                            />
                        </div>
                        <div className="w-full flex flex-col gap-4 sm:w-2/5">
                            <div className="flex flex-col gap-4 md:flex-row w-full">
                                <div className="w-full">
                                <FormikInputGroup
                                    name = 'order'
                                    label = 'Order'
                                    formik={formik}
                            />
                                </div>
                                <div className="w-full">
                                <FormikSelectGroup
                                name="podium"
                                label="Podium"
                                formik={formik}
                                options={PODIUM_TYPE}
                            />
                                </div>
                            </div>
                            
                            
                            <FormikSelectGroup
                                name="level"
                                label="Level"
                                formik={formik}
                                options={TYPE_LEVEL}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 sm:flex-row w-full">
                        <div className="w-full flex flex-col gap-4 sm:w-3/5">
                            <FormikTextareaGroup
                                name="description"
                                label="Description"
                                formik={formik}
                            />
                            <FormikTextareaGroup
                                name="rules"
                                label="Rules & Regulations"
                                formik={formik}
                            />
                        </div>
                        <div className="w-full flex flex-col gap-4 sm:w-2/5">
                            <FormikInputGroup
                                name="teamMinCapacity"
                                label="Min. Participants"
                                formik={formik}
                            />
                            <FormikInputGroup
                                name="teamMaxCapacity"
                                label="Max. Participants"
                                formik={formik}
                            />
                            <FormikInputGroup
                                name="teamMaxRegistration"
                                label="No. of Slots"
                                formik={formik}
                            />
                            <FormikInputGroup
                                name="noOfSubstitution"
                                label="No. of Substitution"
                                formik={formik}
                            />
                        </div>
                    </div>

                    <FormikSelectGroup
                        name="committee"
                        label="Committee"
                        formik={formik}
                        options={generateOptions({
                            array: committee ? committee?.docs : [],
                            labelField: 'name',
                            valueField: '_id',
                        })}
                    />
                    {['Super User','Developer'].includes(
                        user?.role?.name
                    ) && ( 
                        <div>
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        </div>
                    )}
                </form>
            )}
        </PageWithCard>
    );
};

export default AddEvents;

{
    /* <FormikProvider value={formik}>
<div className="text-bold">Photos</div>
<FieldArray 
    name = "photos"
    render={(arrayHelpers)=>{
        return(
            <div className="flex flex-col gap-2">
                <div>
                    {formik.values.photos.map((ele,index)=>(
                        <div
                            className="relative p-4 mb-2"
                            style={{
                                border: '1px solid #d6c7c7',
                                borderRadius: '5px', 
                            }}
                            key={index}
                        >
                            <FormikDirectFileUpload 
                                name = {`photos.${index}`}
                                formik = { formik }
                                randomize = {true}
                                location={`1648_entertainment/event/`}
                            />
                            <div>
                                <DangerButton
                                    className="mt-3"
                                    onClick={() => {
                                        arrayHelpers.remove(
                                            index
                                        );
                                    }}
                                    type="button"
                                >
                                    Remove
                                </DangerButton>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        )
    }}
/>
</FormikProvider> */
}
