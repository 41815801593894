import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import {
    fetchCommittee,
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import {
    editShortlistRegistrationRequest,
    fetchRegistrationRequests,
} from '../../app/reducers/RegistrationRequest/registrationRequestSlice';
import { editData } from '../../app/generators/generateThunks';
const computeInitialValue = (data) => {
    let final = data;
    if (data?.committee1?._id) {
        final = { ...final, committee1: data?.committee1?._id };
    }
    return final;
};
const BulkEditShortlisted = ({
    editData,
    setSelectedEditReqRows,
    showApproveModal,
    setShowApproveModal,
}) => {
    const { committee } = useSelector(getCommittees);
    const dispatch = useDispatch();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            committee1 : ''
        },
        
        onSubmit: async (values) => {
            console.log('bulk edit shortlist', values);
            for(let i  = 0; i < editData.length; i++){
                console.log('loop for id', editData[i])
                await dispatch(
                    editShortlistRegistrationRequest({
                        _id: editData[i],
                        committee1: values?.committee1,
                    })
                );
            }
            dispatch(
                fetchRegistrationRequests({
                    status: 'shortlisted',
                    populate: true,
                })
            );
            setShowApproveModal(false);
            setSelectedEditReqRows([]);
        },
    });
    return (
        <ModalBasic
            modalOpen={showApproveModal}
            setModalOpen={setShowApproveModal}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 p-4"
            >
                <FormikSelectGroup
                    required
                    formik={formik}
                    label="Committee"
                    name="committee1"
                    options={generateOptions({
                        array: committee ? committee?.docs : [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                />
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default BulkEditShortlisted;
