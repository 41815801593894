import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    editEmployee,
    fetchEmployee,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { array, object, string } from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import {
    editCollege,
    fetchCollege,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import { fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { ChevronRight } from 'react-feather';

const EditCollege = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { elementEditData, elementEditDataLoading } =
        useSelector(getColleges);
    useEffect(() => {
        dispatch(fetchCollege({ _id: id }));
    }, []);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...elementEditData,
        },
        validationSchema: object({
            name: string().required(),
            // code: string().required(),
            // status: string().required(),
            // photos: array(),
            volunteers: array(),
        }),
        onSubmit: (values, { resetForm }) => {
            console.log('college', values)
            dispatch(editCollege(values));
        },
    });
    const { role, loading } = useSelector(getRoles);
    const data = useMemo(() => (role?.docs ? role.docs : []), [role]);
    useEffect(() => {
        dispatch(fetchRoles({ type: 'External',sort :{name:1} }));
    }, []);
    return (
        <PageWithCard heading={
            <>
            <Link  to={'/college/view'}>Colleges</Link>
            <span className="text-slate-200 inline-block px-1"><ChevronRight size={14} className='-mb-0.5' /> </span>
            <span>{'Edit College'}</span>
            </>
        }>
            {elementEditDataLoading ? (
                <ClipLoader color="#FFFFFF" size={18} />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4  bg-black p-4 rounded sm:rounded-md"
                >
                    <FormikInputGroup
                        name="name"
                        label="College Name"
                        formik={formik}
                        required
                    />
                    {/* <FormikInputGroup
                        type="number"
                        name="code"
                        label="College Code"
                        formik={formik}
                        required
                    /> */}

                    
                    <FormikProvider value={formik}>
                        <div className="text-bold text-slate-200">CL/ACL</div>
                        <FieldArray
                            name="volunteers"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col gap-2">
                                        {/* <p>Address</p> */}
                                        <div>
                                            {formik.values.volunteers?.map(
                                                (ele, index) => (
                                                    <div
                                                        className="relative p-4 mb-2"
                                                        style={{
                                                            border: '1px solid #475569',
                                                            borderRadius: '5px',
                                                        }}
                                                        key={index}
                                                    >
                                                        <div className="flex gap-2 ">
                                                            <FormikSelectGroup
                                                                name={`volunteers.${index}.role`}
                                                                label="Role"
                                                                formik={formik}
                                                                options={
                                                                    data?.map
                                                                        ? data?.map(
                                                                              (
                                                                                  ele
                                                                              ) => ({
                                                                                  label: ele.name,
                                                                                  value: ele._id,
                                                                              })
                                                                          )
                                                                        : []
                                                                }
                                                            />
                                                            <FormikInputGroup
                                                                type="number"
                                                                name={`volunteers.${index}.seats`}
                                                                label="Seats"
                                                                formik={formik}
                                                            />
                                                        </div>
                                                        <div>
                                                            <DangerButton
                                                                className="mt-3"
                                                                onClick={() => {
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    );
                                                                }}
                                                                type="button"
                                                            >
                                                                Remove
                                                            </DangerButton>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div>
                                            <PrimaryButton
                                                onClick={() => {
                                                    arrayHelpers.push({});
                                                }}
                                                type="button"
                                            >
                                                Add More
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>
                    {/* <FormikCheckbox
                        name="status"
                        label="Status"
                        formik={formik}
                    /> */}
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </PageWithCard>
    );
};

export default EditCollege;
{/* <FormikProvider value={formik}>
                        <div className="text-bold">Photos</div>
                        <FieldArray
                            name="photos"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col gap-2">
                                        {/* <p>Address</p> */}
                                        // <div>
                                        //     {formik.values?.photos?.map(
                                        //         (ele, index) => (
                                        //             <div
                                        //                 className="relative p-4 mb-2"
                                        //                 style={{
                                        //                     border: '1px solid #d6c7c7',
                                        //                     borderRadius: '5px',
                                        //                 }}
                                        //                 key={index}
                                        //             >
                                        //                 <FormikDirectFileUpload
                                        //                     name={`photos.${index}`}
                                        //                     formik={formik}
                                        //                     randomize={true}
                                        //                     location={`1648_entertainment/college/`}
                                        //                 />
                                        //                 <div>
                                        //                     <DangerButton
                                        //                         className="mt-3"
                                        //                         onClick={() => {
                                        //                             arrayHelpers.remove(
                                        //                                 index
                                        //                             );
                                        //                         }}
                                        //                         type="button"
                                        //                     >
                                        //                         Remove
                                        //                     </DangerButton>
                                        //                 </div>
                                        //             </div>
                                        //         )
                                        //     )}
                                        // </div>

                                        // <div>
                                        //     <SecondaryButton
                                        //         onClick={() => {
                                        //             arrayHelpers.push('');
                                        //         }}
                                        //         type="button"
                                        //     >
                                        //         Add More
                                        //     </SecondaryButton>
                                        // </div>
                                    // </div>
                                // );
                            // }}
                        // />
                    // </FormikProvider> */}