import React, { useEffect, useMemo, useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { array, object, string } from 'yup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { getColleges } from '../../app/reducers/College/collegeSlice';
import {
    editParticipant,
    fetchParticipant,
    fetchParticipants,
    getParticipants,
} from '../../app/reducers/Participant/teamParticipantSlice';
import { ClipLoader } from 'react-spinners';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { fetchEvent, getEvents } from '../../app/reducers/Event/eventSlice';
import { useParams } from 'react-router-dom';
import FormikINPhoneInput from '../../components/formik/FormikINPhoneInput';
import { toast } from 'react-toastify';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const EditOtseParticipant = ({
    requestData,
    showOtseEditModal,
    setShowOtseEditModal,
}) => {
    console.log('req otse', requestData)
    const {user} = useSelector(getAuth);
    const { id, eventId } = useParams();
    const [eventDetails, setEventDetails] = useState({});
    const {elementEditDataLoading } =
        useSelector(getParticipants);
    const {elementEditData} = useSelector(getEvents);
    const [existingContactNumbers, setExistingContactNumbers] = useState([]);
    const dispatch = useDispatch();

    const computeEventDetails = async () => {
        const payload = { _id: requestData?.event };
        const stringData = QueryString.stringify(payload);
        const resp = await authAxiosInstance.get(`/event?${stringData}`);
        const respData = resp?.data?.data?.docs[0];
        setEventDetails(respData);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...requestData,
        },
        validationSchema: object({
            participants: array().of(
                object({
                    firstName: string()
                        .matches(
                            /^[A-Z][a-z]*$/,
                            'Case sensitive & can not contain spaces. i.e. Hello'
                        )
                        .label('First Name')
                        .required(),
                    lastName: string()
                        .matches(
                            /^[A-Z][a-z]*$/,
                            'Case sensitive & can not contain spaces. i.e. Hello'
                        )
                        .label('Last Name')
                        .required(),
                    contactNumber: string()
                        .matches(
                            /^[0-9]{10}$/,
                            'Contact Number must be numbers (exact 10 digits)'
                        )
                        .min(10, 'Min 10 digits')
                        .max(10, 'Max 10 digits')
                        .label('Contact Number')
                        .required(),
                })
            ),
        }),
        onSubmit: async (values) => {

            const newContactNumbers = values.participants.map(
                (p) => p.contactNumber
            );
            const duplicateNewContacts = newContactNumbers.filter(
                (item, index) => newContactNumbers.indexOf(item) !== index
            );
            if (duplicateNewContacts.length > 0) {
                toast.error(
                    'Some contact numbers are duplicated in the added participants .'
                );
                setSubmitting(false);
                return;
            }

            const duplicateContacts = values.participants.filter(
                (participant) =>
                    existingContactNumbers.includes(
                        participant.contactNumber
                    )
            );

            if (duplicateContacts.length > 0) {
                toast.error(
                    'Some contact numbers are already registered for this event.'
                );
                setSubmitting(false);
                return;
            }

            if (
                values?.participants?.length >= elementEditData?.teamMinCapacity
            ) {
                await dispatch(editParticipant(values));
                await dispatch(
                    fetchParticipants({
                        event: eventId,
                        college: user?.college?._id,
                    })
                );
                await dispatch(fetchEvent({ _id: eventId }));
            } else {
                toast.error(
                    ` Alert! It seems you haven't met the minimum participant (${elementEditData?.teamMinCapacity}) 
                    requirement for this event. Please double-check and 
                    ensure all criteria are fulfilled accordingly.`
                    // `Min Participants ${elementEditData?.teamMinCapacity} require`
                );
            }
            setShowOtseEditModal(false);
        },
    });
    useEffect(()=>{
        if(eventId){
            dispatch(fetchEvent({_id:eventId}))
        }
    },[eventId])

    useEffect(() => {
        computeEventDetails();
    }, [
        requestData,
        id,
        eventId,
        JSON.stringify(formik?.values?.participants),
    ]);
    useEffect(() => {
        const fetchExistingParticipants = async () => {
            const participantPayload = {
                event: eventId,
                type: 'otse',
                college: user?.college?._id,
            };
            const stringParticipantData =
                QueryString.stringify(participantPayload);
            const respParticipant = await authAxiosInstance.get(
                `/teamParticipant?${stringParticipantData}`
            );
            const participants = respParticipant?.data?.data?.docs || [];
            setExistingContactNumbers(participants.map((p) => p.contactNumber));
        };

        fetchExistingParticipants();
    }, [eventId]);
   
    return (
        <ModalBasic
            modalOpen={showOtseEditModal}
            setModalOpen={setShowOtseEditModal}
        >
            {elementEditDataLoading ? (
                <ClipLoader color="#FFFFFF" size={18} />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 pb-4 m-4"
                >
                    <FormikProvider value={formik}>
                      
                        <FieldArray
                            name="participants"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col gap-2">
                                        <div>
                                            {formik.values.participants?.map(
                                                (ele, index) => (
                                                    <div
                                                        className="relative mb-2 flex flex-col gap-4"
                                                        style={{
                                                            border: '1px solid #475569',
                                                            borderRadius: '5px',
                                                            padding: '16px',
                                                        }}
                                                        key={index}
                                                    >
                                                        <div className="flex flex-col gap-4 sm:flex-row w-full">
                                                            <div className="w-full">
                                                                <FormikInputGroup
                                                                    required
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    label="First Name"
                                                                    name={`participants.${index}.firstName`}
                                                                />
                                                            </div>
                                                            <div className="w-full">
                                                                <FormikInputGroup
                                                                    required
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    label="Last Name"
                                                                    name={`participants.${index}.lastName`}
                                                                />
                                                            </div>
                                                            <div className="w-full">
                                                                <FormikINPhoneInput
                                                                    required
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    name={`participants.${index}.contactNumber`}
                                                                    label="Phone No."
                                                                />
                                                            </div>
                                                        </div>
                                                        {index != 0 && (<div>
                                                            <DangerButton
                                                                className="mt-3"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    );
                                                                    computeEventDetails();
                                                                }}
                                                                type="button"
                                                            >
                                                                Remove
                                                            </DangerButton>
                                                        </div>)}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        { eventDetails?.teamMaxCapacity -
                                            formik?.values?.participants
                                            ?.length !==
                                            0 && (
                                            <div>
                                                <PrimaryButton
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        arrayHelpers.push({});
                                                    }}
                                                    type="button"
                                                >
                                                    Add Participant
                                                </PrimaryButton>
                                            </div>
                                        )}
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>
                    <div className="ps-4">
                        <PrimaryButton type="submit">Update</PrimaryButton>
                    </div>
                </form>
            )}
        </ModalBasic>
    );
};

export default EditOtseParticipant;
