import React from 'react';
import _ from 'lodash';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const FormikEditorInput = ({
    formik,
    label = '',
    required = false,
    name,
    fullWidth,
    ...props
}) => {
    return (
        <div className={fullWidth ? 'w-full' : ''}>
            <label className="block text-sm font-medium mb-1" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>
            <CKEditor
                editor={ClassicEditor}
                data={_.at(formik.values, name)[0]}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    formik.setFieldValue(name, data);
                }}
                name={name}
                onBlur={() => formik.handleBlur({ target: { name } })}
                className="w-full form-input"
                onError={(error) => {
                    console.log(error);
                }}
                {...props}
            />
            {formik.errors[name] && formik.touched[name] ? (
                <p className="text-xs text-red-500">{formik.errors[name]}</p>
            ) : null}
        </div>
    );
};

export default FormikEditorInput;
