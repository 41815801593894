import React from 'react';
import MediaLibraryAssets from '../../pages/MediaLibrary/MediaLibraryAssets';

const PageWithCard = ({ children, heading = '' }) => {
    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full min-h-screen"
                style={{backgroundColor : '#141414'}}
            >
                {/* Page header */}
                <div className="mb-2">
                    {/* Title */}
                    {/* <h1 className="text-2xl md:text-3xl text-slate-200 font-bold">
                        {heading}
                    </h1> */}
                    <header className="">
                        {typeof heading === 'string' ? (
                            <h2 className="text-sm md:text-base text-slate-200 font-medium mb-4">{heading}</h2>
                        ) : (
                            <div className="text-sm md:text-base text-slate-200 font-medium mb-4">{heading}</div>
                        )}
                    </header>
                </div>

                {/* Content */}
                <div className="bg-white shadow-lg rounded-sm mb-6"
                    style={{backgroundColor : '#141414'}}
                >
                    <div className="w-full">
                        <div>{children}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageWithCard;
