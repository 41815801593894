import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { array, object, string } from 'yup';
import { createUser, getUsers } from '../../app/reducers/Users/usersSlice';
import FormikFileInput from '../../components/formik/FormikFileInput';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import {
    createEmployee,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import { createCollege } from '../../app/reducers/College/collegeSlice';
import { ROLE_TYPE } from '../../utils/dropdownOptions';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const AddCollege = () => {
    const { user } = useSelector(getAuth);
    // const { loading } = useSelector(getEmployees);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            name: '',
            // code: '',
            // status: true,
            // photos: [''],
            volunteers: [
                {
                    role: '',
                    seats: '',
                },
                {
                    role: '',
                    seats: '',
                },
            ],
        },
        validationSchema: object({
            name: string().required(),
            // code: string().required(),
            // status: string().required(),
            // photos: array(),
            volunteers: array(),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('TRying');
            console.log(values);
            dispatch(createCollege(values));
            formik.setFieldValue('photos', []);
            resetForm();
        },
    });

    const { role, loading } = useSelector(getRoles);
    const data = useMemo(() => {
        if (role && role?.docs) {
            const data = role.docs?.filter((ele) =>
                ['CL', 'ACL'].includes(ele?.name)
            );
            return data;
        } else {
            return [];
        }
    }, [role]);
    useEffect(() => {
        dispatch(fetchRoles({ type: 'External',sort :{name:1} }));
    }, []);

    // const externalRoles = data.filter((role) => role.level === "External");
    // const externalRolesList = externalRoles.map((externalRole) => ({
    //   label: externalRole.name,
    //   value: externalRole.name,
    // }));

    const externalRolesList = [];

    return (
        <PageWithCard heading="Add College">
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4  bg-black p-4 rounded sm:rounded-md"
            >
                <FormikInputGroup
                    name="name"
                    label="College Name"
                    formik={formik}
                    required
                />
                <FormikProvider value={formik}>
                    <div className="text-bold text-slate-200">CL/ACL</div>
                    <FieldArray
                        name="volunteers"
                        render={(arrayHelpers) => {
                            return (
                                <div className="flex flex-col gap-2">
                                    <div>
                                        {formik.values.volunteers.map(
                                            (ele, index) => (
                                                <div
                                                    className="relative p-4 mb-2"
                                                    style={{
                                                        border: '1px solid #475569',
                                                        borderRadius: '5px',
                                                    }}
                                                    key={index}
                                                >
                                                    <div className="flex gap-2 ">
                                                        <FormikSelectGroup
                                                            name={`volunteers.${index}.role`}
                                                            label="Role"
                                                            formik={formik}
                                                            options={
                                                                data?.map
                                                                    ? data?.map(
                                                                          (
                                                                              ele
                                                                          ) => ({
                                                                              label: ele.name,
                                                                              value: ele._id,
                                                                          })
                                                                      )
                                                                    : []
                                                            }
                                                        />
                                                        <FormikInputGroup
                                                            type="number"
                                                            name={`volunteers.${index}.seats`}
                                                            label="Seats"
                                                            formik={formik}
                                                        />
                                                    </div>
                                                    <div>
                                                        <DangerButton
                                                            className="mt-3"
                                                            onClick={() => {
                                                                arrayHelpers.remove(
                                                                    index
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            Remove
                                                        </DangerButton>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div>
                                        <PrimaryButton
                                            onClick={() => {
                                                arrayHelpers.push({});
                                            }}
                                            type="button"
                                        >
                                            Add More
                                        </PrimaryButton>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </FormikProvider>

                {['Super User', 'Developer'].includes(user?.role?.name) && (
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                )}
            </form>
        </PageWithCard>
    );
};

export default AddCollege;

{
    /* <FormikInputGroup
                    type="number"
                    name="code"
                    label="College Code"
                    formik={formik}
                    required
                />

                <FormikProvider value={formik}>
                    <div className="text-bold">Photos</div>
                    <FieldArray
                        name="photos"
                        render={(arrayHelpers) => {
                            return (
                                <div className="flex flex-col gap-2">
                                    {/* <p>Address</p> */
}
// <div>
{
    /* {formik.values.photos.map(
                                            (ele, index) => (
                                                <div
                                                    className="relative p-4 mb-2"
                                                    style={{
                                                        border: '1px solid #d6c7c7',
                                                        borderRadius: '5px',
                                                    }}
                                                    key={index}
                                                >
                                                    <FormikDirectFileUpload
                                                        name={`photos.${index}`}
                                                        formik={formik}
                                                        randomize={true}
                                                        location={`1648_entertainment/college/`}
                                                    />
                                                    <div>
                                                        <DangerButton
                                                            className="mt-3"
                                                            onClick={() => {
                                                                arrayHelpers.remove(
                                                                    index
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            Remove
                                                        </DangerButton>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div> */
}
// <div>
//     <SecondaryButton
//         onClick={() => {
//             arrayHelpers.push('');
//         }}
//         type="button"
//     >
//         Add More
//     </SecondaryButton>
// </div>
// </div>
// );
// }}
// />
// </FormikProvider>

// <FormikProvider value={formik}>
{
    /* <div className="text-bold">Volunteers</div> */
}
{
    /* <FieldArray */
}
// name="volunteers"
// render={(arrayHelpers) => {
// return (
// <div className="flex flex-col gap-2">
{
    /* <p>Address</p> */
}
{
    /* <div> */
}
{
    /* {formik.values.volunteers.map(
                                            (ele, index) => (
                                                <div
                                                    className="relative p-4 mb-2"
                                                    style={{
                                                        border: '1px solid #d6c7c7',
                                                        borderRadius: '5px',
                                                    }}
                                                    key={index}
                                                >
                                                    <div className="flex gap-2 ">
                                                        <FormikSelectGroup
                                                            name={`volunteers.${index}.role`}
                                                            label="Role"
                                                            formik={formik}
                                                            options={
                                                                data?.map
                                                                    ? data?.map(
                                                                          (
                                                                              ele
                                                                          ) => ({
                                                                              label: ele.name,
                                                                              value: ele._id,
                                                                          })
                                                                      )
                                                                    : []
                                                            }
                                                        />
                                                        <FormikInputGroup
                                                            type="number"
                                                            name={`volunteers.${index}.seats`}
                                                            label="Seats"
                                                            formik={formik}
                                                        />
                                                    </div>
                                                    <div>
                                                        <DangerButton
                                                            className="mt-3"
                                                            onClick={() => {
                                                                arrayHelpers.remove(
                                                                    index
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            Remove
                                                        </DangerButton>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div> */
}
{
    /* <div>
                                        <SecondaryButton
                                            onClick={() => {
                                                arrayHelpers.push({});
                                            }}
                                            type="button"
                                        >
                                            Add More
                                        </SecondaryButton>
                                    </div> */
}
{
    /* </div> */
}
// );
// }}
// />
{
    /* </FormikProvider> */
}

// {/* <FormikCheckbox name="status" label="Status" formik={formik} /> */} */}
