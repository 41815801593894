import React from 'react';
import PageWithCard from '../components/infrastructure/PageWithCard';
import PrimaryButton from '../components/infrastructure/Buttons/PrimaryButton';
import { useNavigate } from 'react-router';

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <PageWithCard>
            <h1 className='text-slate-200'>Page not found</h1>
            <div className="mt-4">
                <PrimaryButton onClick={() => navigate('/')}>
                    Back To Dashboard
                </PrimaryButton>
            </div>
        </PageWithCard>
    );
};

export default PageNotFound;
