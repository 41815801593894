import React from 'react';
import {
    Briefcase,
    File,
    Monitor,
    User,
    Lock,
    BookOpen,
    Upload,
    Tag,
    Star,
    Film,
    Slack,
    CheckSquare,
    Edit,
} from 'react-feather';
import SidebarGroup from '../../components/infrastructure/SidebarGroup';
import SidebarSingle from '../../components/infrastructure/SidebarSingle';
import SidebarTopText from '../../components/infrastructure/SidebarTopText';
import { useDispatch, useSelector } from 'react-redux';
import { getRoles } from '../../app/reducers/Role/roleSlice';
import { useMemo } from 'react';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { LINKS } from '../../utils/links';

const SidebarLinks = ({ sidebarOpen, sidebarExpanded, setSidebarExpanded,handleLinkClick }) => {
    console.log('sidebarOpen props@ sidebar-links', sidebarOpen)
    return (
        <ul className="mt-3">
            {/* Dashboard */}
            <SidebarTopText text="" sidebarOpen={sidebarOpen} />
            {LINKS.map((ele, i) => {
                return (
                    <React.Fragment key={i}>
                        {
                            {
                                single: (
                                    <SidebarSingle
                                        activePath={ele?.link}
                                        name={ele?.label}
                                        Icon={<ele.Icon />}
                                        sidebarOpen={sidebarOpen}
                                        allowedRoles={ele?.allowedRoles}
                                        allowedFeatures={ele?.allowedFeatures}
                                        handleLinkClick={handleLinkClick}
                                    />
                                ),
                                group: (
                                    <SidebarGroup
                                        links={ele?.links}
                                        heading={ele?.heading}
                                        Icon={<ele.Icon />}
                                        setSidebarExpanded={setSidebarExpanded}
                                        sidebarExpanded={sidebarExpanded}
                                        handleLinkClick={handleLinkClick}
                                        sidebarOpen={sidebarOpen}
                                    />
                                ),
                            }[ele.type]
                        }
                    </React.Fragment>
                );
            })}
        </ul>
    );
};

export default SidebarLinks;
