import React, { useEffect } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useFormik } from 'formik';
import { object, ref, string } from 'yup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchColleges,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import { NCP_REPRESENTATIVE_ROLE, link_data } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { createEmployee } from '../../app/reducers/Users/EmployeeSlice';
import FormikINPhoneInput from '../../components/formik/FormikINPhoneInput';
import { getWebsiteCreatives } from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import NavbarLeft from '../../components/Navbar/NavbarLeft';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';

const NcpRegistration = () => {
    // const { college } = useSelector(getColleges);
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    console.log('creative data', websiteCreatives);
    const dynamicDefaultData = websiteCreatives[0];
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // useEffect(()=>{
    //     dispatch(fetchColleges({ populate : true }))
    // },[])
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            username: '',
            ncpCollege: '',
            collegeIdPhoto: '',
        },
        validationSchema: object({
            firstName: string()
                .matches(/^[A-Z][a-z]*$/, 'Case sensitive & can not contain spaces. i.e. Hello').label('First Name')
                .required(),
            lastName: string()
                .matches(/^[A-Z][a-z]*$/, 'Case sensitive & can not contain spaces. i.e. Hello').label('Last Name')
                .required(),
            email: string().email('Invalid email').label('Email').required(),
            password: string().matches(/^\S+$/,'Space in not allowed').label('Password').required(),
            confirmPassword: string().label('Confirm Password')
                .required()
                .oneOf([ref('password')], 'Password must be same'),
            username: string()
                .matches(
                    /^[0-9]{10}$/,
                    'Contact Number must be numbers (exact 10 digits)'
                )
                .min(10,'Min 10 digits')
                .max(10, 'Max 10 digits').label('Contact Number')
                .required(),
            ncpCollege: string().label('College').required(),
            collegeIdPhoto : string().label('College ID Photo').required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('ncp register', values);
            const ncpRequestObj = {
                ...values,
                contactNumber: values?.username,
                role: NCP_REPRESENTATIVE_ROLE,
                partOfO2: 'No',
            };
            console.log('register values', ncpRequestObj);
            const resp = await dispatch(createEmployee(ncpRequestObj));
            if (resp?.payload?.success) {
                navigate(`/ncpGreetPage/${resp?.payload?.data?._id}`);
            }
            // resetForm();
        },
    });
    return (
        // <>
        //     <NavbarLeft props={link_data} />
        //     <main className="bg-black">
        //         <div className="relative md:flex pt-[50px] min-h-screen">
        //             <div
        //                 className="hidden md:w-1/2  md:flex "
        //                 aria-hidden="true"
        //                 style={{
        //                     backgroundColor: '#000000',
        //                 }}
        //             >
        //                 {/* <div className='w-[400px] h-[400px] lg:w-[500px] lg:h-[500px] mx-auto'> */}
        //                 <div className="w-full h-full">
        //                     <img
        //                         className="object-cover w-full h-full"
        //                         style={{
        //                             objectPosition: 'center center',
        //                         }}
        //                         src={`${process.env.REACT_APP_S3_URL}${dynamicDefaultData?.banner}`}
        //                         // width="760"
        //                         // height="1024"
        //                         alt="Authentication"
        //                     />
        //                 </div>
        //             </div>
                    <div className="md:w-1/2 md:flex md:flex-col md:item-center">
                        {/* <div className="min-h-screen h-full flex flex-col "> */}
                        <div className="w-full lg:w-2/3  mx-auto px-4 py-8">
                            <form
                                className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md"
                                onSubmit={formik.handleSubmit}
                            >
                                <div className="text-left ">
                                    <h1 className="text-3xl text-slate-200 font-bold mb-2 ">
                                        Welcome 👋
                                    </h1>
                                    <p className="text-slate-200 text-sm mb-2 ">
                                        Fill this form to register
                                    </p>
                                </div>
                                <div className="flex flex-col gap-4 sm:flex-row w-full">
                                    <div className="w-full">
                                        <FormikInputGroup
                                            name="firstName"
                                            required
                                            formik={formik}
                                            label="First Name"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <FormikInputGroup
                                            name="lastName"
                                            required
                                            formik={formik}
                                            label="Last Name"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 sm:flex-row w-full">
                                    <div className="w-full">
                                        <FormikINPhoneInput
                                            name="username"
                                            label="Contact Number"
                                            formik={formik}
                                            required
                                        />
                                    </div>
                                    <div className="w-full">
                                        <FormikInputGroup
                                            name="email"
                                            required
                                            formik={formik}
                                            label="Email"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 sm:flex-row w-full">
                                    <div className="w-full">
                                        <FormikInputGroup
                                            name="ncpCollege"
                                            required
                                            formik={formik}
                                            label="College"
                                        />
                                    </div>
                                </div>
                                <FormikDirectImageUpload
                                    name="collegeIdPhoto"
                                    label="College ID Photo"
                                    formik={formik}
                                    location={`1648_entertainment/externalRegistered/`}
                                    required
                                    randomize
                                />
                                {/* <div className="flex flex-col gap-4 sm:flex-row w-full"> */}
                                {/* <div className="w-full"> */}
                                <FormikInputGroup
                                    name="password"
                                    required
                                    formik={formik}
                                    label="Password"
                                    type="password"
                                />
                                {/* </div> */}
                                {/* <div className="w-full"> */}
                                <FormikInputGroup
                                    name="confirmPassword"
                                    required
                                    formik={formik}
                                    label="Confirm Password"
                                    type="password"
                                />
                                {/* </div> */}
                                {/* </div> */}

                                <div className="text-center mt-6">
                                    <PrimaryButton
                                        type="submit"
                                        className=" sm:w-[40%]"
                                        style={{
                                            backgroundColor:
                                                dynamicDefaultData?.highlightedText
                                                    ? dynamicDefaultData?.highlightedText
                                                    : '',
                                        }}
                                    >
                                        Submit
                                    </PrimaryButton>
                                </div>
                            </form>
                        </div>
                        {/* </div> */}
                    </div>
        //         </div>
        //     </main>
        // </>
    );
};

export default NcpRegistration;
