import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { generateOptions } from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { getCommittees } from '../../app/reducers/Committee/committeeSlice';
import { getRoles } from '../../app/reducers/Role/roleSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import {
    editEmployee,
    fetchEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import { CORE_ROLE } from '../../utils/constants';

const computeInitialData = (data) => {
    let final = data;
    if (data?.committee?.length) {
        if (data?.committee?.length > 1) {
            final = {
                ...final,
                committee: data.committee.map((ele) => ele._id),
            };
        } else {
            final = { ...final, committee: data.committee[0]._id };
        }
    }
    if (data?.role?._id) {
        final = { ...final, role: data.role._id };
    }
    return final;
};
const EditCoreTeam = ({
    filterValues,
    requestData,
    showApproveModal,
    setShowApproveModal,
}) => {
    console.log('req data', requestData);
    const { committee } = useSelector(getCommittees);
    const { role } = useSelector(getRoles);
    const dispatch = useDispatch();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...computeInitialData(requestData),
        },
        onSubmit: async (values) => {
            console.log('edit data', values);
            let canSubmit = false;
            let errorCommittee = null;
            let committeeArray = [];
            if (Array.isArray(values?.committee)) {
                let existsData = requestData?.committee;
                for (let i = 0; i < values?.committee?.length; i++) {
                    let isCommitteeAlreadyAssigned = existsData?.some(
                        (comId) => {
                            if (comId?._id === values.committee[i]) {
                                committeeArray.push(comId?._id);
                                return true;
                            }
                        }
                    );
                    if (isCommitteeAlreadyAssigned) {
                        continue;
                    }

                    const roleOption = committee?.docs?.find(
                        (ele) => ele?._id === values.committee[i]
                    );
                    const seatsAvailable = roleOption?.members?.find(
                        (ele) => ele?.role?._id === values?.role
                    );

                    const payload = {
                        role: values?.role,
                        committee: values.committee[i],
                    };

                    const string = QueryString.stringify(payload);
                    const allUsers = await authAxiosInstance.get(
                        `/users/employee?${string}`
                    );
                    const userGet = allUsers?.data?.data?.docs;
                    if (seatsAvailable?.seats > userGet.length) {
                        committeeArray.push(values.committee[i]);
                        const editObj = {
                            ...values,
                            committee: committeeArray,
                        };
                        await dispatch(editEmployee(editObj));
                        if (filterValues?.committee?.length) {
                            await dispatch(
                                fetchEmployees({
                                    populate: true,
                                    'role.name': { $in: CORE_ROLE },
                                    'committee._id': {
                                        $in: [requestData?.committee[0]?._id],
                                    },
                                    sort: { 'committee.name': 1, firstName: 1 },
                                })
                            );
                        } else {
                            await dispatch(
                                fetchEmployees({
                                    populate: true,
                                    'role.name': { $in: CORE_ROLE },
                                    sort: { 'committee.name': 1, firstName: 1 },
                                })
                            );
                        }
                    } else {
                        canSubmit = false;
                        console.log('canSubmit', canSubmit);
                        errorCommittee = committee?.docs?.find(
                            (ele) => ele._id === values.committee[i]
                        );

                        toast.error(
                            `Seats are full. ${errorCommittee.name} Committee is not assign to selected role`
                        );
                    }
                }
            } else {
                let selectedCommittee = values?.committee;
                const payload = {
                    _id: selectedCommittee,
                    populate: true,
                };

                const string = QueryString.stringify(payload);
                const res = await authAxiosInstance.get(`/committee?${string}`);
                const committeeData = res?.data?.data?.docs?.[0];

                const memberObj = committeeData?.members?.find(
                    (ele) => ele.role._id == values?.role
                );
                const payloadUser = {
                    role: values?.role,
                    committee: selectedCommittee,
                };

                const stringUser = QueryString.stringify(payloadUser);
                const allUsers = await authAxiosInstance.get(
                    `/users/employee?${stringUser}`
                );
                const userGet = allUsers?.data?.data?.docs;
                if (!memberObj?.seats) {
                    toast.error('Seats are not allocated');
                    return;
                }
                if (memberObj?.seats > userGet?.length) {
                    canSubmit = true;
                } else {
                    canSubmit = false;

                    errorCommittee = committee?.docs?.find(
                        (ele) => ele._id === selectedCommittee
                    );

                    toast.error(`${errorCommittee.name} Seats full`);
                    return;
                }
            }
            if (canSubmit) {
                await dispatch(editEmployee(values));
                if (filterValues?.committee?.length) {
                    await dispatch(
                        fetchEmployees({
                            populate: true,
                            'role.name': { $in: CORE_ROLE },
                            'committee._id': {
                                $in: [requestData?.committee[0]?._id],
                            },
                            sort: { 'committee.name': 1, firstName: 1 },
                        })
                    );
                } else {
                    await dispatch(
                        fetchEmployees({
                            populate: true,
                            'role.name': { $in: CORE_ROLE },
                            sort: { 'committee.name': 1, firstName: 1 },
                        })
                    );
                }
            }
            setShowApproveModal(false);
        },
    });
    return (
        <ModalBasic
            modalOpen={showApproveModal}
            setModalOpen={setShowApproveModal}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 p-4"
            >
                <FormikInputGroup
                    label="First Name"
                    name="firstName"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="Last Name"
                    name="lastName"
                    formik={formik}
                    readOnly
                />
                {requestData?.role?.sharedRole ? (
                    <FormikMultiSelect
                        required
                        label="Committee"
                        name="committee"
                        formik={formik}
                        options={generateOptions({
                            array: committee ? committee?.docs : [],
                            labelField: 'name',
                            valueField: '_id',
                        })}
                    />
                ) : (
                    <FormikSelectGroup
                        required
                        label="Committee"
                        name="committee"
                        formik={formik}
                        options={generateOptions({
                            array: committee ? committee?.docs : [],
                            labelField: 'name',
                            valueField: '_id',
                        })}
                    />
                )}
                {requestData?.role?.sharedRole ? (
                    <FormikSelectGroup
                        isDisabled
                        label="Role"
                        name="role"
                        formik={formik}
                        options={generateOptions({
                            array: role
                                ? role?.docs?.filter(
                                      (ele) =>
                                          !['Super User', 'Developer'].includes(
                                              ele?.name
                                          )
                                  )
                                : [],
                            labelField: 'name',
                            valueField: '_id',
                        })}
                    />
                ) : (
                    <FormikSelectGroup
                        required
                        label="Role"
                        name="role"
                        formik={formik}
                        options={generateOptions({
                            array: role
                                ? role?.docs?.filter(
                                      (ele) => ele.name != 'Super User'
                                  )
                                : [],
                            labelField: 'name',
                            valueField: '_id',
                        })}
                    />
                )}
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default EditCoreTeam;
