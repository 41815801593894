import { FieldArray, useFormik } from 'formik';
import React, { useEffect } from 'react';
import FormikLabel from '../../components/formik/FormikLabel';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWebsiteCreativeData,
    fetchWebsiteCreativesData,
    getWebsiteCreatives,
} from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import FormikDraggableProvider from '../../components/formik/FormikDraggableProvider';
import { Reorder } from 'framer-motion';
import customId from 'custom-id';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import { XSquare } from 'react-feather';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const CoreTeam = ({ canShowOtherUpdate, pages }) => {
    const { user } = useSelector(getAuth);
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    const elementEditData = websiteCreatives[0];
    console.log('edit data', elementEditData);
    const dispatch = useDispatch();

    const computeInitialValue = (data) => {
        if (data) {
            return data;
        } else {
            return {
                name: pages,
                coreTeamCard: [
                    {
                        url: '',
                    },
                ],
            };
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(elementEditData),
        onSubmit: (values) => {
            console.log('core values', values);
            const obj = {
                ...values,
                name: pages,
            };
            console.log('object', obj);
            dispatch(createWebsiteCreativeData(obj));
        },
    });

    useEffect(() => {
        dispatch(fetchWebsiteCreativesData({ name: 'Coreteam' }));
    }, []);
    return (
        <>
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%] mb-3"
            >
                <div className="w-full flex flex-row  justify-between">
                    <div className="w-[30%] pt-5">
                        <FormikLabel label="Core Team" />
                    </div>
                    <div className="w-[70%]">
                        <FormikDraggableProvider
                            formik={formik}
                            name={'coreTeamCard'}
                        >
                            <FieldArray
                                name="coreTeamCard"
                                render={(arrayHelpers) => {
                                    return (
                                        <div className="flex flex-col my-2 gap-2 rounded-lg">
                                            {formik?.values?.coreTeamCard?.map(
                                                (ele, index) => (
                                                    <Reorder.Item
                                                        value={ele}
                                                        key={ele.reOrderKey}
                                                    >
                                                        <div className="relative">
                                                            <XSquare
                                                                className="absolute top-1 bottom-0 -right-2 lg:right-0 text-red-500"
                                                                onClick={() =>
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    )
                                                                }
                                                            />

                                                            <div className="flex flex-row gap-2 text-slate-200">
                                                                <div className="w-[90%]">
                                                                    <FormikDirectImageUpload
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`coreTeamCard.${index}.url`}
                                                                        location={`1648_entertainment/websitesCreatives/coreTeam/images/`}
                                                                        randomize
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Reorder.Item>
                                                )
                                            )}
                                            <div>
                                                <PrimaryButton
                                                    type="button"
                                                    onClick={() => {
                                                        arrayHelpers.push({
                                                            reOrderKey:
                                                                customId({}),
                                                        });
                                                    }}
                                                >
                                                    Add more
                                                </PrimaryButton>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        </FormikDraggableProvider>
                    </div>
                </div>
                {['Super User', 'HOD', 'Admin Head', 'Developer'].includes(
                    user?.role?.name
                ) &&
                    canShowOtherUpdate && (
                        <div className="text-center mt-4">
                            <DangerButton type="submit">Update</DangerButton>
                        </div>
                    )}
            </form>
        </>
    );
};

export default CoreTeam;
