import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import SidebarLinkGroup from './SidebarLinkGroup';
import { useSelector } from 'react-redux';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';
import { authAxiosInstance } from '../../utils/axiosConfig';

const SidebarGroup = ({
    sidebarExpanded,
    setSidebarExpanded,
    links,
    heading,
    Icon,
    handleLinkClick,
    sidebarOpen
}) => {
    const location = useLocation();
    console.log(location);
    const [canShowGroupFeature, setCanShowGroupFeature] = useState(true);
    const { features } = useSelector(getFeatures);
    const { user } = useSelector(getAuth);
    console.log('user', user)
    const { pathname } = location;
    const isActive = links.reduce((acc, link) => {
        if (pathname.includes(link.link)) {
            acc = true;
        }
        return acc;
    }, false);

    const computeActiveGroupFeature = async () => {
        let canShowGroup = false;
        // const allFeatures = await authAxiosInstance.get('/features');
        const allowedFeatures = links.map((ele) => ele.allowedFeatures);
        const featureData = allowedFeatures.flatMap((innerArray) => innerArray);

        if (!user?.isSuperUser) {
            if (features?.docs?.length) {
                if (featureData?.length === 0) {
                    canShowGroup = true;
                }
                featureData.forEach((item) => {
                    const allowedFeature = features?.docs?.find(
                        (ele) => ele?._id === item
                    );
                    canShowGroup =
                        canShowGroup || allowedFeature?.status == 'active';
                });
                const roleCondition = links
                    .reduce((acc, ele) => {
                        acc = [...acc, ...ele.allowedRoles];
                        return acc;
                    }, [])
                    .includes(user?.role?.name);
                canShowGroup = roleCondition && canShowGroup;
                setCanShowGroupFeature(canShowGroup);
            }else{
                console.log('setCanShowGroupFeature = else condition true they',)
                setCanShowGroupFeature(false)
            }
        } else {
            if (
                links
                    .reduce((acc, ele) => {
                        acc = [...acc, ...ele.allowedRoles];
                        return acc;
                    }, [])
                    .includes(user?.role?.name) ||
                user?.isSuperUser
            ) {
                canShowGroup = true;
                setCanShowGroupFeature(canShowGroup);
            }
        }
    };

    const canShowLink = (allowedRoles, allowedFeatures, link) => {
        let canShow = false;
        if (!user?.isSuperUser) {
            if (features?.docs?.length) {
                let roleCondition = allowedRoles?.includes(user?.role?.name);
                console.log('role condition', roleCondition)
                if(allowedFeatures?.length){
                allowedFeatures.forEach((ele) => {
                    const feature = features.docs.find(
                        (feature) => feature._id === ele
                    );
                    canShow = feature && feature.status === 'active';
                    console.log('allow ftr can show', canShow)
                });
                canShow = roleCondition && canShow;
              
                return canShow;
                }
                else{
                 
                    canShow = roleCondition && (canShow=true)
                    return canShow;
                }
            }
        } else {
            if (allowedRoles?.includes(user?.role?.name) || user?.isSuperUser) {
                canShow = true;
                return canShow;
            }
        }
    };
    useEffect(() => {
    
        computeActiveGroupFeature();
    }, [features, location.pathname]);

    return (
        <>
            {canShowGroupFeature && (
                <SidebarLinkGroup activecondition={isActive}>
                    {(handleClick, open) => {
                        console.log(
                            heading,
                            links.reduce((acc, ele) => {
                                acc = [...acc, ...ele.allowedRoles];
                                return acc;
                            }, [])
                        );
                        return (
                            <React.Fragment>
                                {(links
                                    .reduce((acc, ele) => {
                                        acc = [...acc, ...ele.allowedRoles];
                                        return acc;
                                    }, [])
                                    .includes(user?.role?.name) ||
                                    user?.isSuperUser) && (
                                    <>
                                        <a
                                            href="#0"
                                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                                isActive &&
                                                'focus:text-slate-200'
                                            }`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                sidebarExpanded
                                                    ? handleClick()
                                                    : setSidebarExpanded(true);
                                            }}
                                        >
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center">
                                                    {Icon}

                                                    <span className={`text-sm font-medium ml-3 lg:block xl:block 2xl:block ${ sidebarOpen ? 'block' : 'hidden'}`}>
                                                        {heading}
                                                    </span>
                                                </div>
                                                {/* Open Icon */}
                                                <div className="flex shrink-0 ml-2">
                                                    <svg
                                                        className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                                            open &&
                                                            'transform rotate-180'
                                                        }`}
                                                        viewBox="0 0 12 12"
                                                    >
                                                        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="lg:block lg:sidebar-expanded:block xl:block 2xl:block">
                                            <ul
                                                className={`pl-9 mt-1 ${!open && 'hidden'}`}
                                            >
                                                {links.map((link, i) => (
                                                    <React.Fragment key={i}>
                                                        {canShowLink(
                                                            link.allowedRoles,
                                                            link.allowedFeatures,
                                                            link.link
                                                        ) && (
                                                            <li>
                                                                <NavLink
                                                                    end
                                                                    to={
                                                                        link.link
                                                                    }
                                                                    className={({
                                                                        isActive,
                                                                    }) =>
                                                                        'block text-slate-200 hover:text-slate-200 transition duration-0 truncate ' +
                                                                        (isActive
                                                                            ? '!text-red-500'
                                                                            : '')
                                                                    }
                                                                    onClick={handleLinkClick}
                                                                >
                                                                    <span className="text-sm font-medium lg:block xl:block 2xl:block">
                                                                        {
                                                                            link.label
                                                                        }
                                                                    </span>
                                                                </NavLink>
                                                            </li>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </React.Fragment>
                        );
                    }}
                </SidebarLinkGroup>
            )}
        </>
    );
};

export default SidebarGroup;
