import React, { useEffect, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { Link, useParams } from 'react-router-dom';
import { ChevronRight, XSquare } from 'react-feather';
import SetButtons from '../../components/infrastructure/SetButtons';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { object, string } from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWebsiteCreativeData,
    fetchWebsiteCreativesData,
    getWebsiteCreatives,
} from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import FormikLabel from '../../components/formik/FormikLabel';
import ShowDropdownForSchedule from '../../components/infrastructure/ShowDropdownForSchedule';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import FormikDraggableProvider from '../../components/formik/FormikDraggableProvider';
import { Reorder } from 'framer-motion';
import customId from 'custom-id';
import moment from 'moment';
import FormikEditorInput from '../../components/formik/FormikEditorInput';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const Home = ({ canShowOtherUpdate, pages }) => {
    const { user } = useSelector(getAuth);
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    const elementEditData = websiteCreatives[0];
    const dispatch = useDispatch();
    const computeInitialValue = (data) => {
        if (data) {
            return data;
        } else {
            return {
                // name: pages,
                videoLink: '',
                bannerImage: '',
                logo: '',
                tagline: '',
                eventDate: '',
                festStats: [
                    {
                        textValue: '',
                    },
                    {
                        numericValue: '',
                    },
                ],
                segment1Title: '',
                segment1Content: [
                    {
                        paragraph: '',
                    },
                ],
                segment1Images: [
                    {
                        url: '',
                    },
                ],
                segment2Title: '',
                segment2Content: [
                    {
                        paragraph: '',
                    },
                ],
                segment2Images: [
                    {
                        url: '',
                    },
                ],
                mapUrl: '',
            };
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(elementEditData),
        validationSchema: object({
            name: string().required(),
        }),
        onSubmit: (values) => {
            console.log('creatives values', values);
            const newDate = new Date(values?.eventDate);
            const newEventDate = newDate.toISOString();
            console.log('date', newEventDate);
            const obj = {
                ...values,
                name: pages,
                eventDate: newEventDate,
            };
            console.log('object', obj);
            dispatch(createWebsiteCreativeData(obj));
        },
    });
    useEffect(() => {
        dispatch(fetchWebsiteCreativesData({ name: 'Home' }));
    }, [pages]);
    return (
        <>
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%] mb-3"
            >
                <div className="flex gap-2">
                    <div className="w-1/3 flex flex-col gap-5 items-start justify-center">
                        <FormikLabel label="Video" />
                        <FormikLabel label="Banner" />
                        <FormikLabel label="Logo png" />
                        <FormikLabel label="Tagline" />
                        <FormikLabel label="Map" />
                        <FormikLabel label="Date" />
                    </div>
                    <div className="w-2/3 flex flex-col gap-2 items-start justify-center">
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                name="videoLink"
                            />
                        </div>
                        <div className="w-full">
                            <FormikDirectImageUpload
                                formik={formik}
                                name="bannerImage"
                                location={`1648_entertainment/websitesCreatives/Home/bannerImage/`}
                                randomize
                            />
                        </div>
                        <div className="w-full">
                            <FormikDirectImageUpload
                                formik={formik}
                                name="logo"
                                location={`1648_entertainment/websitesCreatives/logo/`}
                                randomize
                            />
                        </div>
                        <div className="w-full">
                            <FormikInputGroup name="tagline" formik={formik} />
                        </div>
                        <div className="w-full">
                            <FormikInputGroup name="mapUrl" formik={formik} />
                        </div>
                        <div className="w-full">
                            <FormikInputDateGroup
                                formik={formik}
                                name="eventDate"
                            />
                        </div>
                    </div>
                </div>
                {['Super User', 'HOD', 'Admin Head', 'Developer'].includes(
                    user?.role?.name
                ) &&
                    canShowOtherUpdate && (
                        <div className="text-center mt-2">
                            <DangerButton type="submit">Update</DangerButton>
                        </div>
                    )}
            </form>
            {
                <div className="mb-3">
                    <ShowDropdownForSchedule
                        heading={
                            <div className="mb-2">
                                <h1 className="text-slate-200 text-xl font-medium px-2">
                                    Fest Stats
                                </h1>
                            </div>
                        }
                        allowedRoles={[
                            'Super User',
                            'HOD',
                            'Admin Head',
                            'Developer',
                        ]}
                    >
                        <form
                            onSubmit={formik.handleSubmit}
                            className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%]"
                        >
                            <div className="w-full flex flex-row gap-2  items-center justify-between">
                                <div className="w-[20%]">
                                    <FormikLabel label="Stat 1" />
                                </div>
                                <div className="w-[40%]">
                                    <FormikInputGroup
                                        name={`festStats[0].textValue`}
                                        formik={formik}
                                    />
                                </div>
                                <div className="w-[40%]">
                                    <FormikInputGroup
                                        name={`festStats[0].numericValue`}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className="w-full flex flex-row gap-2  items-center justify-between">
                                <div className="w-[20%]">
                                    <FormikLabel label="Stat 2" />
                                </div>
                                <div className="w-[40%]">
                                    <FormikInputGroup
                                        name={`festStats[1].textValue`}
                                        formik={formik}
                                    />
                                </div>
                                <div className="w-[40%]">
                                    <FormikInputGroup
                                        name={`festStats[1].numericValue`}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className="w-full flex flex-row gap-2  items-center justify-between">
                                <div className="w-[20%]">
                                    <FormikLabel label="Stat 3" />
                                </div>
                                <div className="w-[40%]">
                                    <FormikInputGroup
                                        name={`festStats[2].textValue`}
                                        formik={formik}
                                    />
                                </div>
                                <div className="w-[40%]">
                                    <FormikInputGroup
                                        name={`festStats[2].numericValue`}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className="w-full flex flex-row gap-2  items-center justify-between">
                                <div className="w-[20%]">
                                    <FormikLabel label="Stat 4" />
                                </div>
                                <div className="w-[40%]">
                                    <FormikInputGroup
                                        name={`festStats[3].textValue`}
                                        formik={formik}
                                    />
                                </div>
                                <div className="w-[40%]">
                                    <FormikInputGroup
                                        name={`festStats[3].numericValue`}
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            {[
                                'Super User',
                                'HOD',
                                'Admin Head',
                                'Developer',
                            ].includes(user?.role?.name) &&
                                canShowOtherUpdate && (
                                    <div className="text-center mt-2">
                                        <DangerButton type="submit">
                                            Update
                                        </DangerButton>
                                    </div>
                                )}
                        </form>
                    </ShowDropdownForSchedule>
                </div>
            }
            {
                <div className="mb-2">
                    <ShowDropdownForSchedule
                        heading={
                            <div className="mb-3">
                                <h1 className="text-slate-200 text-xl font-medium px-2">
                                    Segment 1
                                </h1>
                            </div>
                        }
                        allowedRoles={[
                            'Super User',
                            'HOD',
                            'Admin Head',
                            'Developer',
                        ]}
                    >
                        <form
                            onSubmit={formik.handleSubmit}
                            className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%]"
                        >
                            <div className="w-full flex flex-row  items-center justify-between">
                                <div className="w-1/3">
                                    <FormikLabel label="Title" />
                                </div>
                                <div className="w-2/3">
                                    <FormikInputGroup
                                        name="segment1Title"
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className="w-full flex flex-row  items-start justify-between">
                                <div className="w-1/3">
                                    <FormikLabel label="Content" />
                                </div>
                                <div className="w-2/3">
                                    <FormikDraggableProvider
                                        formik={formik}
                                        name={'segment1Content'}
                                    >
                                        <FieldArray
                                            name="segment1Content"
                                            render={(arrayHelpers) => {
                                                return (
                                                    <div className="flex flex-col my-2 gap-2 rounded-lg">
                                                        {formik?.values?.segment1Content?.map(
                                                            (ele, index) => (
                                                                <Reorder.Item
                                                                    value={ele}
                                                                    key={
                                                                        ele.reOrderKey
                                                                    }
                                                                >
                                                                    <div className="relative">
                                                                        <XSquare
                                                                            className="absolute top-1 bottom-0 right-0 text-red-500"
                                                                            onClick={() =>
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        />

                                                                        <div className="flex flex-row gap-2 text-slate-200">
                                                                            <div className="w-[90%]">
                                                                                <FormikTextareaGroup
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    name={`segment1Content.${index}.paragraph`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Reorder.Item>
                                                            )
                                                        )}
                                                        <div>
                                                            <PrimaryButton
                                                                type="button"
                                                                onClick={() => {
                                                                    arrayHelpers.push(
                                                                        {
                                                                            reOrderKey:
                                                                                customId(
                                                                                    {}
                                                                                ),
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                Add more
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </FormikDraggableProvider>
                                </div>
                            </div>
                            <div className="w-full flex flex-row  items-start justify-between">
                                <div className="w-1/3">
                                    <FormikLabel label="Image" />
                                </div>
                                <div className="w-2/3">
                                    <FormikDraggableProvider
                                        formik={formik}
                                        name={'segment1Images'}
                                    >
                                        <FieldArray
                                            name="segment1Images"
                                            render={(arrayHelpers) => {
                                                return (
                                                    <div className="flex flex-col my-2 gap-2 rounded-lg">
                                                        {formik?.values?.segment1Images?.map(
                                                            (ele, index) => (
                                                                <Reorder.Item
                                                                    value={ele}
                                                                    key={
                                                                        ele.reOrderKey
                                                                    }
                                                                >
                                                                    <div
                                                                        className="relative"
                                                                        key={
                                                                            ele?._id
                                                                        }
                                                                    >
                                                                        <XSquare
                                                                            className="absolute top-1 bottom-0 right-0 text-red-500"
                                                                            onClick={() =>
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                        <div className="flex flex-row gap-2 text-slate-200">
                                                                            <div className="w-[90%]">
                                                                                <FormikDirectImageUpload
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    name={`segment1Images.${index}.url`}
                                                                                    location={`1648_entertainment/websitesCreatives/segment1/images/`}
                                                                                    randomize
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Reorder.Item>
                                                            )
                                                        )}
                                                        <div>
                                                            <PrimaryButton
                                                                type="button"
                                                                onClick={() => {
                                                                    arrayHelpers.push(
                                                                        {
                                                                            reOrderKey:
                                                                                customId(
                                                                                    {}
                                                                                ),
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                Add more
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </FormikDraggableProvider>
                                </div>
                            </div>
                            {[
                                'Super User',
                                'HOD',
                                'Admin Head',
                                'Developer',
                            ].includes(user?.role?.name) &&
                                canShowOtherUpdate && (
                                    <div className="text-center mt-2">
                                        <DangerButton type="submit">
                                            Update
                                        </DangerButton>
                                    </div>
                                )}
                        </form>
                    </ShowDropdownForSchedule>
                </div>
            }
            {
                <div className="mb-3">
                    <ShowDropdownForSchedule
                        heading={
                            <div className="mb-2">
                                <h1 className="text-slate-200 text-xl font-medium px-2">
                                    Segment 2
                                </h1>
                            </div>
                        }
                        allowedRoles={[
                            'Super User',
                            'HOD',
                            'Admin Head',
                            'Developer',
                        ]}
                    >
                        <form
                            onSubmit={formik.handleSubmit}
                            className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-full md:w-[50%]"
                        >
                            <div className="w-full flex flex-row  items-center justify-between">
                                <div className="w-1/3">
                                    <FormikLabel label="Title" />
                                </div>
                                <div className="w-2/3">
                                    <FormikInputGroup
                                        name="segment2Title"
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className="w-full flex flex-row  items-start justify-between">
                                <div className="w-1/3">
                                    <FormikLabel label="Content" />
                                </div>
                                <div className="w-2/3">
                                    <FormikDraggableProvider
                                        formik={formik}
                                        name={'segment2Content'}
                                    >
                                        <FieldArray
                                            name="segment2Content"
                                            render={(arrayHelpers) => {
                                                return (
                                                    <div className="flex flex-col my-2 gap-2 rounded-lg">
                                                        {formik?.values?.segment2Content?.map(
                                                            (ele, index) => (
                                                                <Reorder.Item
                                                                    value={ele}
                                                                    key={
                                                                        ele.reOrderKey
                                                                    }
                                                                >
                                                                    <div className="relative">
                                                                        <XSquare
                                                                            className="absolute top-1 bottom-0 right-0 text-red-500"
                                                                            onClick={() =>
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        />

                                                                        <div className="flex flex-row gap-2 text-slate-200">
                                                                            <div className="w-[90%]">
                                                                                <FormikTextareaGroup
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    name={`segment2Content.${index}.paragraph`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Reorder.Item>
                                                            )
                                                        )}
                                                        <div>
                                                            <PrimaryButton
                                                                type="button"
                                                                onClick={() => {
                                                                    arrayHelpers.push(
                                                                        {
                                                                            reOrderKey:
                                                                                customId(
                                                                                    {}
                                                                                ),
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                Add more
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </FormikDraggableProvider>
                                </div>
                            </div>
                            <div className="w-full flex flex-row  items-start justify-between">
                                <div className="w-1/3">
                                    <FormikLabel label="Image" />
                                </div>
                                <div className="w-2/3">
                                    <FormikDraggableProvider
                                        formik={formik}
                                        name={'segment2Images'}
                                    >
                                        <FieldArray
                                            name="segment2Images"
                                            render={(arrayHelpers) => {
                                                return (
                                                    <div className="flex flex-col my-2 gap-2 rounded-lg">
                                                        {formik?.values?.segment2Images?.map(
                                                            (ele, index) => (
                                                                <Reorder.Item
                                                                    value={ele}
                                                                    key={
                                                                        ele.reOrderKey
                                                                    }
                                                                >
                                                                    <div className="relative">
                                                                        <XSquare
                                                                            className="absolute top-1 bottom-0 right-0 text-red-500"
                                                                            onClick={() =>
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        />

                                                                        <div className="flex flex-row gap-2 text-slate-200">
                                                                            <div className="w-[90%]">
                                                                                <FormikDirectImageUpload
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    name={`segment2Images.${index}.url`}
                                                                                    location={`1648_entertainment/websitesCreatives/segment2/images/`}
                                                                                    randomize
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Reorder.Item>
                                                            )
                                                        )}
                                                        <div>
                                                            <PrimaryButton
                                                                type="button"
                                                                onClick={() => {
                                                                    arrayHelpers.push(
                                                                        {
                                                                            reOrderKey:
                                                                                customId(
                                                                                    {}
                                                                                ),
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                Add more
                                                            </PrimaryButton>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </FormikDraggableProvider>
                                </div>
                            </div>
                            {[
                                'Super User',
                                'HOD',
                                'Admin Head',
                                'Developer',
                            ].includes(user?.role?.name) &&
                                canShowOtherUpdate && (
                                    <div className="text-center mt-2">
                                        <DangerButton type="submit">
                                            Update
                                        </DangerButton>
                                    </div>
                                )}
                        </form>
                    </ShowDropdownForSchedule>
                </div>
            }
        </>
    );
};

export default Home;
