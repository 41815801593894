import { FieldArray, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import {
    createObject,
    fetchAssets,
    getMediaLibrary,
    openAssetsModal,
} from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikPhotoInput from '../../components/formik/FormikPhotoInput';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import * as Yup from 'yup';

const MediaLibraryAddImage = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { loading } = useSelector(getMediaLibrary);
    const folder = location.pathname.replace('/mediaLibrary', '')
        ? `${location.pathname.replace('/mediaLibrary/', '')}/`
        : '/';
    const decodedFolder = window.decodeURI(folder);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            location: decodedFolder,
            fileName: '',
            photos: [],
        },
        validationSchema: Yup.object({
            fileName: Yup.string().matches(/^[\w," "]+$/, 'invalid name'),
        }),
        onSubmit: (values) => {
            dispatch(createObject(values)).then((res) => {
                dispatch(fetchAssets({ currentFolder: values.location }));
                dispatch(openAssetsModal(false));
            });
        },
    });
    return (
        <>
            {loading ? (
                <ClipLoader color="#FFFFFF" size={18} />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <FormikInputGroup
                        name="location"
                        formik={formik}
                        label="Location"
                        readOnly
                    />
                    <FormikInputGroup
                        name="fileName"
                        formik={formik}
                        label="File Name"
                    />
                    <FormikProvider value={formik}>
                        <FieldArray
                            name="photos"
                            render={(arrayHelpers) => (
                                <>
                                    <div className="mb-4 flex gap-4 items-center">
                                        <h1 className="textlg">
                                            Photos(max 15 at a time)
                                        </h1>
                                        <PrimaryButton
                                            type="button"
                                            onClick={() => {
                                                if (
                                                    formik.values.photos
                                                        .length < 15
                                                ) {
                                                    arrayHelpers.push(null);
                                                } else {
                                                    alert(
                                                        'max 15 photos at a time'
                                                    );
                                                }
                                            }}
                                        >
                                            Add
                                        </PrimaryButton>
                                    </div>
                                    <div className="grid grid-cols-4 gap-4">
                                        {formik.values.photos.map(
                                            (photo, index) => (
                                                <div
                                                    className="flex flex-col gap-2"
                                                    key={index}
                                                >
                                                    <X
                                                        onClick={() => {
                                                            arrayHelpers.remove(
                                                                index
                                                            );
                                                        }}
                                                    />
                                                    <FormikPhotoInput
                                                        name={`photos.${index}`}
                                                        formik={formik}
                                                        value={photo}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </>
                            )}
                        />
                    </FormikProvider>
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </>
    );
};

export default MediaLibraryAddImage;
