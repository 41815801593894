import { useState } from 'react';
import { useRef } from 'react';
import { bytesToMegaBytes } from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    createObject,
    deleteSingleObject,
    getMediaLibrary,
} from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { X } from 'react-feather';
import customId from 'custom-id';
import { useEffect } from 'react';
import { openConfirmPopUp } from '../../app/reducers/ConfirmPopUp/condirmPopUpSlice';

const FormikDirectImageUpload = ({
    name,
    formik,
    label = '',
    required,
    location,
    randomize = false,
    height,
    width,
    ...props
}) => {
    const ref = useRef();
    const [loading, setLoading] = useState(false);
    const [finalString, setFinalString] = useState('');
    const [mode, setMode] = useState('file');
    const dispatch = useDispatch();
    const { baseUrl } = useSelector(getMediaLibrary);

    useEffect(() => {
        if (_.at(formik.values, name)?.[0]) {
            setFinalString(_.at(formik.values, name)[0]);
        }
        else{
            setFinalString('')
        }
    }, [_.at(formik.values, name)?.[0]]);

    return (
        <div>
            <label className="block text-sm font-medium mb-1 text-slate-200" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>
            {finalString ? (
                <div className="flex">
                    {mode == 'file' ? (
                        <img
                            src={`${process.env.REACT_APP_S3_URL}${baseUrl}${finalString}`}
                            style={{ width: '100px', border : '1px solid #475569' , borderRadius : '8px'}}
                        />
                    ) : (
                        <div>{finalString}</div>
                    )}
                    <X
                        color="red"
                        onClick={async () => {
                            await dispatch(
                                deleteSingleObject({
                                    keys: [finalString],
                                })
                            );

                            setFinalString('');
                            formik.setFieldValue(name, '');
                        }}
                    />
                </div>
            ) : (
                <>
                    {loading ? (
                        <ClipLoader color="#FFFFFF" size={18} />
                    ) : (
                        <input
                            ref={ref}
                            type="file"
                            accept="image/*"
                            onChange={async (e) => {
                                console.log(
                                    bytesToMegaBytes(
                                        e.currentTarget.files[0]
                                            .size
                                    )
                                );
                                if (
                                    bytesToMegaBytes(
                                        e.currentTarget.files[0]
                                            .size
                                    ) < 30
                                ) {
                                    setLoading(true);
                                    try {
                                        const resp = await dispatch(
                                            createObject({
                                                location: location,
                                                file: e
                                                    .currentTarget
                                                    .files[0],
                                                fileName: randomize
                                                    ? `${e.currentTarget.files[0].name}${customId({})}`
                                                    : e
                                                        .currentTarget
                                                        .files[0]
                                                        .name,
                                            })
                                        );
                                        if (
                                            resp?.payload?.data?.[0]
                                                ?.Location
                                        ) {
                                            setFinalString(
                                                resp.payload.data[0]
                                                    .Key
                                            );
                                            formik.setFieldValue(
                                                name,
                                                resp.payload.data[0]
                                                    .Key
                                            );
                                        } else {
                                            throw new Error(
                                                'Payload error'
                                            );
                                        }
                                    } catch (error) {
                                        if (error.message) {
                                            toast.error(
                                                error.message
                                            );
                                        } else {
                                            toast.error(
                                                'error uploading file'
                                            );
                                        }
                                    } finally {
                                        setLoading(false);
                                    }
                                } else {
                                    formik.setFieldValue(
                                        name,
                                        null
                                    );
                                    ref.current.value = '';
                                    alert('file size too large');
                                }
                            }}
                            {...props}
                            className="w-full form-input text-slate-200 border-0"
                            style={{
                                backgroundColor : '#141414'
                            }}
                            onBlur={formik.handleBlur}
                        />
                    )}

                </>
            )}

            {formik.errors[name] && formik.touched[name] ? (
                <p className="text-xs text-red-500">{formik.errors[name]}</p>
            ) : null}
        </div>
    );
};

export default FormikDirectImageUpload;