import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Edit2, Trash } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import {
    deleteCommittee,
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { useNavigate } from 'react-router-dom';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PageCardForEvents from '../../components/infrastructure/PageCardForEvents';
import EventCard from '../../components/infrastructure/EventCard';
import EventCustomButton from '../../components/infrastructure/Buttons/EventCustomButton';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const NonEvent = ({actionFeatures}) => {
    const { user } = useSelector(getAuth)
    const { committee, loading } = useSelector(getCommittees);
    const { features} = useSelector(getFeatures)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [canShowUpdate,setCanShowUpdate] = useState(true)
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            console.log('searching');
            dispatch(fetchCommittees({ search: search, populate: true }));
        }, 300),
        []
    );
    const [page, setPage] = useState(1);
    const actionsBtn = ['Update'];
    const createFeatureActionsConfig = (actionFeatures, actions)=>{
        const config = {};
        actionFeatures?.forEach((ids, index)=>{
            config[ids] = actions[index]
        });
        return config
    }
    
    const computeActiveFeature = () => {
        const buttonStateSetter = {
            'Update' : setCanShowUpdate
        }
        const actionButtonMap = createFeatureActionsConfig(actionFeatures, actionsBtn);
        console.log(' obj action map', actionButtonMap);
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item)=>{
                    if(actionFeatures.includes(item?._id)){
                        const action = actionButtonMap[item?._id];
                        const status = item?.status;
                        if(buttonStateSetter[action]){
                            buttonStateSetter[action](status === 'active')
                        }
                    }
                })
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn)=> btn(canShow))
        }
    };

    // const cols = [
    //     {
    //         Header: 'Committee',
    //         Cell: ({ row }) => {
    //             return (
    //                 <span
    //                     className="underline hover:text-blue-600 underline-offset-4 cursor-pointer"
    //                     onClick={async (e) => {
    //                         e.stopPropagation();
    //                         navigate(`/approveVolunteer/${row?.original?._id}`);
    //                     }}
    //                 >
    //                     {row.original.name}
    //                 </span>
    //             );
    //         },
    //     },

    //     {
    //         Header: 'Type',
    //         accessor: 'type',
    //     },
    //     {
    //         Header: 'No. of Events',
    //         accessor: 'numberOfEvents',
    //         Cell: ({ row }) => {
    //             return (
    //                 <span>
    //                     {row.original.numberOfEvents
    //                         ? row.original.numberOfEvents
    //                         : 'N.A'}
    //                 </span>
    //             );
    //         },
    //     },
    //     {
    //         Header: 'Members',
    //         accessor: 'members',
    //         Cell: ({ row }) => {
    //             const members = row.original.members || [];
    //             return (
    //                 <ul>
    //                     {members.map((member, index) => (
    //                         <li key={index}>
    //                             {member.role && <span>{member.role.name}</span>}
    //                             &nbsp; - &nbsp; {member.seats}
    //                         </li>
    //                     ))}
    //                 </ul>
    //             );
    //         },
    //     },

    //     {
    //         Header: 'Action',
    //         Cell: ({ row }) => {
    //             return (
    //                 <div className="flex gap-2 items-center cursor-pointer">
    //                     <Edit2
    //                         onClick={async () => {
    //                             navigate(`/committee/edit/${row.original._id}`);
    //                         }}
    //                         color="blue"
    //                     />
    //                     <Trash
    //                         onClick={async () => {
    //                             await dispatch(
    //                                 deleteCommittee({ id: row.original._id })
    //                             );
    //                             dispatch(fetchCommittees({ populate: true }));
    //                         }}
    //                         color="red"
    //                     />
    //                 </div>
    //             );
    //         },
    //     },
    // ];
    const data = useMemo(
        () => (committee?.docs ? committee.docs : []),
        [committee]
    );
    useEffect(() => {
        dispatch(
            fetchCommittees({
                type: 'Non Event',
                populate: true,
                sort : { name : 1 },
            })
        );
        computeActiveFeature();
    }, [page, features]);
    return (
        <>
        <PageCardForEvents heading='Non Events'>
            <EventCard>
            {data?.map((ele) => (
                        <div className="p-4 w-full sm:w-1/2 lg:w-1/3" key={ele?._id}>
                            <div className="h-full bg-gray-300 bg-opacity-75 p-10 rounded-md sm:rounded-xl overflow-hidden text-center relative"
                                style={{
                                    backgroundColor : `${ele.hexCode}`
                                }}
                            >
                                <h3 className="w-full mb-1 text-black font-semibold text-3xl">
                                    {ele?.name}
                                </h3>
                                <hr className="border-black border-1 mb-4" />
                                <EventCustomButton
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        navigate(
                                            `/committee/non-event/approveVolunteer/${ele?._id}`
                                        );
                                    }}
                                >
                                    Committee
                                </EventCustomButton>
                            </div>
                        </div>
                    ))}
            </EventCard>
        </PageCardForEvents>
        {/* <PageWithCard heading={'View committees'}>
            <TableWithHeadingAndGlobalSearch
                loading={loading}
                heading={'View committees'}
                data={data}
                searchFunction={debouncedSearch}
                columns={cols}
            />
            <PaginationClassic
                setPage={setPage}
                paginationDetails={committee}
            />
        </PageWithCard> */}
        </>
    );
};

export default NonEvent;
