import React, { useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ImageViewerModal from './ImageViewerModal';
import { getCommittees } from '../../app/reducers/Committee/committeeSlice';

const Preview = ({ requestData, showPreviewModal, setShowPreviewModal }) => {
    console.log('reqData', requestData);
    const { committee} = useSelector(getCommittees);
    console.log('committee', committee)
    const [showImageModal, setShowImageModal] = useState(false);
    
    const preSaveCommittee = requestData?.preferredCommittee?.split(',') || [];
    console.log('pre array', preSaveCommittee)
    const committeeData = committee?.docs?.filter((ele) =>
        preSaveCommittee.includes(ele?._id)
    );
    console.log('data', committeeData)
    return (
        <>
            <ModalBasic
                modalOpen={showPreviewModal}
                setModalOpen={setShowPreviewModal}
            >
                <div className="w-full p-4">
                    <h1 className="text-red-500 text-lg mb-4">Details :</h1>
                    <div className="flex flex-col md:flex-row gap-4">
                        <div className="w-[20%] ">
                            <div
                                className="w-[100px] h-[100px] overflow-hidden cursor-pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowImageModal(true);
                                }}
                            >
                                <img
                                    className="w-full h-full rounded-full"
                                    src={`${process.env.REACT_APP_S3_URL}${requestData?.collegeIdPhoto}`}
                                    alt={requestData?.firstName}
                                    style={{
                                        objectPosition: 'top center',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w-[80%]">
                            <h2 className="text-red-500">
                                First Name :{' '}
                                <span className="text-slate-200">
                                    {requestData?.firstName}
                                </span>
                            </h2>
                            <h2 className="text-red-500">
                                Last Name :{' '}
                                <span className="text-slate-200">
                                    {requestData?.lastName}
                                </span>
                            </h2>
                            <h2 className="text-red-500">
                                Phone Number :{' '}
                                <span className="text-slate-200">
                                    {requestData?.contactNumber}
                                </span>
                            </h2>
                            <h2 className="text-red-500">
                                Email :{' '}
                                <span className="text-slate-200">
                                    {requestData?.email}
                                </span>
                            </h2>
                            {requestData?.preferredCommittee && (
                                <h2 className="text-red-500">
                                    Preferred Committee1 :{' '}
                                    <span className="text-slate-200">
                                        {committeeData?.map((ele) => {
                                            if (
                                                preSaveCommittee[0] === ele?._id
                                            )
                                                return ele?.name;
                                        })}
                                    </span>
                                </h2>
                            )}
                            {requestData?.preferredCommittee && (
                                <h2 className="text-red-500">
                                    Preferred Committee2 :{' '}
                                    <span className="text-slate-200">
                                        {committeeData?.map((ele) => {
                                            if (
                                                preSaveCommittee[1] === ele?._id
                                            )
                                                return ele?.name;
                                        })}
                                    </span>
                                </h2>
                            )}
                            {requestData?.preferredCommittee && (
                                <h2 className="text-red-500">
                                    Preferred Committee3 :{' '}
                                    <span className="text-slate-200">
                                        {committeeData?.map((ele) => {
                                            if (
                                                preSaveCommittee[2] === ele?._id
                                            )
                                                return ele?.name;
                                        })}
                                    </span>
                                </h2>
                            )}
                            {requestData?.committee?.length && (
                                <h2 className="text-red-500">
                                    Assign Committee :{' '}
                                    <span className="text-slate-200">
                                        {requestData?.committee[0]?.name}
                                    </span>
                                </h2>
                            )}
                            {requestData?.role && (
                                <h2 className="text-red-500">
                                    Assign Role :{' '}
                                    <span className="text-slate-200">
                                        {requestData?.role?.name}
                                    </span>
                                </h2>
                            )}
                            <h2 className="text-red-500">
                                Courses :{' '}
                                <span className="text-slate-200">
                                    {requestData?.courses}
                                </span>
                            </h2>
                            <h2 className="text-red-500">
                                Year :{' '}
                                <span className="text-slate-200">
                                    {requestData?.year}
                                </span>
                            </h2>
                        </div>
                    </div>
                </div>
            </ModalBasic>
            <ModalBasic
                modalOpen={showImageModal}
                setModalOpen={setShowImageModal}
                zIndex={100}
                title={'Photo Preview'}
                max_width="max-w-[1000px]"
            >
                <img
                    className="w-full"
                    src={`${process.env.REACT_APP_S3_URL}${requestData?.collegeIdPhoto}`}
                    alt={requestData?.firstName}
                />
            </ModalBasic>
        </>
    );
};

export default Preview;
