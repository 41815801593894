export const GENDER = [
    { label: 'male', value: 'male' },
    { label: 'female', value: 'female' },
];
export const ROLES = [
    { label: 'Admin', value: 'admin' },
    { label: 'Attendee', value: 'attendee' },
    { label: 'Crew', value: 'crew' },
];
export const VOLUME = [
    { label: 'High', value: 'high' },
    { label: 'Medium', value: 'medium' },
    { label: 'Low', value: 'low' },
];
export const FORM = [
    { label: 'Liquid', value: 'LIQ' },
    { label: 'Powder', value: 'POW' },
    { label: 'SaltFree', value: 'SF' },
    { label: 'Press Cake', value: 'PC' },
    { label: 'Granules', value: 'GR' },
    { label: 'Crystal', value: 'CR' },
];

export const TSHIRT_SIZE = [
    { label: 'S', value: 'S' },
    { label: 'M', value: 'M' },
    { label: 'L', value: 'L' },
    { label: 'XL', value: 'XL' },
    { label: 'Xxl', value: 'Xxl' },
    { label: 'XXxl', value: 'XXxl' },
];
export const FOOD_PREFERENCE = [
    { label: 'Veg', value: 'Veg' },
    { label: 'Non veg', value: 'Non veg' },
    { label: 'Jain', value: 'Jain' },
];
export const MUSIC_PREFERENCE = [
    { label: 'Rock', value: 'Rock' },
    { label: 'Jazz', value: 'Jazz' },
    { label: 'Bollywood party', value: 'Bollywood party' },
    { label: 'Sufi', value: 'Sufi' },
    { label: 'Ghazal', value: 'Ghazal' },
];
export const CREW_MEMBERS = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
];
export const TITLE = [
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Ms.', value: 'Ms.' },
    { label: 'Mrs.', value: 'Mrs.' },
];
export const LEVEL_TYPE = [
    { label: 'College Level', value: 'college' },
    { label: 'Event Level', value: 'event' },
];
export const ROLE_TYPE = [
    { label: 'Internal', value: 'Internal' },
    { label: 'External', value: 'External' },
];
export const COMMITTEE_TYPE = [
    { label: 'Event', value: 'Event' },
    { label: 'Non Event', value: 'Non Event' },
];
export const STATUS_TYPE = [
    { label: 'Pending', value: 'pending' },
    { label: 'Declined', value: 'declined' },
    { label: 'Approved', value: 'approved' },
    { label: 'Shortlisted', value: 'shortlisted' },
];
export const COURSES_TYPE = [
    { label: 'Arts', value: 'Arts' },
    { label: 'Commerce', value: 'Commerce' },
    { label: 'Science', value: 'Science' },
    { label: 'BSC CS', value: 'BSC CS' },
    { label: 'BSC IT', value: 'BSC IT' },
    { label: 'BAMMC', value: 'BAMMC' },
    { label: 'BMS', value: 'BMS' },
    { label: 'BIOTECH', value: 'BIOTECH' },
    { label: 'BAF', value: 'BAF' },
    { label: 'BRM', value: 'BRM' },
];
export const YEAR_TYPE = [
    { label: 'FYJC', value: 'FYJC' },
    { label: 'SYJC', value: 'SYJC' },
    { label: 'FY DEGREE', value: 'FY DEGREE' },
    { label: 'SY DEGREE', value: 'SY DEGREE' },
    { label: 'TY DEGREE', value: 'TY DEGREE' },
];
export const PART_OF_O2_TYPE = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
];
export const FEATURES_TYPE = [
    { label: 'Frontend', value: 'frontend' },
    { label: 'Backend', value: 'backend' },
];
export const PODIUM_TYPE = [
    {label: '1', value: '1'},
    {label: '2', value: '2'},
    {label: '3', value: '3'},
];
export const TYPE_LEVEL = [
    {label :'Regular', value : 'regular'},
    {label :'Flagship', value : 'flagship'},
];
