import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getAuth } from '../app/reducers/Auth/authSlice';
import {
    fetchFeatures,
    getFeatures,
} from '../app/reducers/Features/featuresSlice';
import { useEffect, useMemo, useState } from 'react';

const RoleAccess = ({ roles = [], allowedFeatures }) => {
    const location = useLocation();
    const { user } = useSelector(getAuth);
    const { features } = useSelector(getFeatures);
    const [canShowFeature, setCanShowFeature] = useState(true);
    console.log('features.docs', features?.docs);
    const computeActiveFeature = () => {
        let canShow = true;
        if (!user?.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                allowedFeatures.forEach((item) => {
                    const allowedFeature = features?.docs?.find(
                        (ele) => ele?._id === item
                    );
                    console.log('allowedFeatures', allowedFeature);
                    canShow = canShow && allowedFeature?.status == 'active';
                });
                setCanShowFeature(canShow);
            }
        } else {
            if (
                !roles.length ||
                roles.includes(user?.role?.name) ||
                user?.isSuperUser
            ) {
                setCanShowFeature(canShow);
            }
        }
    };

    useEffect(
        () => (console.log('checkEffect'), computeActiveFeature()),
        [features, location.pathname]
    );

    return (
        <>
            {canShowFeature ? (
                <Outlet />
            ) : (
                <Navigate to="/unauthorized" replace />
            )}
        </>
    );
};

export default RoleAccess;
