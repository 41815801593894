import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useRef, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch } from 'react-redux';

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    convertToPixelCrop,
} from 'react-image-crop';
import { createObject } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import { toast } from 'react-toastify';
import customId from 'custom-id';

//Center Aspect Crop
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

const ImageCropper = ({
    isOpen,
    name,
    setModelOpen,
    location,
    setFinalString,
    randomize,
    formik,
}) => {
    const dispatch = useDispatch();
    const imgRef = useRef();
    const ref = useRef();
    const [image, setImage] = useState();
    const [mode, setMode] = useState('file');
    const [fileName, setFileName] = useState();
    const [crop, setCrop] = useState(null);
    const [completedCrop, setCompletedCrop] = useState({});

    const handleFileChange = (e) => {
        e.stopPropagation();
        const file = e.target.files[0];
        setFileName(file.name);
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    //Image Load On Selection
    function onImageLoad(e) {
        const { width, height } = e.currentTarget;
        const newCrop = centerAspectCrop(width, height, 1);
        setCrop(centerAspectCrop(width, height, 1));
        setCompletedCrop(convertToPixelCrop(newCrop, width, height));
    }

    //CropImage
    const getCroppedImage = async (crop, e) => {
        try {
            //Creating Base64 Image
            e.stopPropagation();
            const image = imgRef.current;
            const canvas = document.createElement('canvas');
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = completedCrop.width;
            canvas.height = completedCrop.height;
            const ctx = canvas.getContext('2d');
            ctx.imageSmoothingQuality = 'high';
            ctx.drawImage(
                image,
                completedCrop.x * scaleX,
                completedCrop.y * scaleY,
                completedCrop.width * scaleX,
                completedCrop.height * scaleY,
                0,
                0,
                completedCrop.width,
                completedCrop.height
            );

            // As Base64 string
            const base64Image = canvas.toDataURL('image/jpeg');
            console.log(base64Image, 'check now');
            //Creating the file from base64
            const file = await fetch(base64Image)
                .then((res) => res.blob())
                .then((blob) => {
                    const file = new File([blob], fileName, {
                        type: 'image/jpeg',
                    });
                    return file;
                });
            if (file) {
                const resp = await dispatch(
                    createObject({
                        location: location,
                        file: file,
                        fileName: randomize
                            ? `${file.name}${customId({})}`
                            : file.name,
                    })
                );
                console.log(resp);
                if (resp?.payload?.data?.[0]?.Location) {
                    setFinalString(resp.payload.data[0].Key);
                    setImage(null);
                    setFileName(null);
                    formik.setFieldValue(name, resp.payload.data[0].Key);
                    setModelOpen(false);
                } else {
                    throw new Error('Payload error');
                }
            } else {
                throw new Error('File Not Found');
            }
        } catch (error) {
            if (error.message) {
                toast.error(error.message);
            } else {
                toast.error('error uploading file');
            }
        } finally {
            // setLoading(false);
        }
    };

    return (
        <ModalBasic
            modalOpen={isOpen}
            setModalOpen={setModelOpen}
            ignoreClick={true}
        >
            <div className="p-3">
                {!image && mode == 'file' && (
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                )}
                {image && (
                    <div className="mt-4">
                        <ReactCrop
                            crop={crop}
                            minHeight={100}
                            aspect={1}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                        >
                            <img
                                src={image}
                                ref={imgRef}
                                alt="Crop me"
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                        <div className="flex gap-5">
                            <PrimaryButton
                                type="button"
                                onClick={(e) => {
                                    getCroppedImage(crop, e);
                                }}
                            >
                                Crop Image
                            </PrimaryButton>
                            <PrimaryButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setImage();
                                }}
                            >
                                Cancel Image
                            </PrimaryButton>
                        </div>
                    </div>
                )}
                {mode == 'link' && !image && (
                    <>
                        <input
                            ref={ref}
                            name={name}
                            className="w-full form-input"
                            type="text"
                            value={_.at(formik.values, name)?.[0]}
                            onChange={(e) => {
                                setImage(e.target.value);
                                // setFinalString(e.target.value);
                                // formik.setFieldValue(name, e.target.value);
                            }}
                            placeholder="Enter Link"
                        />
                    </>
                )}
                {/* <div
                    className="text-cyan-700 underline cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        setImage(null);
                        setFinalString('');
                        // ref.current?.value = '';
                        formik.setFieldValue(name, '');
                        setMode(mode == 'file' ? 'link' : 'file');
                    }}
                >
                    mode
                </div> */}
            </div>
        </ModalBasic>
    );
};

export default ImageCropper;
