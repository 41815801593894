import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    editData,
    deleteData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';

const SLICE_NAME = 'committee';

export const fetchCommittees = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createCommittee = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchCommittee = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editCommittee = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteCommittee = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);

const committeeSlice = createSlice({
    initialState: generateStateSingle(SLICE_NAME),
    name: SLICE_NAME,
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchCommittees, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchCommittee, SLICE_NAME),
        ...createData.generateExtraReducers(createCommittee, SLICE_NAME),
        ...editData.generateExtraReducers(editCommittee, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteCommittee, SLICE_NAME),
    },
});

export const getCommittees = (state) => state.committee;

export default committeeSlice.reducer;
