import React from 'react';
import PhoneInput from 'react-phone-input-2';
import _ from 'lodash';

const FormikINPhoneInput = ({
    formik,
    label = '',
    required = false,
    name,
    fullWidth,
    ...props
}) => {
    const error = _.get(formik.errors, name);
    const touched = _.get(formik.touched, name);
    return (
        <div className={fullWidth ? 'w-full' : ''}>
            <label className="block text-sm font-medium mb-1 text-slate-200" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>
            <div className='relative '>
            <input
                type="text"
                value={_.at(formik.values, name)[0]}
                onChange={formik.handleChange}
                name={name}
                onBlur={formik.handleBlur}
                className="w-full form-input border-0 rounded-md text-slate-100 ps-[40px]"
                style={{
                    backgroundColor: '#141414'
                }}
                {...props}
            />
            <div className='absolute text-slate-200 bg-transparent top-0 left-0 bottom-0 flex items-center'>
                <span className='p-2 text-sm'>+91</span></div>
            </div>
            {touched && error ? (
                <p className="text-xs text-red-500">{error}</p>
            ) : null}
        </div>
    );
};

export default FormikINPhoneInput;
