import React, { useEffect, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { Link, useParams } from 'react-router-dom';
import { ChevronRight } from 'react-feather';
import SetButtons from '../../components/infrastructure/SetButtons';
import { useDispatch, useSelector } from 'react-redux';
import Default from './Default';
import Home from './Home';
import Gallery from './Gallery';
import CoreTeam from './CoreTeam';
import Sponsors from './Sponsors';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';

const WebsiteCreatives = ({ actionFeatures }) => {
    const { user } = useSelector(getAuth);
    const { pages } = useParams();
    const { features } = useSelector(getFeatures);
    const creativesPages = [
        'Default',
        'Home',
        'Gallery',
        'Coreteam',
        'Sponsors',
    ];
    const initialIndex = pages
        ? creativesPages?.findIndex((ele) => ele === pages)
        : 0;
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const [canShowDefaultUpdate, setCanShowDefaultUpdate] = useState(true);
    const [canShowOtherUpdate, setCanShowOtherUpdate] = useState(true);
    const actionsBtn = ['UpdateDefault', 'UpdateOther'];
    const createFeatureActionsConfig = (actionFeatures, actions) => {
        const config = {};
        actionFeatures?.forEach((ids, index) => {
            config[ids] = actions[index];
        });
        return config;
    };

    const computeActiveFeature = () => {
        const buttonStateSetter = {
            UpdateDefault: setCanShowDefaultUpdate,
            UpdateOther: setCanShowOtherUpdate,
        };
        const actionButtonMap = createFeatureActionsConfig(
            actionFeatures,
            actionsBtn
        );
        // console.log(' obj action map', actionButtonMap);
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item) => {
                    if (actionFeatures.includes(item?._id)) {
                        const action = actionButtonMap[item?._id];
                        const status = item?.status;
                        if (buttonStateSetter[action]) {
                            buttonStateSetter[action](status === 'active');
                        }
                    }
                });
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn) => btn(canShow));
        }
    };
    useEffect(() => {
        if (pages) {
            const index = creativesPages?.findIndex((ele) => ele === pages);
            if (index !== -1) {
                setActiveButtonIndex(index);
                setSelectedTabIndex(index);
            }
        }
    }, [pages, creativesPages]);
    useEffect(() => {
        computeActiveFeature();
    }, [features]);

    return (
        <PageWithCard
            heading={
                <>
                    {/* <Link to={'/allRegistration'}>Data Bank</Link> */}
                    <span>Data Bank</span>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />{' '}
                    </span>
                    <span>{'Website Creatives'}</span>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />{' '}
                    </span>
                    {selectedTabIndex === 0 && <span>{'Default'}</span>}
                    {selectedTabIndex === 1 && <span>{'Home'}</span>}
                    {selectedTabIndex === 2 && <span>{'Gallery'}</span>}
                    {selectedTabIndex === 3 && <span>{'Core Team'}</span>}
                    {selectedTabIndex === 4 && <span>{'Sponsors'}</span>}
                </>
            }
        >
            {
                <SetButtons>
                    <div className="flex items-center justify-start divide-x divide-slate-600">
                        {creativesPages?.map((ele, index) => (
                            <Link key={index} to={`/websitesCreatives`}>
                                <button
                                    className={
                                        activeButtonIndex === index
                                            ? `btn  text-red-500 px-2 p-px text-xs sm:text-sm md:text-base min-w-[100px] md:min-w-[0px]`
                                            : `btn text-slate-200 px-2 p-px text-xs sm:text-sm md:text-base min-w-[100px] md:min-w-[0px]`
                                    }
                                    onClick={() => {
                                        setActiveButtonIndex(index);
                                        setSelectedTabIndex(index);
                                    }}
                                >
                                    {ele}
                                </button>
                            </Link>
                        ))}
                    </div>
                </SetButtons>
            }
            {selectedTabIndex === 0 && (
                <Default
                    pages="Default"
                    canShowDefaultUpdate={canShowDefaultUpdate}
                />
            )}
            {selectedTabIndex === 1 && (
                <Home pages="Home" canShowOtherUpdate={canShowOtherUpdate} />
            )}
            {selectedTabIndex === 2 && (
                <Gallery
                    pages="Gallery"
                    canShowOtherUpdate={canShowOtherUpdate}
                />
            )}
            {selectedTabIndex === 3 && (
                <CoreTeam
                    pages="Coreteam"
                    canShowOtherUpdate={canShowOtherUpdate}
                />
            )}
            {selectedTabIndex === 4 && (
                <Sponsors
                    pages="Sponsors"
                    canShowOtherUpdate={canShowOtherUpdate}
                />
            )}
        </PageWithCard>
    );
};

export default WebsiteCreatives;
