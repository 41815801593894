import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchWarOfCls,
    getWarOfCls,
} from '../../app/reducers/WarOfCl/warOfClSlice';
import FormikLabel from '../../components/formik/FormikLabel';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import {
    createWarOfClScore,
    editWarOfClScore,
    fetchWarOfClScore,
    fetchWarOfClScores,
    getWarOfClScores,
    resetWarOfClScore,
} from '../../app/reducers/WarOfClScoring/warOfClScoringSlice';
import { useParams } from 'react-router-dom';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';

const WarOfClScoreboard = ({
    committeeEditData,
    // eventId,
    canShowSubmit,
    user,
    // id,
    activeButtonIndex,
    elementEditData,
}) => {
    const { id, eventId } = useParams();
    const { warOfCl } = useSelector(getWarOfCls);
    // console.log('war', warOfCl)
    const { elementEditData: warOfClScoreEditData } =
        useSelector(getWarOfClScores);
    // console.log('score', warOfClScoreEditData)
    const [isEditable, setIsEditable] = useState(false);
    const [actionType, setActionType] = useState('');
    const dispatch = useDispatch();
    const computeInitialValue = (data) => {
        if (data) {
            return data;
        } else {
            return {
                firstPlaceCl: [''],
            };
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(warOfClScoreEditData),
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            try {
                console.log('war cl values', values);
                if (actionType === 'submit') {
                    
                            let obj = {
                                firstPlaceCl: values?.firstPlaceCl,
                                committee: id,
                                event: eventId,
                            };
                            console.log('create obj', obj);
                            await dispatch(createWarOfClScore(obj));
                            await dispatch(
                                fetchWarOfClScore({
                                    event: eventId,
                                    committee: id,
                                })
                            );
                            setSubmitting(false);
                        
                    
                } else if (actionType === 'update' && isEditable) {
                    console.log('edit', values);
    
                    const stringData = QueryString.stringify({ event: values?.event });
                    const resp = await authAxiosInstance.get(
                        `/warOfClScoring?${stringData}`
                    );
                    console.log('object', resp?.data?.data?.docs);
                    const existingData = resp?.data?.data?.docs;
                    if (existingData?.length === 0) {
                       
                                let obj = {
                                    firstPlaceCl: values?.firstPlaceCl,
                                    committee: id,
                                    event: eventId,
                                };
                                console.log('create obj', obj);
                                await dispatch(createWarOfClScore(obj));
                                await dispatch(
                                    fetchWarOfClScore({
                                        event: eventId,
                                        committee: id,
                                    })
                                );
                                setSubmitting(false);
                            
                          
                    } else {
                        let editObj = {
                            _id: values?._id,
                            ...values,
                        };
                        console.log('edit obj', editObj);
                        await dispatch(editWarOfClScore(editObj));
                        await dispatch(fetchWarOfClScore({event : eventId, committee : id}));
                    }
                }
                resetForm();
                if (actionType !== 'edit') {
                    setActionType('');
                    setIsEditable(false);
                }
            } catch (error) {
                console.log('Error', error);
            } finally {
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        dispatch(fetchWarOfCls({ populate: true }));
    }, []);

    useEffect(() => {
        if (id && eventId) {
            dispatch(fetchWarOfClScore({ event: eventId, committee: id }));
        }
    }, [eventId, activeButtonIndex]);

    const clOptions = useMemo(() => {
        return warOfCl?.docs?.map((ele) => ({
            label: ele?.collegeCode,
            value: ele?._id,
        }));
    }, [warOfCl]);
    const renderClButtons = () => {
        if (warOfClScoreEditData) {
            if (['Developer', 'Super User'].includes(user?.role?.name)) {
                if (isEditable) {
                    return (
                        <div className="flex flex-row items-center gap-4">
                            <PrimaryButton
                                type="submit"
                                disabled={formik.isSubmitting}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setActionType('update');
                                    formik.handleSubmit();
                                }}
                            >
                                Submit Changes
                            </PrimaryButton>
                            <DangerButton
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsEditable(false);
                                }}
                            >
                                Cancel Edit
                            </DangerButton>
                        </div>
                    );
                } else {
                    return (
                        <div className="flex flex-row gap-2">
                            {
                                // scorecardEditData?.status === 'loaded' &&
                                <PrimaryButton
                                    type="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActionType('edit');
                                        setIsEditable(true);
                                    }}
                                >
                                    Edit
                                </PrimaryButton>
                            }
                        </div>
                    );
                }
            }
        } else {
            if (
                committeeEditData?.scorer === user?._id ||
                ['Developer', 'Super User', 'HOD', 'Admin Head'].includes(
                    user?.role?.name
                )
            ) {
                return (
                    canShowSubmit && (
                        <PrimaryButton
                            type="submit"
                            disabled={formik.isSubmitting}
                            onClick={async (e) => {
                                e.stopPropagation();
                                setActionType('submit');
                            }}
                        >
                            Submit
                        </PrimaryButton>
                    )
                );
            }
        }
        return null;
    };
    return (
        elementEditData?.warOfClEvent === 'yes' && (
            <div className="mt-4">
                {warOfClScoreEditData ? (
                    <>
                        <h1 className="mb-2 font-semibold text-xl text-slate-200">
                            War of CLs
                        </h1>
                        <form
                            className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md"
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="flex flex-row items-center gap-4">
                                <div className="w-1/3">
                                    <FormikLabel label="1st" />
                                </div>
                                <div className="w-2/3">
                                    <FormikMultiSelect
                                        name="firstPlaceCl"
                                        formik={formik}
                                        options={clOptions}
                                        isDisabled={
                                            !isEditable ||
                                            ![
                                                'Developer',
                                                'Super User',
                                            ].includes(user?.role?.name)
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                {renderClButtons()}
                                {/* { (committeeEditData?.scorer === user?._id ||
                            ['HOD', 'Super User', 'Developer'].includes(
                                user?.role?.name
                            )) &&
                            // canShowSubmit && 
                            <PrimaryButton type='submit'>
                        Edit
                    </PrimaryButton>} */}
                            </div>
                        </form>
                    </>
                ) : (
                    <>
                        <h1 className="mb-2 font-semibold text-xl text-slate-200">
                            War of CLs
                        </h1>
                        <form
                            className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md"
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="flex flex-row items-center gap-4">
                                <div className="w-1/3">
                                    <FormikLabel label="1st" />
                                </div>
                                <div className="w-2/3">
                                    <FormikMultiSelect
                                        name="firstPlaceCl"
                                        formik={formik}
                                        options={clOptions}
                                    />
                                </div>
                            </div>
                            <div>
                                {renderClButtons()}
                                {/* { (committeeEditData?.scorer === user?._id ||
                            ['HOD', 'Super User', 'Developer'].includes(
                                user?.role?.name
                            )) &&
                            canShowSubmit && <PrimaryButton type='submit'>
                        Submit
                    </PrimaryButton>} */}
                            </div>
                        </form>
                    </>
                )}
            </div>
        )
    );
};

export default WarOfClScoreboard;
