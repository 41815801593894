import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import { createData, deleteData, editData, fetchData, fetchEditData } from "../../generators/generateThunks";
import { resetData } from "../../generators/generateReducers";

const SLICE_NAME = 'warOfClScoring';

export const fetchWarOfClScores = fetchData.generateThunk(SLICE_NAME,SLICE_NAME);
export const fetchWarOfClScore = fetchEditData.generateThunk(SLICE_NAME,SLICE_NAME);
export const createWarOfClScore = createData.generateThunk(SLICE_NAME,SLICE_NAME);
export const deleteWarOfClScore = deleteData.generateThunk(SLICE_NAME,SLICE_NAME);
export const editWarOfClScore = editData.generateThunk(SLICE_NAME,SLICE_NAME);

const warOfClScoringSlice = createSlice({
    name : SLICE_NAME,
    initialState : generateStateSingle(SLICE_NAME),
    reducers:{
        resetWarOfClScore : resetData(generateStateSingle(SLICE_NAME))
    },
    extraReducers : {
        ...fetchData.generateExtraReducers( fetchWarOfClScores , SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchWarOfClScore ,SLICE_NAME),
        ...createData.generateExtraReducers(createWarOfClScore, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteWarOfClScore, SLICE_NAME),
        ...editData.generateExtraReducers(editWarOfClScore, SLICE_NAME)
    }
})

export const getWarOfClScores = (state) => state.warOfClScoring;

export const { resetWarOfClScore } = warOfClScoringSlice.actions;

export default warOfClScoringSlice.reducer;