import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import {
    fetchFeatures,
    getFeatures,
} from '../../app/reducers/Features/featuresSlice';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';

const SidebarSingle = ({
    activePath,
    Icon,
    name,
    allowedRoles,
    sidebarOpen,
    allowedFeatures,
    handleLinkClick,
}) => {
    const { user, jwt } = useSelector(getAuth);
    const [canShowFeature, setCanShowFeature] = useState(true);
    const { features } = useSelector(getFeatures);
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;

    const computeActiveFeature = () => {
        let canShow = true;
        if (!user?.isSuperUser) {
            if (features?.docs?.length) {
                allowedFeatures.forEach((item) => {
                    const allowedFeature = features?.docs?.find(
                        (ele) => ele?._id === item
                    );
                    canShow = canShow && allowedFeature?.status == 'active';
                });
                setCanShowFeature(canShow);
            }
            if (allowedRoles?.includes(user?.role?.name)) {
                setCanShowFeature(canShow);
            }else{
                setCanShowFeature(false)
            }
        } else {
            if (allowedRoles?.includes(user?.role?.name) || user?.isSuperUser) {
                setCanShowFeature(canShow);
            }
        }
    };

    useEffect(() => {
        computeActiveFeature();
    }, [features, location.pathname]);

    const checkPathName = () => {
        if (activePath === '/') {
            return pathname === '/';
        }
        return pathname.startsWith(activePath);
    };
    // const checkPathName = () => {
    //     if (activePath === '/') {
    //         if (pathname === '/') return true;
    //         return false;
    //     }
    //     return pathname.includes(activePath);
    // };
    console.log('sidebar single',sidebarOpen);
    {
        return (
            <>
                {canShowFeature && (
                    <li
                        title={name}
                        className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                            checkPathName() && 'bg-transparent'
                        }`}
                    >
                        <NavLink
                            end
                            to={activePath}
                            className={`block truncate transition duration-0 ${
                                checkPathName() && 'focus:text-red-500' ? 'text-red-500' : 'text-slate-200'
                            }`}
                            onClick={handleLinkClick}
                        >
                            <div className="flex items-center">
                                {Icon}
                                <span
                                    className={`text-sm font-medium ml-3 sidebar-expanded:block 2xl:opacity-100 duration-200 ${
                                        sidebarOpen ? 'block' : 'hidden'
                                    }`}
                                >
                                    {name}
                                </span>
                            </div>
                        </NavLink>
                    </li>
                )}
            </>
        );
    }
};

export default SidebarSingle;
