import React from 'react';

const SecondaryButton = ({
    children,
    className,
    type = 'button',
    ...props
}) => {
    return (
        <button
            className={`btn border-0 text-black bg-emerald-400 px-4 py-1.5 font-medium ${
                className ? className : ''
            }`}
            type={type}
            {...props}
        >
            {children}
        </button>
    );
};

export default SecondaryButton;
