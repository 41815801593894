import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';
import { toast } from 'react-toastify';
import { resetData } from '../../generators/generateReducers';

const SLICE_NAME = 'registrationRequest';

export const createRegistrationRequest = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const fetchRegistrationRequests = fetchData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const fetchRegistrationRequest = fetchEditData.generateThunk( SLICE_NAME, SLICE_NAME ); 
export const editRegistrationRequest = editData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editShortlistRegistrationRequest = editData.generateThunk(
    SLICE_NAME,
    '/registrationRequest/shortlist'
);

export const editRegistrationRequestUserPass = editData.generateThunk( SLICE_NAME, '/registrationRequest/changePassword');

export const deleteRegistrationRequest = deleteData.generateThunk(SLICE_NAME, SLICE_NAME, false)

const registrationRequestSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    reducers : {
        resetRegistrationRequest : resetData(generateStateSingle(SLICE_NAME))
    },
    extraReducers: {
        ...createData.generateExtraReducers(
            createRegistrationRequest,
            SLICE_NAME
        ),
        ...fetchData.generateExtraReducers(
            fetchRegistrationRequests,
            SLICE_NAME
        ),
        ...fetchEditData.generateExtraReducers(fetchRegistrationRequest, SLICE_NAME),
        ...editData.generateExtraReducers(editRegistrationRequest, SLICE_NAME),
        ...editData.generateExtraReducers(
            editShortlistRegistrationRequest,
            SLICE_NAME
        ),
        ...editData.generateExtraReducers(editRegistrationRequestUserPass, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteRegistrationRequest, SLICE_NAME)
    },
});

export const getRegistrationRequests = (state) => state.registrationRequest;

export const { resetRegistrationRequest } = registrationRequestSlice.actions;

export default registrationRequestSlice.reducer;
