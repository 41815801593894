import React from 'react';

const EventCustomButton = ({ children, className, type = 'button', ...props }) => {
    return (
        <button
        className= {`py-2 px-4 w-full rounded-md text-normal bg-black font-normal text-white mb-4 ${className}`}
            type={type}
            {...props}
        >
            {children}
        </button>
    );
};

export default EventCustomButton;
