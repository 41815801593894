import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { checkJwt, getAuth } from '../../app/reducers/Auth/authSlice';

const PersistLogin = () => {
    const dispatch = useDispatch();
    const { loading, jwt } = useSelector(getAuth);
    const location = useLocation();

    useEffect(() => {
        if (!jwt) {
            dispatch(checkJwt());
        }
    }, []);

    return (
        <>
            {loading ? (
                <h1>Loading...</h1>
            ) : !jwt ? (
                <Navigate to="/login" state={{ from: location.pathname }} />
            ) : (
                <Outlet />
            )}
        </>
        // <>
        //   <Outlet />
        // </>
    );
};

export default PersistLogin;
