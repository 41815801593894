import React, { useEffect } from 'react';
import {
    fetchWebsiteCreativesData,
    getWebsiteCreatives,
} from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { link_data } from '../../utils/constants';
import NavbarLeft from '../Navbar/NavbarLeft';

const RegisterContainer = ({ children }) => {
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    console.log('creative data', websiteCreatives);
    const dynamicDefaultData = websiteCreatives[0];
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchWebsiteCreativesData({ name: 'Default' }));
    }, []);
    return (
        <>
            <NavbarLeft props={link_data} />
            <main className="bg-black">
                <div className="relative md:flex pt-[50px] min-h-screen">
                    <div
                        className="hidden  md:w-1/2  md:flex "
                        aria-hidden="true"
                        style={{
                            backgroundColor: '#000000',
                        }}
                    >
                        <div className="w-full h-full">
                            <img
                                className="object-cover w-full h-full"
                                style={{
                                    objectPosition: 'top center',
                                }}
                                src={`${process.env.REACT_APP_S3_URL}${dynamicDefaultData?.banner}`}
                                alt="Authentication"
                            />
                        </div>
                    </div>
                    {children}
                </div>
            </main>
        </>
    );
};

export default RegisterContainer;
