import DatePicker from 'react-datepicker';
import _ from 'lodash';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './datePickerStyles.css'

const FormikInputDateGroup = ({
    formik,
    label = '',
    required = false,
    name,
    dateFormat = 'dd/MM/yyyy',
    ...props
}) => {
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className="w-full flex flex-col "
        >
            <label className="block text-sm font-medium mb-1 text-slate-200" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>
            <DatePicker
                name={name}
                selected={moment(_.at(formik.values, name)[0]).valueOf()}
                onBlur={formik.handleBlur}
                onChange={(selectedDate) => {
                    formik.setFieldValue(
                        name,
                        new Date(selectedDate).getTime()
                    );
                }}
                dateFormat={dateFormat}
                autoComplete="off"
                dropdownMode="select"
                className='text-slate-100 custom-datepicker-input border-0 rounded-md'
                {...props}
            />
            {formik?.errors?.[name] && formik?.touched?.[name] ? (
                <p className="text-xs text-red-500">{formik.errors[name]}</p>
            ) : null}
        </div>
    );
};

export default FormikInputDateGroup;