import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Edit2, Trash } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import {
    deleteCollege,
    fetchColleges,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import { useNavigate } from 'react-router-dom';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import { fetchRoles } from '../../app/reducers/Role/roleSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';

const ViewCollege = () => {
    const { college, loading } = useSelector(getColleges);
    const { baseUrl } = useSelector(getMediaLibrary);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            console.log('searching');
            dispatch(
                fetchColleges({
                    sort: { name: 1 },
                    search: search,
                    populate: true,
                })
            );
        }, 300),
        []
    );
    const [page, setPage] = useState(1);

    const cols = [
        // {
        //     Header: 'College Photo',
        //     Cell: ({ row }) => {
        //         return (
        //             <div>
        //                 {row.original.photos && (
        //                     <img
        //                         src={baseUrl + row.original.photos[0]}
        //                         height={70}
        //                         width={100}
        //                     />
        //                 )}
        //             </div>
        //         );
        //     },
        // },
        {
            Header: 'College Name',
            Cell: ({ row }) => {
                return <span>{row.original.name}</span>;
            },
        },

        // {
        //     Header: 'Code',
        //     accessor: 'code',
        // },
        // {
        //     Header: 'CL/ACL',
        //     accessor: 'volunteers',
        //     Cell: ({ row }) => {
        //         const volunteers = row.original.volunteers || [];
        //         return (
        //             <ul>
        //                 {volunteers.map((volunteer, index) => (
        //                     <li key={index}>
        //                         {volunteer.role && (
        //                             <span>{volunteer.role.name}</span>
        //                         )}
        //                         &nbsp; - &nbsp; {volunteer.seats}
        //                     </li>
        //                 ))}
        //             </ul>
        //         );
        //     },
        // },

        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 items-center cursor-pointer">
                        <PrimaryButton
                            onClick={async () => {
                                navigate(`/college/edit/${row.original._id}`);
                            }}
                            color="blue"
                        >
                            Edit
                        </PrimaryButton>
                        <DangerButton
                            onClick={async () => {
                                await dispatch(
                                    deleteCollege({ id: row.original._id })
                                );
                                dispatch(
                                    fetchColleges({
                                        populate: true,
                                        sort: { name: 1 },
                                        page,
                                    })
                                );
                            }}
                            color="red"
                        >
                            Delete
                        </DangerButton>
                    </div>
                );
            },
        },
    ];
    const data = useMemo(() => (college?.docs ? college.docs : []), [college]);
    useEffect(() => {
        dispatch(
            fetchColleges({
                populate: true,
                sort: { name: 1 },
                page,
            })
        );
    }, [page]);
    return (
        <PageWithCard heading={'Colleges'}>
            <TableWithHeadingAndGlobalSearch
                loading={loading}
                data={data}
                searchFunction={debouncedSearch}
                columns={cols}
            />
            <PaginationClassic setPage={setPage} paginationDetails={college} />
        </PageWithCard>
    );
};

export default ViewCollege;
