import { createAsyncThunk } from '@reduxjs/toolkit';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../utils/axiosConfig';

export const fetchData = {
    generateThunk: (element, url) => {
        const thunk = createAsyncThunk(
            `${element}/fetchData`,
            async (data, { rejectWithValue }) => {
                try {
                    const string = QueryString.stringify(data);
                    const resp = await authAxiosInstance.get(
                        `${url}?${string}`
                    );
                    return resp.data;
                } catch (error) {
                    console.error(error);
                    toast.error('failed to fetch data');
                    return rejectWithValue(JSON.stringify(error));
                }
            }
        );

        return thunk;
    },
    generateExtraReducers: (thunk, element) => {
        return {
            [thunk.pending]: (state, action) => {
                state.loading = true;
            },
            [thunk.fulfilled]: (state, action) => {
                state.loading = false;
                state[element] = action.payload.data;
            },
            [thunk.rejected]: (state, action) => {
                state.loading = false;
            },
        };
    },
};

export const createData = {
    generateThunk: (element, url) => {
        const thunk = createAsyncThunk(
            `${element}/createData`,
            async (data, { rejectWithValue }) => {
                try {
                    const resp = await authAxiosInstance.post(url, data);
                    toast.success(`${element} created`);
                    return resp.data;
                } catch (error) {
                    console.error(error);
                    if(error){
                        toast.error(error?.response?.data?.error?.message)
                    }
                    toast.error('failed to create data');
                    return rejectWithValue(JSON.stringify(error));
                }
            }
        );

        return thunk;
    },
    generateExtraReducers: (thunk, element) => {
        return {
            [thunk.pending]: (state, action) => {
                state.loading = true;
            },
            [thunk.fulfilled]: (state, action) => {
                state.loading = false;
            },
            [thunk.rejected]: (state, action) => {
                state.loading = false;
            },
        };
    },
};

export const deleteData = {
    generateThunk: (element, url, showAlert = true) => {
        const thunk = createAsyncThunk(
            `${element}/deleteData`,
            async (data, { rejectWithValue }) => {
                try {
                    let confirm = true
                    if(showAlert){
                         confirm = window.confirm(
                            'are you sure you want to delete this data'
                        );

                    }
                    if (confirm) {
                        const resp = await authAxiosInstance.delete(
                            `${url}/${data.id}`
                        );
                        toast.success(`${element} deleted`);
                        return resp.data;
                    }
                } catch (error) {
                    console.error(error);
                    toast.error('failed to delete data');
                    rejectWithValue(JSON.stringify(error));
                }
            }
        );
        return thunk;
    },
    generateExtraReducers: (thunk, element) => {
        return {
            [thunk.pending]: (state, action) => {
                state.loading = true;
            },
            [thunk.fulfilled]: (state, action) => {
                state.loading = false;
            },
            [thunk.rejected]: (state, action) => {
                state.loading = false;
            },
        };
    },
};

export const editData = {
    generateThunk: (element, url, noToast = false) => {
        const thunk = createAsyncThunk(
            `${element}/editData`,
            async (data, { rejectWithValue }) => {
                try {
                    const resp = await authAxiosInstance.patch(url, data);
                    if(!noToast){
                        toast.success('item edited');
                    }
                    return resp.data;
                } catch (error) {
                    console.error(error);
                    if(error){
                        toast.error(error?.response?.data?.error?.message)
                    }
                    if(!noToast){
                    toast.error('failed to edit data');
                    }
                    return rejectWithValue(JSON.stringify(error));
                }
            }
        );
        return thunk;
    },
    generateExtraReducers: (thunk, element) => {
        return {
            [thunk.pending]: (state, action) => {
                state.loading = true;
            },
            [thunk.fulfilled]: (state, action) => {
                state.loading = false;
            },
            [thunk.rejected]: (state, action) => {
                state.loading = false;
            },
        };
    },
};

export const fetchEditData = {
    generateThunk: (element, url) => {
        const thunk = createAsyncThunk(
            `${element}/fetchEditData`,
            async (data, { rejectWithValue }) => {
                try {
                    const string = QueryString.stringify(data);
                    const resp = await authAxiosInstance.get(
                        `${url}?${string}`
                    );
                    return resp.data;
                } catch (error) {
                    console.error(error);
                    toast.error('failed to fetch data');
                    return rejectWithValue(JSON.stringify(error));
                }
            }
        );

        return thunk;
    },
    generateExtraReducers: (thunk, element) => {
        return {
            [thunk.pending]: (state, action) => {
                state.editDataLoading = true;
            },
            [thunk.fulfilled]: (state, action) => {
                state.editDataLoading = false;
                state.elementEditData = action.payload.data.docs[0];
            },
            [thunk.rejected]: (state, action) => {
                state.editDataLoading = false;
            },
        };
    },
};

export const createWithFile = {
    generateThunk: (element, url) => {
        const thunk = createAsyncThunk(
            `${element}/createData`,
            async (data, { rejectWithValue }) => {
                try {
                    const resp = await authAxiosInstance.post(url, data, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    toast.success(`${element} created`);
                    return resp.data;
                } catch (error) {
                    console.error(error);
                    toast.error('failed to create data');
                    return rejectWithValue(JSON.stringify(error));
                }
            }
        );

        return thunk;
    },
    generateExtraReducers: (thunk, element) => {
        return {
            [thunk.pending]: (state, action) => {
                state.loading = true;
            },
            [thunk.fulfilled]: (state, action) => {
                state.loading = false;
            },
            [thunk.rejected]: (state, action) => {
                state.loading = false;
            },
        };
    },
};
