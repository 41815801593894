import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents, getEvents } from '../../app/reducers/Event/eventSlice';
import TableWithHeading from '../../components/Table/TableWithHeading';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import DescriptionModal from '../Participant/DescriptionModal';
import DetailsModal from '../Participant/DetailsModal';
import WarOfClDisclaimer from './WarOfClDisclaimer';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';

const WarOfCLRegistration = ({actionFeatures}) => {
    const { user } = useSelector(getAuth);
    const { event } = useSelector(getEvents);
    console.log('event', event?.docs)
    const [requestData, setRequestData] = useState({});
    const { features } = useSelector(getFeatures);
    const [clData, setClData] = useState({});
    const [warClData, setWarClData] = useState([]);
    const [showDescriptionModal, setShowDescriptionModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
    const [canShowRegistration,setCanShowRegistration] = useState(true);

    const computeWarOfCls = async () => {
        try {
            const string = QueryString.stringify({});
            const string1 = QueryString.stringify({ collegeCode : user?.username});

            const resp = await authAxiosInstance.get(`/warOfCl?${string}`);
            const resp1 = await authAxiosInstance.get(`/warOfCl?${string1}`);
            console.log('resp', resp?.data?.data);
            setWarClData(resp?.data?.data?.docs);
            setClData(resp1?.data?.data?.docs[0]);
        } catch (error) {
            console.log(error);
        }
    };
    console.log('cl data', clData);
    console.log('user',user)

    const dispatch = useDispatch();
    const actionsBtn = ['Registration'];
    const createFeatureActionsConfig = (actionFeatures, actions) => {
        const config = {};
        actionFeatures?.forEach((ids, index) => {
            config[ids] = actions[index];
        });
        return config;
    };
   
    const computeActiveFeature = () => {
        const buttonStateSetter = {
            'Registration': setCanShowRegistration,
        };
        const actionButtonMap = createFeatureActionsConfig(
            actionFeatures,
            actionsBtn
        );
        console.log(' obj action map', actionButtonMap);
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item) => {
                    if (actionFeatures.includes(item?._id)) {
                        const action = actionButtonMap[item?._id];
                        const status = item?.status;
                        if (buttonStateSetter[action]) {
                            buttonStateSetter[action](status === 'active');
                        }
                    }
                });
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn) => btn(canShow));
        }
    };

    useEffect(() => {
        dispatch(
            fetchEvents({
                populate: true,
                warOfClEvent:'yes',
                sort: { name: 1 },
            })
        );
        computeWarOfCls();
    }, []);
    useEffect(()=>{
        computeActiveFeature();
    },[features])
    const eventsCols = [
        {
            Header: ' ',
            accessor: 'name',
            cellStyle: {
                width: '40%',
            },
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center gap-2 w-full">
                        <PrimaryButton
                            className={'w-[60%]'}
                            onClick={async (e) => {
                                e.stopPropagation();
                                console.log('row', row?.original);
                                setRequestData(row?.original);
                                setShowDescriptionModal(true);
                            }}
                        >
                            {row?.original?.subTitle
                                ? row?.original?.subTitle
                                : 'Event Subtitle'}
                        </PrimaryButton>
                        <PrimaryButton
                            className={'w-[40%]'}
                            onClick={async (e) => {
                                e.stopPropagation();
                                setRequestData(row?.original);
                                setShowDetailsModal(true);
                            }}
                        >
                            Details
                        </PrimaryButton>
                    </div>
                );
            },
            cellStyle: {
                width: '60%',
            },
        },
    ];

    const eventMemo = useMemo(() => (event?.docs ? event?.docs : []), [event]);

    const noOfClSlots = event?.docs?.[0]?.noOfClSlots || 0;
    const canRegister = clData?.userId !== user?._id && warClData.length < noOfClSlots;

    return (
        <PageWithCard
            heading={
                <>
                    <Link to={'/committee/event'}>Events</Link>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />
                    </span>
                    <span>{'War of CL'}</span>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />
                    </span>
                    <span>{'Registration'}</span>
                </>
            }
        >
            <DescriptionModal
                requestData={requestData}
                showDescriptionModal={showDescriptionModal}
                setShowDescriptionModal={setShowDescriptionModal}
            />

            <DetailsModal
                requestData={requestData}
                showDetailsModal={showDetailsModal}
                setShowDetailsModal={setShowDetailsModal}
            />

            <WarOfClDisclaimer
                requestData={requestData}
                showDisclaimerModal={showDisclaimerModal}
                setShowDisclaimerModal={setShowDisclaimerModal}
                computeWarOfCls={computeWarOfCls}
            />

            <div className="w-full md:w-1/2">
                <h1 className="text-red-500 font-medium text-xl">
                    Event Details :
                </h1>
                <ul>
                    <li className="font-medium text-sm text-slate-200 list-disc list-inside">
                        CL’s need to participate in 1 event per department.
                    </li>
                    <li className="font-medium text-sm text-slate-200 list-disc list-inside">
                        CL’s are playing for the best CL trophy.
                    </li>
                    <li className="font-medium text-sm text-slate-200 list-disc list-inside">
                        Score board for war of CL is different and
                        will not be counted in overall trophy.
                    </li>
                </ul>
                <div className="mt-4">
                    <TableWithHeading columns={eventsCols} data={eventMemo} />
                </div>
                { canRegister && (
                        canShowRegistration &&
                        <div>
                            <DangerButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRequestData(user);
                                    setShowDisclaimerModal(true)
                                }}
                            >
                                Register
                            </DangerButton>
                        </div>
                    )}
            </div>
        </PageWithCard>
    );
};

export default WarOfCLRegistration;
