import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { Link } from 'react-router-dom';
import { ChevronRight, Eye } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWarOfCls, getWarOfCls } from '../../app/reducers/WarOfCl/warOfClSlice';
import TableWithHeading from '../../components/Table/TableWithHeading';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ViewDetails from '../CollegesRequest/ViewDetails';

const WarOfClParticipants = () => {
    const { warOfCl } = useSelector(getWarOfCls);
    const [requestData,setRequestData] = useState({});
    const [showClDetailModal,setShowClDetailModal] = useState(false);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(fetchWarOfCls({ populate : true ,sort : { createdAt : 1}}))
    },[])
    const participantCols =[
        {
            Header : 'CC Code',
            accessor : 'collegeCode',
            cellStyle : {
                width : '50%'
            }
        },
        {
            Header : ' ',
            Cell : ({row})=>{
                // console.log('row', row?.original)
                return (
                    <div className='flex flex-row items-center gap-4'>
                        <PrimaryButton
                            onClick={(e)=>{
                                e.stopPropagation();
                                setRequestData(row?.original?.user);
                                setShowClDetailModal(true)
                            }}
                        >
                            View Participants <Eye size={14} className='ml-2' />
                        </PrimaryButton>
                    </div>
                )
            },
            cellStyle : {
                width : '50%'
            }
        }
    ];

    const participantMemo = useMemo(
        ()=> ( warOfCl?.docs ? warOfCl?.docs : []),
        [warOfCl]
    )
    return (
        <PageWithCard
            heading={
                <>
                    <Link to={'/committee/event'}>Events</Link>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />
                    </span>
                    <span>{'War of CL'}</span>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />
                    </span>
                    <span>{'Participants'}</span>
                </>
            }
        >
            <ViewDetails 
                requestData={requestData}
                showApprovedModal={showClDetailModal}
                setShowApprovedModal={setShowClDetailModal}
            />
            <div className='w-full md:w-1/2'>
            <TableWithHeading 
                columns={participantCols}
                data={participantMemo}
            />
            </div>

        </PageWithCard>
    );
};

export default WarOfClParticipants;
