import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { array, object, string } from 'yup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { XSquare } from 'react-feather';
import FormikLabel from '../../components/formik/FormikLabel';
import { useDispatch, useSelector } from 'react-redux';
import {
    createYear,
    deleteYear,
    fetchYears,
    getYears,
} from '../../app/reducers/Master/yearsSlice';
import TableWithHeading from '../../components/Table/TableWithHeading';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import YearEditModal from './YearEditModal';

const AddYears = () => {
    const { years } = useSelector(getYears);
    console.log('years', years);
    const [yearEditModal, setYearEditModal] = useState(false);
    const [requestData, setRequestData] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchYears({sort : {createdAt : 1}}));
    }, []);
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: object({
            name: string()
                .matches(
                    /^[A-Z\s]+$/,
                    'Year Name must contain only uppercase letters (e.g., FYJC, FY DEGREE)'
                )
                .label('Year Name')
                .required(),
        }),
        onSubmit: (values, { resetForm }) => {
            console.log('values', values);
            dispatch(createYear(values)).then((res) => {
                if (res?.payload?.success) {
                    dispatch(fetchYears({sort : {createdAt : 1}}));
                }
            });
            resetForm();
        },
    });

    const cols = [
        {
            Header: 'Sr.No',
            Cell: ({ row }) => {
                // console.log('row', row)
                return <span>{row?.index + 1}</span>;
            },
        },
        {
            Header: 'Years',
            accessor: 'name',
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center gap-4">
                        <PrimaryButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setRequestData(row?.original);
                                setYearEditModal(true);
                            }}
                        >
                            Edit
                        </PrimaryButton>
                        <DangerButton
                            onClick={async (e) => {
                                e.stopPropagation();
                                await dispatch(
                                    deleteYear({ id: row?.original?._id })
                                );
                                await dispatch(
                                    fetchYears({sort : {createdAt : 1}})
                                );
                            }}
                        >
                            Delete
                        </DangerButton>
                    </div>
                );
            },
        },
    ];

    const yearMemo = useMemo(() => {
        if (years?.docs?.length) {
            return years?.docs;
        } else {
            return [];
        }
    }, [years]);
    return (
        <PageWithCard heading="Years">
            <YearEditModal
                requestData={requestData}
                yearEditModal={yearEditModal}
                setYearEditModal={setYearEditModal}
            />
            <form
                className="flex w-full md:w-1/2 flex-col gap-4 bg-black p-4 rounded sm:rounded-md mb-4"
                onSubmit={formik.handleSubmit}
            >
                <FormikInputGroup
                    name="name"
                    label="Year Name"
                    formik={formik}
                />

                <div className="mt-4">
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
            {years?.docs?.length > 0 && (
                <TableWithHeading columns={cols} data={yearMemo} />
            )}
        </PageWithCard>
    );
};

export default AddYears;
