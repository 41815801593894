import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';

const SLICE_NAME = 'features';

export const createFeature = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchFeatures = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editFeature = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchFeature = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);

const featuresSlice = createSlice({
    name: SLICE_NAME,
    initialState: generateStateSingle(SLICE_NAME),
    extraReducers: {
        ...createData.generateExtraReducers(createFeature, SLICE_NAME),
        ...fetchData.generateExtraReducers(fetchFeatures, SLICE_NAME),
        ...editData.generateExtraReducers(editFeature, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchFeature, SLICE_NAME),
    },
});

export const getFeatures = (state) => state.features;

export default featuresSlice.reducer;
