import React, { useEffect } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import {
    editScoringTemplate,
    fetchScoringTemplate,
    fetchScoringTemplates,
    getScoringTemplates,
} from '../../app/reducers/ScoringTemplate/scoringTemplateSlice';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import { array, object, string } from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';

const EditScoringTemplate = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { elementEditData, elementEditDataLoading } =
        useSelector(getScoringTemplates);
    useEffect(() => {
        dispatch(fetchScoringTemplate({ _id: id }));
    }, []);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...elementEditData,
        },
        validationSchema: object({
            templateName: string().required(),
            points: array(),
        }),
        onSubmit: (values, { resetForm }) => {
            console.log('edit values', values);
            dispatch(editScoringTemplate(values));
        },
    });
    return (
        <PageWithCard heading="Edit Scoring Template">
            {elementEditDataLoading ? (
                <Clipboard />
            ) : (
                <form
                    className="flex flex-col gap-4"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikInputGroup
                        name="templateName"
                        label="Template Name"
                        formik={formik}
                        required
                    />
                    <FormikProvider value={formik}>
                        <div className="text-bold">Points</div>
                        <FieldArray
                            name="points"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col gap-2">
                                        <div>
                                            {formik?.values?.points?.map(
                                                (ele, index) => (
                                                    <div
                                                        className="relative p-4 mb-2"
                                                        style={{
                                                            border: '1px solid #d6c7c7',
                                                            borderRadius: '5px',
                                                        }}
                                                        key={index}
                                                    >
                                                        <div className="flex gap-2">
                                                            <FormikInputGroup
                                                                name={`points.${index}.name`}
                                                                label="Name"
                                                                formik={formik}
                                                            />
                                                            <FormikInputGroup
                                                                name={`points.${index}.pointAwarded`}
                                                                label="Point Awarded"
                                                                formik={formik}
                                                                type="number"
                                                            />
                                                        </div>
                                                        <div>
                                                            <DangerButton
                                                                className="mt-3"
                                                                onClick={() => {
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    );
                                                                }}
                                                                type="button"
                                                            >
                                                                Remove
                                                            </DangerButton>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div>
                                            <SecondaryButton
                                                onClick={() => {
                                                    arrayHelpers.push({});
                                                }}
                                                type="button"
                                            >
                                                Add More
                                            </SecondaryButton>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>
                    {['Super User', 'Developer'].includes(user?.role?.name) && (
                        <div>
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        </div>
                    )}
                </form>
            )}
        </PageWithCard>
    );
};

export default EditScoringTemplate;
