import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';

const SLICE_NAME = 'festival';

export const fetchFestivals = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createFestival = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchFestival = fetchEditData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);
export const editFestival = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteFestival = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);

const festivalSlice = createSlice({
    initialState: generateStateSingle(SLICE_NAME),
    name: SLICE_NAME,
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchFestivals, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchFestival, SLICE_NAME),
        ...createData.generateExtraReducers(createFestival, SLICE_NAME),
        ...editData.generateExtraReducers(editFestival, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteFestival, SLICE_NAME),
    },
});

export const getFestivals = (state) => state.festivals;

export default festivalSlice.reducer;
