import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import { createData, fetchData } from "../../generators/generateThunks";

const SLICE_NAME = 'websiteCreatives';

export const createWebsiteCreativeData = createData.generateThunk(SLICE_NAME,SLICE_NAME);

export const fetchWebsiteCreativesData = fetchData.generateThunk(SLICE_NAME,SLICE_NAME);

const websiteCreativesSlice = createSlice({
    name : SLICE_NAME,
    initialState : generateStateSingle(SLICE_NAME),
    extraReducers : {
        ...createData.generateExtraReducers(createWebsiteCreativeData, SLICE_NAME),
        ...fetchData.generateExtraReducers(fetchWebsiteCreativesData, SLICE_NAME)
    }
})

export const getWebsiteCreatives = (state) => state.websiteCreatives;

export default websiteCreativesSlice.reducer;
