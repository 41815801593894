import React from 'react'
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic'
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch } from 'react-redux';
import { editEvent } from '../../app/reducers/Event/eventSlice';
import { createWarOfCl } from '../../app/reducers/WarOfCl/warOfClSlice';

const WarOfClDisclaimer = ({
    requestData,
    showDisclaimerModal,
    setShowDisclaimerModal,
    computeWarOfCls
}) => {
    console.log('user data', requestData)
    const dispatch = useDispatch();
  return (
    <ModalBasic
        modalOpen={showDisclaimerModal}
        setModalOpen={setShowDisclaimerModal}
    >
      <div className="p-4">
                <h1 className="text-2xl text-red-500 font-medium mb-4">
                    Disclaimer :
                </h1>
                <p className="text-slate-200 text-xl">
                This will register you for all War of CL events automatically. Are you sure you want to proceed?
                </p>
                <div className='mt-4'>
                    <PrimaryButton
                        onClick={async(e)=>{
                            e.stopPropagation();
                            await dispatch(createWarOfCl({
                                collegeCode : requestData?.username, 
                                userId : requestData?._id 
                            }));
                            computeWarOfCls();
                            setShowDisclaimerModal(false);
                        }}
                    >
                        Yes
                    </PrimaryButton>
                </div>
            </div>
    </ModalBasic>
  )
}

export default WarOfClDisclaimer
