import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from '../app/reducers/Auth/authSlice';
import SecondaryButton from '../components/infrastructure/Buttons/SecondaryButton';
import {
    editEmployee,
    editExtEmployee,
    fetchEmployee,
    fetchEmployees,
    getEmployees,
} from '../app/reducers/Users/EmployeeSlice';
import ModalBasic from '../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../components/formik/FormikInputGroup';
import { useFormik } from 'formik';
import PrimaryButton from '../components/infrastructure/Buttons/PrimaryButton';
import {
    fetchColleges,
    getColleges,
} from '../app/reducers/College/collegeSlice';
import FormikSelectGroup from '../components/formik/FormikSelectGroup';
import { generateOptions } from '../utils/Utils';
import { object, string, ref } from 'yup';
import QueryString from 'qs';
import { authAxiosInstance } from '../utils/axiosConfig';
import { editCollegeParticipant } from '../app/reducers/CollegeParticipant/collegeParticipantSlice';
import { ACL_ROLE_ID, CL_ROLE_ID } from '../utils/constants';
import { toast } from 'react-toastify';
import DangerButton from '../components/infrastructure/Buttons/DangerButton';
import FormikImageCropper from '../components/formik/FormikImageCropper';
import FormikDirectImageUpload from '../components/formik/FormikDirectImageUpload';
import { getWebsiteCreatives } from '../app/reducers/WebsitesCreatives/websitesCreativeSlice';
import { Download } from 'react-feather';
import { ClipLoader } from 'react-spinners';
import { fetchEvents, getEvents } from '../app/reducers/Event/eventSlice';
import {
    fetchParticipants,
    getParticipants,
} from '../app/reducers/Participant/teamParticipantSlice';
import {
    fetchScoreBoardsWithCommittees,
    getScoreBoards,
} from '../app/reducers/Scoreboard/scoreboardSlice';
import SetButtons from '../components/infrastructure/SetButtons';
import moment from 'moment/moment';
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useMediaQuery } from 'react-responsive';
import O2Favicon from '../images/O2 favicon.png';
import DashBoardSchedule from './DashBoardSchedule';
import { getFeatures } from '../app/reducers/Features/featuresSlice';
import DashboardAdminHead from './DashboardAdminHead';

const Dashboard = ({ actionFeatures }) => {
    const { user } = useSelector(getAuth);
    const { features } = useSelector(getFeatures);
    const { employees, elementEditData } = useSelector(getEmployees);
    const { scoreBoard } = useSelector(getScoreBoards);
    const [rank, setRank] = useState(0);
    const [totalScore, setTotalScore] = useState({});
    const [otseReg, setOtseReg] = useState([]);
    const [showEditForm, setShowEditForm] = useState(false);
    const [registeredEvent, setRegisteredEvent] = useState(0);
    const [participantsRegister, setParticipantsRegister] = useState(0);
    const [totalEvents, setTotalEvents] = useState(0);
    const [totalParticipants, setTotalParticipants] = useState(0);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [canShowCertificate, setCanShowCertificate] = useState(false);
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    console.log('creative data', websiteCreatives);
    const dynamicDefaultData = websiteCreatives[0];
    const [isDownloading, setIsDownloading] = useState({
        cl: false,
        acl: false,
        participant: false,
    });
    const dispatch = useDispatch();

    const actionsBtn = ['Participants Certificate'];
    const createFeatureActionsConfig = (actionFeatures, actions) => {
        const config = {};
        actionFeatures?.forEach((ids, index) => {
            config[ids] = actions[index];
        });
        return config;
    };

    const computeActiveFeature = () => {
        const buttonStateSetter = {
            'Participants Certificate': setCanShowCertificate,
        };
        const actionButtonMap = createFeatureActionsConfig(
            actionFeatures,
            actionsBtn
        );
        console.log(' obj action map', actionButtonMap);
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item) => {
                    if (actionFeatures.includes(item?._id)) {
                        const action = actionButtonMap[item?._id];
                        const status = item?.status;
                        if (buttonStateSetter[action]) {
                            buttonStateSetter[action](status === 'active');
                        }
                    }
                });
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn) => btn(canShow));
        }
    };
    const computeEventAndParticipantData = async () => {
        try {
            let stringData;
            if (user?.ncpCollege) {
                stringData = QueryString.stringify({
                    ncpCollege: user?.ncpCollege,
                });
            } else {
                stringData = QueryString.stringify({
                    college: user?.college?._id,
                });
            }
            const response = await authAxiosInstance.get(
                `/teamParticipant?${stringData}`
            );
            const teamPartiData = response?.data?.data?.docs;
            console.log('team1', teamPartiData);
            let events = [];
            if (teamPartiData.length > 0) {
                const data = {
                    eventIds: teamPartiData?.map((ele) => ele.event),
                    // status: 'approved',
                    populate: true,
                };
                console.log('data', data);
                const resp = await authAxiosInstance.post(
                    `/teamParticipant/getEventParticipantData`,
                    data
                );
                console.log('event wise', resp?.data?.data);
                events = resp?.data?.data;
                setRegisteredEvent(events.length);
            } else {
                setRegisteredEvent(0);
            }
            if (teamPartiData.length > 0) {
                let count = 0;
                for (let i = 0; i < teamPartiData.length; i++) {
                    if (teamPartiData[i].type !== 'otse') {
                        count = count + teamPartiData[i].participants.length;
                    }
                }
                console.log('total count', count);
                setParticipantsRegister(count);
            } else {
                setParticipantsRegister(0);
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    const computeTotalParticipants = async () => {
        try {
            const stringData = QueryString.stringify({ populate: true });
            const resp = await authAxiosInstance.get(`/event?${stringData}`);
            const totalData = resp?.data?.data?.docs;
            console.log('total event', totalData);
            let totalCount = 0;
            for (let i = 0; i < totalData.length; i++) {
                totalCount = totalCount + totalData[i].teamMaxCapacity;
            }
            console.log('total count', totalCount);
            setTotalParticipants(totalCount);
        } catch (error) {
            console.log('error', error);
        }
    };
    const computeOtse = async () => {
        const stringData = QueryString.stringify({
            type: 'otse',
            mainCode: user?.username,
        });
        const resp = await authAxiosInstance.get(
            `/teamParticipant?${stringData}`
        );
        const data = resp?.data?.data?.docs;
        console.log('otse', data);
        setOtseReg(data);
    };
    const computeRank = () => {
        try {
            const index = scoreBoard?.data?.findIndex(
                (ele) => ele._id === user?.username
            );
            console.log('rank index', index);
            if (index !== -1) {
                setRank(`${index + 1}`);
            } else {
                setRank(0);
            }
            const scoreData = scoreBoard?.data?.find(
                (ele) => ele._id === user?.username
            );
            if (scoreData) {
                setTotalScore(scoreData);
            }
        } catch (error) {
            console.log('Error', error);
        }
    };
    const computeTotalEvent = async () => {
        try {
            const stringData = QueryString.stringify({ populate: true });
            const resp = await authAxiosInstance.get(`/event?${stringData}`);
            setTotalEvents(resp?.data?.data?.docs?.length);
        } catch (error) {
            console.log('Error', error);
        }
    };
    useEffect(() => {
        dispatch(fetchEmployee({ username: user?.username, populate: true }));
        dispatch(fetchColleges({ populate: true }));
        dispatch(
            fetchEvents({
                populate: true,
                status: 'scheduled',
                sort: { startTime: 1 },
            })
        );
    }, []);

    useEffect(() => {
        dispatch(
            fetchScoreBoardsWithCommittees({
                committeeIds: [],
                status: 'approved',
                populate: true,
            })
        );
    }, []);
    useEffect(() => {
        computeRank();
    }, [scoreBoard]);
    useEffect(() => {
        computeOtse();
        computeEventAndParticipantData();
        computeTotalParticipants();
        computeTotalEvent();
    }, []);
    useEffect(() => {
        computeActiveFeature();
    }, [features]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...elementEditData,
            password: '',
            confirmPassword: '',
        },
        validationSchema: object({
            firstName: string()
                .matches(
                    /^[A-Z][a-z]*$/,
                    'Case sensitive & can not contain spaces. i.e. Hello'
                )
                .label('First Name')
                .required(),
            lastName: string()
                .matches(
                    /^[A-Z][a-z]*$/,
                    'Case sensitive & can not contain spaces. i.e. Hello'
                )
                .label('First Name')
                .required(),
            email: string().email('Invalid email').label('Email').required(),
            college: string().required(),
            contactNumber: string()
                .matches(
                    /^[0-9]{10}$/,
                    'Contact Number must be numbers (exact 10 digits)'
                )
                .min(10, 'Min 10 digits')
                .max(10, 'Max 10 digits')
                .label('Contact Number')
                .required(),
            password: string()
                .matches(/^\S+$/, 'Space in not allowed')
                .label('Password'),
            confirmPassword: string()
                .label('Confirm password')
                .oneOf([ref('password')], 'Password must be same'),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('Dashboard', values);
            const editExtEmpObj = {
                _id: values?._id,
                ...values,
            };
            console.log('object', editExtEmpObj);
            await dispatch(editExtEmployee(editExtEmpObj));
        },
    });
    return (
        <div style={{ backgroundColor: '#141414' }}>
            <DashboardAdminHead
                user={user}
                registeredEvent={registeredEvent}
                totalEvents={totalEvents}
            />
            {[
                'Developer',
                'Super User',
                'HOD',
                'Head',
                'Volunteer',
                'Sub Head',
            ].includes(user?.role?.name) && <DashBoardSchedule />}

            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full">
                {/* Page header */}
                <div className="mb-8 flex flex-cols gap-2 justify-between ">
                    {/* Title */}
                    <h1 className="text-2xl md:text-3xl text-slate-200 font-bold">
                        {user?.role?.name == 'Unapproved CL' && (
                            <span>
                                {`Welcome, ${elementEditData?.firstName} ${elementEditData?.lastName}_`}
                                <span
                                    style={{
                                        color: dynamicDefaultData?.highlightedText
                                            ? dynamicDefaultData?.highlightedText
                                            : '',
                                    }}
                                >
                                    {user?.username}
                                </span>
                            </span>
                        )}
                        {user?.role?.name == 'CL' && (
                            <span>
                                {`Welcome,`}{' '}
                                <span
                                    style={{
                                        color: dynamicDefaultData?.highlightedText
                                            ? dynamicDefaultData?.highlightedText
                                            : '',
                                    }}
                                >
                                    {user?.username}
                                </span>
                            </span>
                        )}
                    </h1>
                </div>

                {/* Content */}

                {user?.role?.name == 'Unapproved CL' && (
                    <form
                        onSubmit={formik.handleSubmit}
                        className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md full md:w-[50%]"
                    >
                        <div className="flex flex-col gap-3 sm:flex-row w-full">
                            <div className="w-full">
                                <FormikInputGroup
                                    required
                                    formik={formik}
                                    label="First Name"
                                    name="firstName"
                                />
                            </div>
                            <div className="w-full">
                                <FormikInputGroup
                                    required
                                    formik={formik}
                                    label="Last Name"
                                    name="lastName"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-3 sm:flex-row w-full">
                            <div className="w-full">
                                <FormikInputGroup
                                    required
                                    formik={formik}
                                    label="Contact Number"
                                    name="contactNumber"
                                />
                            </div>
                            <div className="w-full">
                                <FormikInputGroup
                                    required
                                    formik={formik}
                                    label="Email"
                                    name="email"
                                />
                            </div>
                        </div>
                        <FormikImageCropper
                            name="userPhotoId"
                            label="Photo For ID"
                            formik={formik}
                            location={`1648_entertainment/externalRegistered/`}
                            required
                            randomize
                            dynamicDefaultData={dynamicDefaultData}
                        />
                        <FormikDirectImageUpload
                            name="collegeIdPhoto"
                            label="College ID Photo"
                            formik={formik}
                            location={`1648_entertainment/externalRegistered/`}
                            required
                            randomize
                        />
                        {
                            <div className="flex flex-col gap-3 sm:flex-row w-full">
                                <SecondaryButton
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        setShowChangePassword(
                                            !showChangePassword
                                        );
                                    }}
                                >
                                    {showChangePassword
                                        ? 'Cancel'
                                        : 'Change Password'}
                                </SecondaryButton>
                            </div>
                        }
                        {showChangePassword && (
                            <div className="flex flex-col gap-3 sm:flex-row w-full">
                                <div className="w-full">
                                    <FormikInputGroup
                                        formik={formik}
                                        label="Password"
                                        name="password"
                                        type="password"
                                    />
                                </div>
                                <div className="w-full">
                                    <FormikInputGroup
                                        formik={formik}
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        type="password"
                                    />
                                </div>
                            </div>
                        )}

                        <div>
                            <PrimaryButton
                                type="submit"
                                style={{
                                    backgroundColor:
                                        dynamicDefaultData?.highlightedText
                                            ? dynamicDefaultData?.highlightedText
                                            : '',
                                }}
                            >
                                Update
                            </PrimaryButton>
                        </div>
                    </form>
                )}
                {user?.role?.name === 'CL' && (
                    <>
                        <div className="w-full md:w-[55%] grid grid-cols-2 gap-4  mb-10">
                            <div className=" bg-black rounded-lg p-6 flex items-center justify-center flex-col text-center">
                                <p className="text-slate-200 font-semibold text-lg md:text-4xl">
                                    No. of events registered in
                                </p>
                                <span
                                    className="font-semibold text-2xl md:text-6xl"
                                    style={{
                                        color: dynamicDefaultData?.highlightedText
                                            ? dynamicDefaultData?.highlightedText
                                            : '#ffffff',
                                    }}
                                >
                                    {`${registeredEvent}/${totalEvents}`}
                                </span>
                            </div>
                            <div className=" bg-black rounded-lg  p-6 flex items-center justify-center flex-col text-center">
                                <p className="text-slate-200 font-semibold text-lg md:text-4xl">
                                    Score
                                </p>
                                <span
                                    className="font-semibold text-2xl md:text-6xl"
                                    style={{
                                        color: dynamicDefaultData?.highlightedText
                                            ? dynamicDefaultData?.highlightedText
                                            : '#ffffff',
                                    }}
                                >
                                    {`${totalScore.totalPoints ? totalScore.totalPoints : 0}`}
                                </span>
                            </div>
                            <div className=" bg-black rounded-lg  p-6 flex items-center justify-center flex-col text-center">
                                <p className="text-slate-200 font-semibold text-lg md:text-4xl">
                                    Rank
                                </p>
                                <span
                                    className="font-semibold text-2xl md:text-6xl"
                                    style={{
                                        color: dynamicDefaultData?.highlightedText
                                            ? dynamicDefaultData?.highlightedText
                                            : '#ffffff',
                                    }}
                                >
                                    {`#${rank}`}
                                </span>
                            </div>
                            <div className=" bg-black rounded-lg  p-6 flex items-center justify-center flex-col text-center">
                                <p className="text-slate-200 font-semibold text-lg md:text-4xl">
                                    OTSE Registration
                                </p>
                                <span
                                    className="font-semibold text-2xl md:text-6xl"
                                    style={{
                                        color: dynamicDefaultData?.highlightedText
                                            ? dynamicDefaultData?.highlightedText
                                            : '#ffffff',
                                    }}
                                >
                                    {otseReg?.reduce
                                        ? `${otseReg?.reduce((acc, ele) => {
                                              return (acc +=
                                                  ele.participants.length);
                                          }, 0)}`
                                        : 0}
                                </span>
                            </div>
                            <div className="col-span-2 bg-black rounded-lg  p-6 flex items-center justify-between flex-row text-center">
                                <p className="text-slate-200 font-semibold text-lg md:text-4xl text-left w-[60%]">
                                    No. of participants registered
                                </p>
                                <span
                                    className="font-semibold text-2xl md:text-6xl w-[40%]"
                                    style={{
                                        color: dynamicDefaultData?.highlightedText
                                            ? dynamicDefaultData?.highlightedText
                                            : '#ffffff',
                                    }}
                                >
                                    {`${participantsRegister}/${totalParticipants}`}
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-row items-start justify-start gap-4">
                            {isDownloading.participant ? (
                                <ClipLoader color="#FFFFFF" size={18} />
                            ) : (
                                canShowCertificate && (
                                    <DangerButton
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            try {
                                                const string =
                                                    QueryString.stringify({
                                                        populate: true,
                                                        college:
                                                            user?.college?._id,
                                                        // status: 'approved',
                                                    });
                                                const resp =
                                                    await authAxiosInstance.get(
                                                        `/teamParticipant/getCollegeParticipantData?${string}`
                                                    );
                                                console.log(
                                                    'resp parti',
                                                    resp?.data?.data
                                                );
                                                const participantData =
                                                    resp?.data?.data;
                                                if (
                                                    participantData?.length ===
                                                    0
                                                ) {
                                                    throw new Error(
                                                        'No Data Found'
                                                    );
                                                }
                                                const response =
                                                    await authAxiosInstance.post(
                                                        '/printable/participantCertificatePdf',
                                                        participantData
                                                    );
                                                const printWindow = window.open(
                                                    '',
                                                    '_blank'
                                                );
                                                printWindow.document.write(
                                                    response.data
                                                );
                                                // const response =
                                                //     await authAxiosInstance.post(
                                                //         '/printable/participantCertificatePdf',
                                                //         participantData,
                                                //         { responseType: 'blob' }
                                                //     );
                                                // const fileName =
                                                //     'ParticipantCertificate.pdf';
                                                // saveAs(response.data, fileName);
                                            } catch (error) {
                                                console.log('Error', error);
                                                toast.error(`${error.message}`);
                                            } finally {
                                                setIsDownloading((state) => ({
                                                    ...state,
                                                    participant: false,
                                                }));
                                            }
                                        }}
                                    >
                                        <Download size={14} className="mr-2" />{' '}
                                        Participants Certificate
                                    </DangerButton>
                                )
                            )}
                        </div>
                    </>
                )}

                {/* <div className="bg-white shadow-lg rounded-sm mb-8">
                    <div className="flex flex-col md:flex-row md:-mr-px"></div>
                </div> */}
            </div>
        </div>
    );
};

export default Dashboard;
