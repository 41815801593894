import React from 'react';

const EventCard = ({ children }) => {
    return (
        <section className="text-gray-600 body-font">
            <div className="container mx-auto">
                <div className="flex flex-wrap -m-4">
                    {children}
                </div>
            </div>
        </section>
    );
};

export default EventCard;
