import { createSlice } from "@reduxjs/toolkit"
import { generateStateSingle } from "../../generators/generateState";
import { createData, deleteData, editData, fetchData, fetchEditData } from "../../generators/generateThunks";

const SLICE_NAME = 'schedule';

export const createSchedule = createData.generateThunk(SLICE_NAME, SLICE_NAME);

export const fetchSchedules = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);

export const fetchSchedule = fetchEditData.generateThunk(SLICE_NAME. SLICE_NAME);

export const editSchedule = editData.generateThunk(SLICE_NAME,SLICE_NAME);

export const deleteSchedule = deleteData.generateThunk(SLICE_NAME,SLICE_NAME);

const scheduleSlice = createSlice({
    name : SLICE_NAME,
    initialState : generateStateSingle(SLICE_NAME),
    extraReducers :{
        ...createData.generateExtraReducers(createSchedule,SLICE_NAME),
        ...fetchData.generateExtraReducers(fetchSchedules,SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchSchedule,SLICE_NAME),
        ...editData.generateExtraReducers(editSchedule,SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteSchedule,SLICE_NAME),
    }
});
export const getSchedules = (state) => state.schedule;
export default scheduleSlice.reducer;