import React from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useFormik } from 'formik';
import { object, ref, string } from 'yup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import { editRegistrationRequestUserPass } from '../../app/reducers/RegistrationRequest/registrationRequestSlice';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import { fetchEmployees } from '../../app/reducers/Users/EmployeeSlice';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const ChangePassword = ({
    requestedData,
    showChangePasswordModal,
    setShowChangePasswordModal,
}) => {
    // console.log('req data', requestedData);
    const { user } = useSelector(getAuth);

    const dispatch = useDispatch();

    const formik = useFormik({
        enableReinitialize : true,
        initialValues: {
            password: '',
            confirmPassword: '',
            isAccountLocked : requestedData?.isAccountLocked === true ? true : false,
        },
        validationSchema: object({
            password: string().required(),
            confirmPassword: string()
                .required()
                .oneOf([ref('password')], 'Password must be same'),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('change pass', values);
            await dispatch(
                editRegistrationRequestUserPass({
                    username: requestedData?.username,
                    password: values?.password,
                    isAccountLocked : false,
                    loginAttempts : 0,
                })
            );
            if (['Admin Head', 'HOD'].includes(user?.role?.name)) {
                dispatch(
                    fetchEmployees({
                        populate: true,
                        'role.name': { $in : ['Volunteer','CL','ACL','Unapproved CL', 'NCP Representative']},
                        sort: { firstName: 1 },
                    })
                );
            } else {
                dispatch(
                    fetchEmployees({ populate: true, sort: { firstName: 1 } })
                );
            }
            setShowChangePasswordModal(false);
            resetForm();
        },
    });
    return (
        <ModalBasic
            modalOpen={showChangePasswordModal}
            setModalOpen={setShowChangePasswordModal}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4  bg-black p-4 rounded sm:rounded-md"
            >
                <FormikInputGroup
                    autocomplete="new-password"
                    name="password"
                    type="password"
                    label="Password"
                    formik={formik}
                    required
                />
                <FormikInputGroup
                    name="confirmPassword"
                    formik={formik}
                    label="Confirm Password"
                    required
                    type="password"
                />
                {
                    requestedData?.isAccountLocked === true &&
                    <FormikCheckbox 
                        formik={formik}
                        label='Account Locked'
                        name='isAccountLocked'
                    />
                }
                <div className="text-center">
                    <PrimaryButton type="submit" className="w-[25%]">
                        Submit
                    </PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default ChangePassword;
