import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { editEmployee, fetchEmployee, fetchEmployees } from '../../app/reducers/Users/EmployeeSlice';
import { ACL_ROLE_ID } from '../../utils/constants';
import FormikImageCropper from '../../components/formik/FormikImageCropper';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import { getWebsiteCreatives } from '../../app/reducers/WebsitesCreatives/websitesCreativeSlice';

const EditAclRegistration = ({
    requestData,
    showApprovedModal,
    setShowApprovedModal,
}) => {
    console.log('data', requestData);
    const { websiteCreatives } = useSelector(getWebsiteCreatives);
    console.log('creative data', websiteCreatives);
    const dynamicDefaultData = websiteCreatives[0];
    const dispatch = useDispatch();
    // useEffect(() => {
      
    // }, []);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
          ...requestData
        },
        validationSchema: object({
            firstName: string()
                .matches(/^[A-Z][a-z]*$/, 'Case sensitive & can not contain spaces. i.e. Hello').label('First Name')
                .required(),
            lastName: string()
                .matches(/^[A-Z][a-z]*$/, 'Case sensitive & can not contain spaces. i.e. Hello').label('First Name')
                .required(),
            email: string().email('Invalid email').label('Email').required(),
            username: string()
                .matches(
                    /^[0-9]{10}$/,
                    'Contact Number must be numbers (exact 10 digits)'
                )
                .min(10,'Min 10 digits')
                .max(10, 'Max 10 digits').label('Contact Number')
                .required(),
        }),
        onSubmit: async(values,{resetForm}) => {
            console.log('edit acl req', values);
            await dispatch(editEmployee({...values, contactNumber : values?.username}));
            dispatch(fetchEmployees({ role : ACL_ROLE_ID, college : values?.college }));
            setShowApprovedModal(false);
        },
    });
    return (
        <ModalBasic
            modalOpen={showApprovedModal}
            setModalOpen={setShowApprovedModal}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 p-4"
            >
                <div className="flex flex-col gap-4 sm:flex-row w-full">
                    <div className="w-full">
                        <FormikInputGroup
                            formik={formik}
                            name="firstName"
                            label="First Name"
                        />
                    </div>
                    <div className="w-full">
                        <FormikInputGroup
                            formik={formik}
                            name="lastName"
                            label="Last Name"
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-4 sm:flex-row w-full">
                    <div className="w-full">
                        <FormikInputGroup
                            formik={formik}
                            name="username"
                            label="Contact Number"
                        />
                    </div>
                    <div className="w-full">
                        <FormikInputGroup
                            formik={formik}
                            name="email"
                            label="Email"
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-4 sm:flex-row w-full">
                        <div className="w-full">
                            <FormikImageCropper
                                name="userPhotoId"
                                label="Enter Your Photo ID"
                                formik={formik}
                                location={`1648_entertainment/externalRegistered/acl/`}
                                required
                                randomize
                                dynamicDefaultData={dynamicDefaultData}
                            />
                        </div>
                        <div className="w-full">
                            <FormikDirectImageUpload
                                name="collegeIdPhoto"
                                label="College ID Photo"
                                formik={formik}
                                location={`1648_entertainment/externalRegistered/acl/`}
                                required
                                randomize
                            />
                        </div>
                    </div>
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default EditAclRegistration;
