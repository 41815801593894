import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
    editEmployee,
    editExtEmployee,
    fetchEmployee,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import { useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { object, string } from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import {
    fetchColleges,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import { fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import {
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { ChevronRight } from 'react-feather';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { useState } from 'react';

const EditEmployeePage = () => {
    const { id } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const { elementEditData, elementEditDataLoading } =
        useSelector(getEmployees);
    const { college, loading: collegeLoading } = useSelector(getColleges);
    const { role, loading: roleLoading } = useSelector(getRoles);
    const { committee, loading: committeeLoading } = useSelector(getCommittees);
    const [userRole, setUserRole] = useState({});

    const computeUserRole = async () => {
        try {
            const payload = { _id: id, populate: true };
            const stringData = QueryString.stringify(payload);
            const resp = await authAxiosInstance.get(
                `/users/employee?${stringData}`
            );
            const respData = resp?.data?.data?.docs[0];
            console.log('user', respData);
            setUserRole(respData);
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        dispatch(fetchColleges());
        dispatch(fetchCommittees());
        dispatch(fetchRoles());
        dispatch(fetchEmployee({ _id: id }));
        computeUserRole();
    }, []);

    const collegeData = useMemo(
        () => (college?.docs ? college.docs : []),
        [college]
    );
    const roleData = useMemo(() => (role?.docs ? role.docs : []), [role]);
    const committeeData = useMemo(
        () => (committee?.docs ? committee.docs : []),
        [committee]
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...elementEditData,
            password: '',
        },
        validationSchema: object({
            firstName: string().required(),
            username: string().required(),
        }),
        onSubmit: (values) => {
            dispatch(editExtEmployee(values));
        },
    });
    return (
        <PageWithCard
            heading={
                <>
                    <Link to={'/users/view-employee'}>Super Users</Link>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />{' '}
                    </span>
                    <span>{'Edit Super User'}</span>
                </>
            }
        >
            {elementEditDataLoading ? (
                <ClipLoader color="#FFFFFF" size={18}/>
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4  bg-black p-4 rounded sm:rounded-md"
                >
                    <FormikInputGroup
                        label="System Id"
                        name="_id"
                        required
                        readOnly
                        formik={formik}
                    />
                    <FormikInputGroup
                        label="Email"
                        name="email"
                        required
                        readOnly
                        formik={formik}
                    />
                    <FormikInputGroup
                        label="Username"
                        name="username"
                        required
                        readOnly
                        formik={formik}
                    />
                    <FormikInputGroup
                        label="First Name"
                        name="firstName"
                        required
                        formik={formik}
                    />
                    <FormikInputGroup
                        label="Last Name"
                        name="lastName"
                        required
                        formik={formik}
                    />
                    <FormikInputGroup
                        label="Password"
                        name="password"
                        type="password"
                        required
                        formik={formik}
                    />
                    <FormikInputGroup
                        label="Roll No"
                        name="rollNo"
                        required
                        formik={formik}
                    />
                    {collegeLoading ? (
                        <ClipLoader color="#FFFFFF" size={18}/>
                    ) : (
                        <FormikSelectGroup
                            name="college"
                            label="College"
                            formik={formik}
                            options={generateOptions({
                                array: collegeData,
                                valueField: '_id',
                                labelField: 'name',
                            })}
                        />
                    )}
                    {committeeLoading ? (
                        <ClipLoader color="#FFFFFF" size={18} />
                    ) : (
                        <FormikSelectGroup
                            name="committee"
                            label="Committee"
                            formik={formik}
                            options={generateOptions({
                                array: committeeData,
                                labelField: 'name',
                                valueField: '_id',
                            })}
                        />
                    )}
                    {roleLoading ? (
                        <ClipLoader color="#FFFFFF" size={18} />
                    ) : (
                        <FormikSelectGroup
                            name="role"
                            label="Role"
                            formik={formik}
                            options={generateOptions({
                                array: roleData,
                                valueField: '_id',
                                labelField: 'name',
                            })}
                        />
                    )}
                    <FormikCheckbox
                        label="Status"
                        name="status"
                        formik={formik}
                    />
                    <FormikCheckbox
                        label="isDeveloper"
                        name="isSuperUser"
                        formik={formik}
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </PageWithCard>
    );
};

export default EditEmployeePage;
