import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    editEmployee,
    fetchEmployee,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { array, number, object, string } from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import {
    editCollege,
    fetchCollege,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import {
    editRole,
    fetchRole,
    fetchRoles,
    getRoles,
} from '../../app/reducers/Role/roleSlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { LEVEL_TYPE, ROLE_TYPE } from '../../utils/dropdownOptions';
import { ChevronRight } from 'react-feather';

const EditRole = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { elementEditData, elementEditDataLoading } = useSelector(getRoles);
    console.log('edit role edit', elementEditData);
    const { role } = useSelector(getRoles);
    useEffect(() => {
        dispatch(fetchRole({ _id: id }));
        dispatch(fetchRoles({ type: 'Internal' }));
    }, []);

    const data = useMemo(() => {
        role?.docs ? role.docs : [];
    }, [role]);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...elementEditData,
        },
        validationSchema: object({
            name: string().required(),
            level: string().required(),
        }),
        onSubmit: (values, { resetForm }) => {
            dispatch(editRole(values));
            resetForm();
        },
    });

    return (
        <PageWithCard heading={
            <>
            <Link to={'/role/view'}>Roles</Link>
            <span className="text-slate-200 inline-block px-1"><ChevronRight size={14} className='-mb-0.5' /> </span>
            <span>{"Edit Role"}</span>
            </>
            }>
            {elementEditDataLoading ? (
                <ClipLoader color="#FFFFFF" size={18} />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md"
                >
                    <FormikInputGroup
                        name="name"
                        label="Role Name"
                        formik={formik}
                        required
                    />
                    <FormikSelectGroup
                        name="type"
                        label="Type"
                        formik={formik}
                        options={generateOptions({
                            array: ROLE_TYPE,
                            valueField: 'value',
                            labelField: 'label',
                        })}
                    />
                    <FormikCheckbox
                        name="sharedRole"
                        label="Shared Role"
                        formik={formik}
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </PageWithCard>
    );
};

export default EditRole;
