import React from 'react';
import _ from 'lodash';
import PasswordToggle from './PasswordToggle';

const FormikInputGroup = ({
    formik,
    type,
    label = '',
    required = false,
    name,
    fullWidth,
    ...props
}) => {
    const error = _.get(formik.errors, name);
    const touched = _.get(formik.touched, name);

    return (
        <div className={fullWidth ? 'w-full' : ''}>
            <label className="block text-sm font-medium mb-1 text-slate-200" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>
            {type === 'password' ? (
                <PasswordToggle
                    field={{
                        name,
                        value: _.get(formik.values, name),
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                    }}
                    form={formik}
                    {...props}
                />
            ) : (
                <input
                    type={type}
                    value={_.get(formik.values, name)}
                    onChange={formik.handleChange}
                    name={name}
                    onBlur={formik.handleBlur}
                    className="w-full form-input border-0 rounded-md text-slate-100"
                    style={{
                        backgroundColor: '#141414',
                    }}
                    {...props}
                />
            )}
            {touched && error ? (
                <p className="text-xs text-red-500">{error}</p>
            ) : null}
        </div>
    );
};

export default FormikInputGroup;
