import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { generateOptions } from '../../utils/Utils';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { fetchYears, getYears } from '../../app/reducers/Master/yearsSlice';
import {
    createCourse,
    deleteCourse,
    fetchCourses,
    getCourses,
} from '../../app/reducers/Master/coursesSlice';
import TableWithHeading from '../../components/Table/TableWithHeading';
import CourseEditModal from './CourseEditModal';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';

const AddCourse = () => {
    const { course } = useSelector(getCourses);
    const { years } = useSelector(getYears);
    const [courseEditModal, setCourseEditModal] = useState(false);
    const [requestData, setRequestData] = useState({});
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchYears({sort : {createdAt : 1}}));
        dispatch(fetchCourses({ populate: true, sort: { courseName: 1 } }));
    }, []);
    const formik = useFormik({
        initialValues: {
            courseName: '',
            years: [],
        },
        validationSchema: object({
            courseName: string().label('Course Name').required(),
            years: array().label('Years').required(),
        }),
        onSubmit: (values, { resetForm }) => {
            console.log('values', values);
            dispatch(createCourse(values)).then((res) => {
                if (res.payload.success) {
                    dispatch(
                        fetchCourses({
                            populate: true,
                            sort: { courseName: 1 },
                        })
                    );
                }
            });
            resetForm();
        },
    });
    const cols = [
        {
            Header: 'Sr.No',
            Cell: ({ row }) => {
                console.log('row', row);
                return <span>{row?.index + 1}</span>;
            },
        },
        {
            Header: 'Course Name',
            accessor: 'courseName',
        },
        {
            Header: 'Years',
            Cell: ({ row }) => {
                console.log('row', row?.original);
                return (
                    <ul>
                        {row?.original?.years.map((year) => (
                            <li>{year.name}</li>
                        ))}
                    </ul>
                );
            },
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center gap-4">
                        <PrimaryButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setRequestData(row?.original);
                                setCourseEditModal(true);
                            }}
                        >
                            Edit
                        </PrimaryButton>
                        <DangerButton
                            onClick={async (e) => {
                                e.stopPropagation();
                                dispatch(
                                    deleteCourse({ id: row?.original?._id })
                                );
                                dispatch(
                                    fetchCourses({
                                        populate: true,
                                        sort: { courseName: 1 },
                                    })
                                );
                            }}
                        >
                            Delete
                        </DangerButton>
                    </div>
                );
            },
        },
    ];

    const courseMemo = useMemo(() => {
        if (course?.docs?.length) {
            return course?.docs;
        } else {
            return [];
        }
    }, [course]);

    return (
        <PageWithCard heading="Courses">
            <CourseEditModal
                requestData={requestData}
                courseEditModal={courseEditModal}
                setCourseEditModal={setCourseEditModal}
            />
            <form
                className="flex w-full md:w-1/2 flex-col gap-4 bg-black p-4 rounded sm:rounded-md mb-4"
                onSubmit={formik.handleSubmit}
            >
                <FormikInputGroup
                    name="courseName"
                    label="Course Name"
                    formik={formik}
                />
                <FormikMultiSelect
                    name="years"
                    label="Years"
                    formik={formik}
                    options={generateOptions({
                        array: years?.docs ? years?.docs : [],
                        valueField: '_id',
                        labelField: 'name',
                    })}
                />

                <div className="mt-4">
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
            {course?.docs?.length > 0 && (
                <TableWithHeading columns={cols} data={courseMemo} />
            )}
        </PageWithCard>
    );
};

export default AddCourse;
