import React from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton from './Buttons/PrimaryButton';

const SetButtons = ({ children }) => {
    return (
        <div
            className="flex flex-nowrap overflow-x-scroll no-scrollbar items-center justify-start flex-row gap-2 mb-4 w-full divide-x divide-slate-600"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {children}
        </div>
    );
};

export default SetButtons;
