import React, { useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';

const ViewClAclDetails = ({
    clData,
    aclData,
    showApprovedModal,
    setShowApprovedModal,
}) => {
    // console.log('req cl obj', clData);
    // console.log('req acl array', aclData);
    const [requestData, setRequestData] = useState({});
    const [showImageModal, setShowImageModal] = useState(false);
    return (
        <>
            <ModalBasic
                modalOpen={showApprovedModal}
                setModalOpen={setShowApprovedModal}
                zIndex={50}
            >
                <div className="flex flex-col sm:flex-row gap-4 w-full p-4">
                    <div className="w-full md:w-[30%]">
                        <h1 className="font-bold text-sm text-red-500 mb-2">
                            CL Details
                        </h1>
                        <div className="w-full">
                            <div
                                className="w-[100px] h-[100px] overflow-hidden"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const obj = {
                                        name: clData?.firstName,
                                        url: clData?.userPhotoId,
                                    };
                                    setRequestData(obj);
                                    setShowImageModal(true);
                                }}
                            >
                                <img
                                    className="w-full h-full rounded-full"
                                    src={`${process.env.REACT_APP_S3_URL}${clData?.userPhotoId}`}
                                    alt={clData?.firstName}
                                    style={{
                                        objectPosition: 'center center',
                                    }}
                                />
                            </div>
                            <div className="my-2">
                                <h2 className="text-red-500 text-[10px]">
                                    Name :{' '}
                                    <span className="text-slate-200">
                                        {clData?.firstName} {clData?.lastName}
                                    </span>
                                </h2>
                                <h2 className="text-red-500 text-[10px]">
                                    Phone Number :{' '}
                                    <a
                                        href={`tel:${clData?.contactNumber}`}
                                        className="font-normal text-slate-200"
                                    >
                                        {` ${clData?.contactNumber}`}
                                    </a>
                                </h2>
                                <h2 className="text-red-500 text-[10px]">
                                    Email :{' '}
                                    <span className="text-slate-200">
                                        {clData?.email}
                                    </span>
                                </h2>
                            </div>
                            <div className="w-full">
                                <h3 className="text-red-500 text-[10px] mb-1">
                                    College ID :
                                </h3>
                                <div
                                    className="w-full h-[120px] overflow-hidden "
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const obj = {
                                            name: clData?.firstName,
                                            url: clData?.collegeIdPhoto,
                                        };
                                        setRequestData(obj);
                                        setShowImageModal(true);
                                    }}
                                >
                                    <img
                                        className="w-full h-full rounded-lg"
                                        src={`${process.env.REACT_APP_S3_URL}${clData?.collegeIdPhoto}`}
                                        alt={clData?.firstName}
                                        style={{
                                            objectPosition: 'top center',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-[70%]">
                        <h1 className="font-bold text-sm text-red-500 mb-2">
                            ACL Details
                        </h1>
                        <div className="flex flex-row gap-4">
                            {aclData?.map((acl) => (
                                <div className="w-[50%]">
                                    <div
                                        className="w-[100px] h-[100px] overflow-hidden"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            const obj = {
                                                name: acl?.firstName,
                                                url: acl?.userPhotoId,
                                            };
                                            setRequestData(obj);
                                            setShowImageModal(true);
                                        }}
                                    >
                                        <img
                                            className="w-full h-full rounded-full"
                                            src={`${process.env.REACT_APP_S3_URL}${acl?.userPhotoId}`}
                                            alt={acl?.firstName}
                                            style={{
                                                objectPosition: 'top center',
                                            }}
                                        />
                                    </div>
                                    <div className="my-2">
                                        <h2 className="text-red-500 text-[10px]">
                                            Name :{' '}
                                            <span className="text-slate-200">
                                                {acl?.firstName} {acl?.lastName}
                                            </span>
                                        </h2>
                                        <h2 className="text-red-500 text-[10px]">
                                            Phone Number :{' '}
                                            <a
                                                href={`tel:${acl?.contactNumber}`}
                                                className="font-normal text-slate-200"
                                            >
                                                {` ${acl?.contactNumber}`}
                                            </a>
                                        </h2>
                                        <h2 className="text-red-500 text-[10px]">
                                            Email :{' '}
                                            <span className="text-slate-200">
                                                {acl?.email}
                                            </span>
                                        </h2>
                                    </div>
                                    <div className="w-full">
                                        <h3 className="text-red-500 text-[10px] mb-1">
                                            College ID :
                                        </h3>
                                        <div
                                            className="w-full h-[120px]  overflow-hidden "
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const obj = {
                                                    name: acl?.firstName,
                                                    url: acl?.collegeIdPhoto,
                                                };
                                                setRequestData(obj);
                                                setShowImageModal(true);
                                            }}
                                        >
                                            <img
                                                className="w-full h-full rounded-lg"
                                                src={`${process.env.REACT_APP_S3_URL}${acl?.collegeIdPhoto}`}
                                                alt={acl?.firstName}
                                                style={{
                                                    objectPosition:
                                                        'top center',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </ModalBasic>
            <ModalBasic
                modalOpen={showImageModal}
                setModalOpen={setShowImageModal}
                zIndex={100}
                title={"Photo Preview"} 
                max_width='max-w-[1000px]'
            >
                
                    <img
                        className="w-full "
                        src={`${process.env.REACT_APP_S3_URL}${requestData?.url}`}
                        alt={requestData?.name}
                        
                    />
               
            </ModalBasic>
        </>
    );
};

export default ViewClAclDetails;
