import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import FormikLabel from '../../components/formik/FormikLabel';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { useFormik } from 'formik';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { PART_OF_O2_TYPE } from '../../utils/dropdownOptions';
import {
    editEvent,
    fetchEvents,
    getEvents,
} from '../../app/reducers/Event/eventSlice';
import { getFeatures } from '../../app/reducers/Features/featuresSlice';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

const CLEventSetup = ({ actionFeatures }) => {
    const { user } = useSelector(getAuth);
    const { committee } = useSelector(getCommittees);
    const { event, loading } = useSelector(getEvents);
    console.log('event', event?.docs);
    const { features } = useSelector(getFeatures);
    const [canShowUpdate, setCanShowUpdate] = useState(true);
    const dispatch = useDispatch();

    const actionsBtn = ['Update'];
    const createFeatureActionsConfig = (actionFeatures, actions) => {
        const config = {};
        actionFeatures?.forEach((ids, index) => {
            config[ids] = actions[index];
        });
        return config;
    };

    const computeActiveFeature = () => {
        const buttonStateSetter = {
            Update: setCanShowUpdate,
        };
        const actionButtonMap = createFeatureActionsConfig(
            actionFeatures,
            actionsBtn
        );
        console.log(' obj action map', actionButtonMap);
        let canShow = true;
        if (!user.isSuperUser && user?.role?.name !== 'Super User') {
            if (features?.docs?.length) {
                features?.docs?.forEach((item) => {
                    if (actionFeatures.includes(item?._id)) {
                        const action = actionButtonMap[item?._id];
                        const status = item?.status;
                        if (buttonStateSetter[action]) {
                            buttonStateSetter[action](status === 'active');
                        }
                    }
                });
            }
        } else {
            Object.values(buttonStateSetter).forEach((btn) => btn(canShow));
        }
    };
    const computeInitialValues = (events) => {
        const initialValues = {
            noOfClSlots: '',
            warOfClEvent: {},
        };
        if (events && events?.length > 0) {
            events?.forEach((evt) => {
                if (evt.warOfClEvent === 'yes' && evt.noOfClSlots !== 0) {
                    initialValues.warOfClEvent[evt.committee._id] = evt._id;
                    initialValues.noOfClSlots = evt.noOfClSlots;
                }
            });
        }
        console.log('initial val', initialValues);
        return initialValues;
    };
    useEffect(() => {
        dispatch(
            fetchCommittees({
                populate: true,
                type: 'Event',
                sort: { name: 1 },
            })
        );
        dispatch(fetchEvents({ populate: true }));
    }, []);

    useEffect(() => {
        computeActiveFeature();
    }, [features]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValues(event?.docs),
        onSubmit: async (values, { resetForm }) => {
            console.log('setup values', values);
            const { noOfClSlots, warOfClEvent } = values;
            const selectedEventIds = Object.values(warOfClEvent);
            console.log('ids are', selectedEventIds);
            const editPromises = event?.docs?.map((ele) => {
                const isSelected = selectedEventIds.includes(ele._id);
                let editObj = {
                    _id: ele._id,
                    warOfClEvent: isSelected ? 'yes' : 'no',
                    noOfClSlots: isSelected ? noOfClSlots : 0,
                };
                console.log('edit', editObj);
                return dispatch(editEvent(editObj));
            });

            try {
                await Promise.all(editPromises);
                toast.success('Events updated successfully!');
                await dispatch(fetchEvents({ populate: true }));
                formik.setValues(computeInitialValues());
            } catch (error) {
                toast.error('Failed to update events.');
            }

            //     Object.values(warOfClEvent).forEach((item)=>{
            //         let editObj={
            //             _id : item,
            //             warOfClEvent : true,
            //             noOfClSlots : noOfClSlots
            //         }
            //         console.log('edit', editObj);
            //         dispatch(editEvent(editObj))
            //     });
            //     // resetForm();
            // await dispatch(fetchEvents({ populate: true }));
        },
    });

    const eventOption = (committeeId) => {
        if (event?.docs) {
            return event?.docs
                ?.filter((ele) => ele?.committee?._id === committeeId)
                .map((item) => ({
                    label: item.name,
                    value: item._id,
                }));
        }
    };

    return loading ? (
        <ClipLoader color="#FFFFFF" size={18} />
    ) : (
        <PageWithCard
            heading={
                <>
                    <Link to={'/committee/event'}>Events</Link>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />
                    </span>
                    <span>{'War of CL'}</span>
                    <span className="text-slate-200 inline-block px-1">
                        <ChevronRight size={14} className="-mb-0.5" />
                    </span>
                    <span>{'Event Setup'}</span>
                </>
            }
        >
            <form
                className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md md:w-1/2"
                onSubmit={formik.handleSubmit}
            >
                {committee?.docs?.map((ele) => (
                    <div
                        className="flex flex-row items-center gap-4"
                        key={ele?._id}
                    >
                        <div className="w-1/3">
                            <FormikLabel label={ele?.name} />
                        </div>
                        <div className="w-2/3">
                            <FormikSelectGroup
                                name={`warOfClEvent.${ele._id}`}
                                formik={formik}
                                options={eventOption(ele?._id)}
                            />
                        </div>
                    </div>
                ))}
                <div className="flex flex-row items-center gap-4">
                    <div className="w-1/3">
                        <FormikLabel label="No of slots" />
                    </div>
                    <div className="w-2/3">
                        <FormikInputGroup name="noOfClSlots" formik={formik} />
                    </div>
                </div>
                {[
                    'Head',
                    'Admin Head',
                    'HOD',
                    'Super User',
                    'Developer',
                ].includes(user?.role?.name) &&
                    canShowUpdate && (
                        <div>
                            <DangerButton type="submit">Update</DangerButton>
                        </div>
                    )}
            </form>
        </PageWithCard>
    );
};

export default CLEventSetup;
