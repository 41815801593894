import React from 'react';
export const ROLES = {
    ALL: [
        'HOD',
        'Head',
        'Sub Head',
        'Scorer',
        'Volunteer',
        'Admin Head',
        'Super User',
        'CL',
        'ACL',
        'Unapproved CL',
        'Developer',
        'NCP Representative'
    ],
};
