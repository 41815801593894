import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import {
    editYear,
    fetchYear,
    fetchYears,
} from '../../app/reducers/Master/yearsSlice';
import { useDispatch } from 'react-redux';

const YearEditModal = ({ yearEditModal, setYearEditModal, requestData }) => {
    const dispatch = useDispatch();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...requestData },
        validationSchema: object({
            name: string()
                .matches(
                    /^[A-Z\s]+$/,
                    'Year Name must contain only uppercase letters (e.g., FYJC, FY DEGREE)'
                )
                .label('Year Name')
                .required(),
        }),
        onSubmit: (values, { resetForm }) => {
            console.log('values', values);
            dispatch(editYear(values)).then((res) => {
                if (res?.payload?.success) {
                    dispatch(fetchYears({sort : {createdAt : 1}}));
                }
            });
            resetForm();
            setYearEditModal(false);
        },
    });
    return (
        <ModalBasic modalOpen={yearEditModal} setModalOpen={setYearEditModal}>
            <form
                className="flex w-full md:w-1/2 flex-col gap-4 bg-black p-4 rounded sm:rounded-md mb-4"
                onSubmit={formik.handleSubmit}
            >
                <FormikInputGroup
                    name="name"
                    label="Year Name"
                    formik={formik}
                />
                <div className="mt-4">
                    <PrimaryButton type="submit">Update</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default YearEditModal;
