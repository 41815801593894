import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { useDispatch, useSelector } from 'react-redux';
import { getYears } from '../../app/reducers/Master/yearsSlice';
import { generateOptions } from '../../utils/Utils';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import {
    editCourse,
    fetchCourses,
} from '../../app/reducers/Master/coursesSlice';

const CourseEditModal = ({
    requestData,
    courseEditModal,
    setCourseEditModal,
}) => {
    const { years } = useSelector(getYears);
    const dispatch = useDispatch();
    const computeInitialValue = (data) => {
        if (data) {
            return {
                ...data,
                years: data.years ? data.years.map((ele) => ele._id) : [],
            };
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(requestData),
        validationSchema: object({
            courseName: string().label('Course Name').required(),
            years: array().label('Years').required(),
        }),
        onSubmit: (values, { resetForm }) => {
            console.log('values', values);
            dispatch(editCourse(values)).then((res) => {
                if (res.payload.success) {
                    dispatch(
                        fetchCourses({
                            populate: true,
                            sort: { courseName: 1 },
                        })
                    );
                }
            });
            resetForm();
            setCourseEditModal(false);
        },
    });
    return (
        <ModalBasic
            modalOpen={courseEditModal}
            setModalOpen={setCourseEditModal}
        >
            <form
                className="flex w-full md:w-1/2 flex-col gap-4 bg-black p-4 rounded sm:rounded-md mb-4"
                onSubmit={formik.handleSubmit}
            >
                <FormikInputGroup
                    name="courseName"
                    label="Course Name"
                    formik={formik}
                />
                <FormikMultiSelect
                    name="years"
                    label="Years"
                    formik={formik}
                    options={generateOptions({
                        array: years?.docs ? years?.docs : [],
                        valueField: '_id',
                        labelField: 'name',
                    })}
                />

                <div className="mt-4">
                    <PrimaryButton type="submit">Update</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default CourseEditModal;
