import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { injectStore } from './utils/axiosConfig';
import 'react-phone-input-2/lib/style.css';
import './css/global.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
injectStore(store);

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Provider store={store}>
                <ToastContainer />
                <App />
            </Provider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
