import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { array, number, object, string } from 'yup';
import { createUser, getUsers } from '../../app/reducers/Users/usersSlice';
import FormikFileInput from '../../components/formik/FormikFileInput';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import { createCommittee } from '../../app/reducers/Committee/committeeSlice';
import { COMMITTEE_TYPE } from '../../utils/dropdownOptions';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';
import { toast } from 'react-toastify';
import { generateOptions } from '../../utils/Utils';
import { Title } from 'chart.js';
import { createEvent } from '../../app/reducers/Event/eventSlice';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const AddCommittee = () => {
    const { user} =useSelector(getAuth);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            name: '',
            type: '',
            hexCode: '',
            numberOfEvents: '',
            members: [],
        },
        validationSchema: object({
            name: string().required(),
            type: string().required(),
            hexCode: string(),
            numberOfEvents: number(),
            members: array(),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('committee', values);
            const isEvent = values?.numberOfEvents == '';
            if (values?.type == 'Event') {
                if (isEvent) {
                    return toast.error('Fill the No. of Events field');
                }
            }
            if (values?.members?.length > 0) {
                const isSeat = values?.members?.some((ele) => ele.seats);
                if (!isSeat) {
                    return toast.error('Fill the seats field');
                }
                const resp = await dispatch(createCommittee(values));
                const newEventData = resp?.payload?.data;
                if (!isEvent) {
                    for (let i = 1; i <= values?.numberOfEvents; i++) {
                        const newEventObj = {
                            name: `${values?.name} ${i}`,
                            committee: newEventData?._id,
                            order : `${10 + i}`
                        };
                        dispatch(createEvent(newEventObj));
                    }
                }
                resetForm();
            } else {
                const resp = await dispatch(createCommittee(values));
                const newEventData = resp?.payload?.data;
                if (!isEvent) {
                    for (let i = 1; i <= values?.numberOfEvents; i++) {
                        const newEventObj = {
                            name: `Event ${i}`,
                            committee: newEventData?._id,
                            order : `${10 + i}`,
                        };
                        dispatch(createEvent(newEventObj));
                    }
                }
                resetForm();
            }
        },
    });

    const { role, loading } = useSelector(getRoles);
    const data = useMemo(() => (role?.docs ? role.docs : []), [role]);
    useEffect(() => {
        dispatch(fetchRoles({ type: 'Internal', sort: { name: 1 } }));
    }, []);

    return (
        <PageWithCard heading="Add Committee">
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4  bg-black p-4 rounded sm:rounded-md"
            >
                <FormikInputGroup
                    name="name"
                    label="Committee Name"
                    formik={formik}
                    required
                />
                <FormikSelectGroup
                    name="type"
                    label="Type"
                    formik={formik}
                    options={COMMITTEE_TYPE}
                />
                <FormikInputGroup
                    name="hexCode"
                    label="Color Code"
                    formik={formik}
                    // required
                />
                {formik.values.type == 'Event' ? (
                    <FormikInputGroup
                        name="numberOfEvents"
                        label="No. of Events"
                        formik={formik}
                    />
                ) : (
                    ''
                )}
                {/* { formik.values.type == "Event" ?
                    (<FormikInputGroup
                    name = 'scorer'
                    label = 'Scorer'
                    formik = { formik }
                    // options={generateOptions({
                    //     array : [],
                    //     labelField : 'name',
                    //     valueField : '_id'
                    // })}
                />) : ''
                } */}
                <FormikProvider value={formik}>
                    <div className="text-bold text-slate-200">Members</div>
                    <FieldArray
                        name="members"
                        render={(arrayHelpers) => {
                            return (
                                <div className="flex flex-col gap-2">
                                    {/* <p>Address</p> */}
                                    <div>
                                        {formik.values.members.map(
                                            (ele, index) => (
                                                <div
                                                    className="relative p-4 mb-2"
                                                    style={{
                                                        border: '1px solid #d6c7c7',
                                                        borderRadius: '5px',
                                                    }}
                                                    key={index}
                                                >
                                                    <div className="flex gap-2 ">
                                                        <FormikSelectGroup
                                                            name={`members.${index}.role`}
                                                            label="Role"
                                                            formik={formik}
                                                            options={
                                                                data?.map
                                                                    ? data?.map(
                                                                          (
                                                                              ele
                                                                          ) => ({
                                                                              label: ele.name,
                                                                              value: ele._id,
                                                                          })
                                                                      )
                                                                    : []
                                                            }
                                                        />
                                                        <FormikInputGroup
                                                            type="number"
                                                            name={`members.${index}.seats`}
                                                            label="Seats"
                                                            formik={formik}
                                                        />
                                                    </div>
                                                    <div>
                                                        <DangerButton
                                                            className="mt-3"
                                                            onClick={() => {
                                                                arrayHelpers.remove(
                                                                    index
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            Remove
                                                        </DangerButton>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div>
                                        <PrimaryButton
                                            onClick={() => {
                                                arrayHelpers.push({});
                                            }}
                                            type="button"
                                        >
                                            Add More
                                        </PrimaryButton>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </FormikProvider>
                {['Super User', 'Developer'].includes(user?.role?.name) && (
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                )}
            </form>
        </PageWithCard>
    );
};

export default AddCommittee;
