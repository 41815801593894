import React from 'react'
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic'

const DetailsModal = ({
    requestData,
    showDetailsModal,
    setShowDetailsModal
}) => {
    console.log('det data', requestData )
  return (
    <ModalBasic
        modalOpen={showDetailsModal}
        setModalOpen={setShowDetailsModal}
    >
        <div className=' p-4'>
                <h1 className='text-red-500 text-xl md:text-3xl mb-2 md:mb-4 font-semibold' >Event Details</h1>
                <pre className='mb-2 md:mb-4 text-slate-200 text-xs md:text-xl font-poppins whitespace-pre-wrap'>
                    { requestData ? requestData?.rules : ''}
                </pre>
                <div className='flex flex-col md:flex-row gap-2 md:gap-4 mb-2 md:mb-4'>
                    <p className='text-red-500 text-xs md:text-xl font-medium'>Min Participants :{" "}<span className='text-slate-200'>{ requestData?.teamMinCapacity}</span> </p>
                    <p className='text-red-500 text-xs md:text-xl font-medium'>Max Participants :{" "}<span className='text-slate-200'>{ requestData?.teamMaxCapacity}</span> </p>
                    <p className='text-red-500 text-xs md:text-xl font-medium'>Podium :{" "}<span className='text-slate-200'>{requestData?.podium}</span></p>
                </div>
                <div className='flex flex-col md:flex-row gap-2 md:gap-4 mb-2 md:mb-4'>
                <p className='text-red-500 text-xs md:text-xl font-medium'>Level :{" "}<span className='text-slate-200'>{ requestData?.level}</span> </p>
                </div>
                
            </div>
      
    </ModalBasic>
  )
}

export default DetailsModal
