import React from 'react';
import PageWithCard from '../components/infrastructure/PageWithCard';
import PrimaryButton from '../components/infrastructure/Buttons/PrimaryButton';
import { useNavigate } from 'react-router';

const EventNotFound = () => {
    const navigate = useNavigate();
    return (
        <>
            <h1 className='text-red-500 text-3xl'>Event not found for this Committee</h1>
            <div className="mt-4">
                <PrimaryButton onClick={() => navigate('/committee/event')}>
                    Back To Events
                </PrimaryButton>
            </div>
        </>
    );
};

export default EventNotFound;
