import { createSlice } from "@reduxjs/toolkit";
import { createData, deleteData, editData, fetchData, fetchEditData } from "../../generators/generateThunks";
import { generateStateSingle } from "../../generators/generateState";
import { resetData } from "../../generators/generateReducers";


const SLICE_NAME = 'event';

export const createEvent = createData.generateThunk(SLICE_NAME, SLICE_NAME);

export const fetchEvents = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);

export const editEvent = editData.generateThunk(SLICE_NAME, SLICE_NAME, {noToast: true});

export const fetchEvent = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);

export const deleteEvent = deleteData.generateThunk(SLICE_NAME, SLICE_NAME)


const eventSlice = createSlice({
    name : SLICE_NAME,
    initialState : generateStateSingle(SLICE_NAME),
    extraReducers : {
        ...createData.generateExtraReducers(createEvent, SLICE_NAME),
        ...fetchData.generateExtraReducers(fetchEvents, SLICE_NAME),
        ...editData.generateExtraReducers(editEvent, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchEvent, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteEvent, SLICE_NAME)
    }
})

export const getEvents = (state) =>  state.event;
export default eventSlice.reducer;