// import Image from "next/image";
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import ExpandableMenuLinks from "./ExpandableMenuLinks";
import NavLinks from "./NavLinks";
import { useDispatch, useSelector } from 'react-redux';
import { fetchFeatures, getFeatures } from '../../app/reducers/Features/featuresSlice';
import MobileLogo from '../../images/O2 White No Diamond v1.png';

function MobileNav({ open, setOpen, feature, ...props }) {
  console.log('...props', {feature})
  const links = props?.props[0]?.links;
  const logo = props?.props[1]?.logo;
  // console.log('data', links)
  // console.log('data1', logo)
  return (
    <div
      className={`absolute top-0 z-50 left-0 h-screen overflow-y-auto w-screen bg-black transform ${
        open ? "-translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out filter drop-shadow-md fixed`}
    >
      <div className="flex items-center justify-center filter drop-shadow-md  h-[70px] p-4 bg-black ">
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: 10,
          }}
        >
          <div className='w-[35px] h-[35px]'>
            <img
              alt="Mountains"
              // src={logo?.url}
              src={MobileLogo}
              className='object-contain w-full'
            />
          </div>
        </div>
      </div>
      <div className=" relative flex flex-col ml-4 mt-4">
        {links?.map((link) => ( (link?.featureId === "" || feature[link?.featureId] === 'active') &&
          <NavLinks
            link={link}
            setOpenNavbar={setOpen}
            openNavbar={open}
            key={link._id}
            onClick={() =>
              setTimeout(() => {
                setOpen(!open);
              }, 100)
            }
          />
        ))}
        {/* <div className="mt-3">
          <a
            target={button?.target === "new_page" ? "_blank" : "_self"}
            href={button?.href ? button.href : "#!"}
          >
            <PrimaryButton type="button">{button?.title}</PrimaryButton>
          </a>
        </div> */}
        {/* <div>
          <Link href="/become-a-sponsor" passHref>
            <a>
              <PrimaryButton>Become a Sponsor</PrimaryButton>
            </a>
          </Link>
        </div> */}
      </div>
    </div>
  );
}
// expandable menu code
function ExpandableMenu({ openExpMenu, setOpenExpMenu, ...props }) {
  // const { logo, links } = props;
  const links = props?.props[0]?.links;
  const logo = props?.props[1]?.logo;

  useEffect(() => {
    for (let i = 0; i < links.length; i++) {
      if (links[i].links?.length) {
        if (links[i].links?.length) setCurrentItemOpen(links[i]._id);
        break;
      }
    }
  }, [links]);

  const [currentItemOpen, setCurrentItemOpen] = useState("");
  return (
    <div
      className={`absolute top-0 z-50 left-0 h-screen w-screen bg-black transform ${
        openExpMenu ? "-translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out filter drop-shadow-md `}
    >
      <div className="flex items-center justify-center filter drop-shadow-md bg-black h-[70px] p-4">
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            zIndex: "10",
          }}
        >
          <div className='w-[35px] h-[35px]'>
          
          <img
            alt="Mountains"
            src={MobileLogo}
            // src={logo?.url}
            className='object-contain w-full'
          />
          </div>
        </div>
      </div>
      <div className="flex flex-col ml-20 divide-y-2 mt-4 w-[20%]">
        {links?.map((link) => (
          <ExpandableMenuLinks
            link={link}
            setOpenExpMenu={setOpenExpMenu}
            openExpMenu={openExpMenu}
            currentItemOpen={currentItemOpen}
            setCurrentItemOpen={setCurrentItemOpen}
            key={link._id}
            onClick={() =>
              setTimeout(() => {
                setOpenExpMenu(!openExpMenu);
              }, 100)
            }
          />
        ))}
      </div>
    </div>
  );
}

export default function NavbarLeft(props) {

  const { features } =useSelector(getFeatures);
  console.log('feature', features)
  console.log('navbar props', props)
  const links = props?.props[0]?.links;
  // console.log('navbarLinks', links)
  const logo = props?.props[1]?.logo;
  const [open, setOpen] = useState(false);
  const [openExpMenu, setOpenExpMenu] = useState(false);
  
  const dispatch = useDispatch();
  const feature = useMemo(()=>{
    const featureData = {};
    if(features?.docs?.length){
      features.docs.forEach((ele) => {
        if (ele?._id && ele?.status) {
          featureData[ele._id] = ele.status;
        }
      });
      
    } 
    return featureData
  },[features])
  console.log('open stat', open)
  useEffect(()=>{
    dispatch(fetchFeatures())
  },[])
  return (
    // <ResponsiveDisplay show_in_desktop={props.show_in_desktop} show_in_mobile={props.show_in_mobile}>
    <nav className="flex fixed filter drop-shadow-md bg-black px-4 py-4 h-[50px] items-center w-full z-50">
      <MobileNav open={open} setOpen={setOpen} feature={feature} {...props} />
      <ExpandableMenu
        openExpMenu={openExpMenu}
        setOpenExpMenu={setOpenExpMenu}
        {...props}
      />
      <div className="w-1/2 h-[70px] lg:w-3/12 flex items-center lg:hidden ">
        <Link to="https://o2thefest.com/" passHref>
          <a
            className="w-full xl:h-[80%]"
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              zIndex: "10",
            }}
          >
            <div className='w-[35px] h-[35px] pt-1'>
              <img
                alt="Mountains"
                src={MobileLogo}
                // src={logo?.url}
                className='object-contain w-full'
              />
            </div>
          </a>
        </Link>
      </div>
      <div className="w-9/12 md:w-full flex justify-center items-center">
        <div className=" h-full gap-4 hidden lg:flex lg:justify-center lg:items-center mx-4 lg:gap-3 xl:gap-4" >
          { links?.map((link, index) => ( (link?.featureId === "" || feature[link?.featureId] === 'active') &&
            <NavLinks
              setOpenNavbar={setOpen}
              openNavbar={open}
              link={link}
              key={index}
            />
          ))}

        </div>
      </div>
      <div className="flex justify-end ml-4 mr-2 lg:hidden">
        <div
          className="z-50 flex relative w-6 h-6 flex-col justify-between items-center lg:hidden"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <span
            className={`h-1 w-full bg-white rounded-lg transform transition duration-300 ease-in-out ${
              open ? "rotate-45 translate-y-2.5" : ""
            }`}
          />
          <span
            className={`h-1 w-full bg-white rounded-lg transition-all duration-300 ease-in-out ${
              open ? "w-0" : "w-full"
            }`}
          />
          <span
            className={`h-1 w-full bg-white rounded-lg transform transition duration-300 ease-in-out ${
              open ? "-rotate-45 -translate-y-2.5" : ""
            }`}
          />
        </div>
      </div>
    </nav>
    // </ResponsiveDisplay>
  );
}
