import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { generateOptions } from '../../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    editCommittee,
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { useFormik } from 'formik';
import { editShortlistRegistrationRequest } from '../../app/reducers/RegistrationRequest/registrationRequestSlice';
import {
    editEmployee,
    fetchEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import { getRoles } from '../../app/reducers/Role/roleSlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
const computeInitialValue = (data) => {
    let final = data;
    if (data?.committee?.[0]?._id) {
        final = { ...final, committee: data?.committee[0]._id };
    }
    if (data?.role?._id) {
        final = { ...final, role: data?.role?._id };
    }
    return final;
};
const EditApprovedVolunteer = ({
    filterValues,
    requestData,
    showApproveModal,
    setShowApproveModal,
}) => {
    console.log('req.data transfer', requestData);
    const { committee } = useSelector(getCommittees);
    const { role } = useSelector(getRoles);
    const dispatch = useDispatch();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...computeInitialValue(requestData),
        },
        onSubmit: async (values) => {
            console.log('edit app vol', values);
            let canSubmit = false;
            // let assignCommittee = requestData && requestData?.committee[0];

            const roleOptions = committee?.docs?.find(
                (ele) => ele._id === values?.committee
            );
            console.log('roleOption', roleOptions);
            const seatAvailable = roleOptions?.members?.find(
                (ele) => ele.role?._id === values?.role
            );
            console.log('seats', seatAvailable.seats);
            const payload = {
                role: values?.role,
                committee: values?.committee,
            };

            const string = QueryString.stringify(payload);
            const allUsers = await authAxiosInstance.get(
                `/users/employee?${string}`
            );
            const userGet = allUsers?.data?.data?.docs;
            console.log('userGet', userGet);
            if (seatAvailable?.seats > userGet.length) {
                canSubmit = true;
                console.log('canSubmitTrue', canSubmit);
            } else {
                canSubmit = false;
                console.log('canSubmitFalse', canSubmit);
                toast.error(`Seats are not available for Volunteer Role`);
                return;
            }
            if (canSubmit) {
                const payload = { _id: requestData?.committee[0]?._id };
                const stringData = QueryString.stringify(payload);
                const resp = await authAxiosInstance.get(
                    `/committee?${stringData}`
                );
                // console.log('resp', resp?.data?.data?.docs[0])
                const committeeObj = resp?.data?.data?.docs[0];
                if (
                    committeeObj?.scorer &&
                    committeeObj?.scorer === requestData?._id
                ) {
                    const confirm = window.confirm(
                        'Selected user is scorer, Are sure you want to transfer ?'
                    )
                    if(confirm){
                    console.log('user is scorer')
                    const { scorer, ...rest } = committeeObj;
                    const editObj = {
                        ...rest,
                        scorer : ''
                    };
                    console.log('edited obj', editObj)
                    await dispatch(editCommittee(editObj));
                    }
                }
                let obj = {
                    _id: values?._id,
                    committee: [values?.committee],
                };
                // console.log('obj', obj);
                await dispatch(editEmployee(obj));
                if (filterValues?.committee?.length) {
                    await dispatch(
                        fetchEmployees({
                            populate: true,
                            'role.name': 'Volunteer',
                            'committee._id': {
                                $in: [requestData?.committee[0]?._id],
                            },
                            sort: { 'committee.name': 1, firstName: 1 },
                        })
                    );
                } else {
                    await dispatch(
                        fetchEmployees({
                            populate: true,
                            'role.name': 'Volunteer',
                            sort: { 'committee.name': 1, firstName: 1 },
                        })
                    );
                }

                setShowApproveModal(false);
            }
        },
    });
    return (
        <ModalBasic
            modalOpen={showApproveModal}
            setModalOpen={setShowApproveModal}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 p-4"
            >
                <FormikInputGroup
                    label="First Name"
                    name="firstName"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="Last Name"
                    name="lastName"
                    formik={formik}
                    readOnly
                />
                <FormikSelectGroup
                    required
                    formik={formik}
                    label="Committee"
                    name="committee"
                    options={generateOptions({
                        array: committee ? committee?.docs : [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                />
                <FormikSelectGroup
                    formik={formik}
                    isDisabled
                    label="Role"
                    name="role"
                    options={generateOptions({
                        array: role ? role?.docs : [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                />
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default EditApprovedVolunteer;
