import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikTime from '../../components/formik/FormikTime';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { editEvent, fetchEvents } from '../../app/reducers/Event/eventSlice';
import { toast } from 'react-toastify';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const AddEventSchedule = ({
    requestData,
    showApproveModal,
    setShowApproveModal,
    setRequestData
}) => {
    // console.log('req date', requestData)
    const {user} = useSelector(getAuth);
    const dispatch = useDispatch();
    const convertCustomTimeToDate = (selectedDate, customTime) => {
        const milliseconds = parseInt(selectedDate, 10);
        const date = new Date(milliseconds);
        const formattedDateString = date.toString();
        console.log('values', formattedDateString);
        console.log('values1', customTime);
        const timeString = customTime.split(' ')[4];

        const [hours, minutes, seconds] = timeString.split(':').map(Number);

        const newDate = new Date(formattedDateString);

        newDate.setHours(hours);
        newDate.setMinutes(minutes);
        newDate.setSeconds(seconds);

        return newDate;
    };
    const formik = useFormik({
        initialValues: {
            date: moment().startOf('day').valueOf(),
            startTime: new Date(),
            endTime: new Date(new Date().setHours(new Date().getHours() + 1)),
            venue: '',
        },
        validationSchema: object({
            date: string().required(),
            startTime: string().required(),
            endTime: string().required(),
            venue: string().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('schedule', values);
            const currentDate = new Date();
            const selectedDate = new Date(values?.date);
            const currentTime =
                currentDate.getHours() +
                ':' +
                String(currentDate.getMinutes()).padStart(2, '0');

            let newStartTime = convertCustomTimeToDate(
                values?.date,
                values?.startTime.toString()
            );
            let newEndTime = convertCustomTimeToDate(
                values?.date,
                values?.endTime.toString()
            );
            const selectedStartTime =
                newStartTime.getHours() +
                ':' +
                String(currentDate.getMinutes()).padStart(2, '0');

            if (
                selectedDate.toDateString() === currentDate.toDateString() &&
                selectedStartTime < currentTime
            ) {
                toast.error(
                    'Cannot schedule event before the current time on the same day!'
                );
                return;
            }
            if (newEndTime <= newStartTime) {
                toast.error('End-Time must be after the start-Time');
                return;
            }
            let scheduleEventObj = {
                ...requestData,
                status: 'scheduled',
                startTime: newStartTime,
                endTime: newEndTime,
                venue: values?.venue,
            };
            console.log('eventScheduleObj', scheduleEventObj);
            await dispatch(editEvent(scheduleEventObj));
            dispatch(fetchEvents({ populate: true, sort: { startTime: 1 } }));
            setShowApproveModal(false);
        },
    });
    useEffect(()=>{
        if(!showApproveModal){
            setRequestData({})
        }
    },[showApproveModal])
    return (
        <ModalBasic
            modalOpen={showApproveModal}
            setModalOpen={setShowApproveModal}
        >
            <form
                className="flex flex-col gap-4 bg-black p-4 rounded sm:rounded-md w-1/2"
                onSubmit={formik.handleSubmit}
            >
                <FormikInputDateGroup
                    required
                    formik={formik}
                    label="Date"
                    name="date"
                />
                <FormikTime
                    required
                    formik={formik}
                    showTimeInput
                    label="Start Time"
                    name="startTime"
                />
                <FormikTime
                    required
                    formik={formik}
                    showTimeInput
                    label="End Time"
                    name="endTime"
                />
                <FormikInputGroup
                    required
                    formik={formik}
                    label="Venue"
                    name="venue"
                />
                {['Admin Head', 'Developer', 'Super User', 'HOD'].includes(
                    user?.role?.name
                ) && (
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                )}
            </form>
        </ModalBasic>
    );
};

export default AddEventSchedule;
