import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Edit2, Trash } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import { useNavigate } from 'react-router-dom';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import {
    deleteRole,
    fetchRoles,
    getRoles,
} from '../../app/reducers/Role/roleSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';

const ViewRole = () => {
    const { role, loading } = useSelector(getRoles);
    const { baseUrl } = useSelector(getMediaLibrary);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            console.log('searching');
            dispatch(fetchRoles({ search: search, populate: true }));
        }, 300),
        []
    );
    const [page, setPage] = useState(1);

    const cols = [
        {
            Header: 'System ID',
            accessor: '_id',
        },
        {
            Header: 'Role Name',
            Cell: ({ row }) => {
                return <span>{row.original.name}</span>;
            },
        },
        {
            Header: 'type',
            accessor: 'type',
        },

        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 items-center cursor-pointer">
                        <PrimaryButton
                            onClick={async () => {
                                navigate(`/role/edit/${row.original._id}`);
                            }}
                            color="blue"
                        >
                            Edit
                        </PrimaryButton>
                        <DangerButton
                            onClick={async () => {
                                await dispatch(
                                    deleteRole({ id: row.original._id })
                                );
                                dispatch(
                                    fetchRoles({
                                        page,
                                        populate: true,
                                        sort: { type: 1 },
                                    })
                                );
                            }}
                            color="red"
                        >
                            Delete
                        </DangerButton>
                    </div>
                );
            },
        },
    ];
    const data = useMemo(() => (role?.docs ? role.docs : []), [role]);
    useEffect(() => {
        dispatch(fetchRoles({ page, populate: true, sort: { type: 1 } }));
    }, [page]);
    return (
        <PageWithCard heading="View Roles">
            <TableWithHeadingAndGlobalSearch
                loading={loading}
                data={data}
                searchFunction={debouncedSearch}
                columns={cols}
            />
            <PaginationClassic setPage={setPage} paginationDetails={role} />
        </PageWithCard>
    );
};

export default ViewRole;
