import React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { ColumnGroup } from 'rc-table';

const computeValue = (options, val) => {
    const resp = options.find((option) => option.value === val);

    return resp ? resp : null;
};
const customStyles = {
    control: (base, {isDisabled}) => ({
      ...base,
      background: "#141414",
      background : isDisabled ? '#6C6C6C' : "#141414",
    }),
    menu: (base, state) => ({
      ...base,
      marginTop: 0,
      background: "#141414",
      color : '#ffffff',
      border : '0.5px solid #475569',
      zIndex: 9999,
      position: 'absolute',
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...base,
          backgroundColor: isFocused ? "black" : "#141414",
        };
    },
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
const FormikSelectGroup = ({
    formik,
    label = '',
    required = false,
    options,
    name,
    refetch = false,
    ignoreDotInName = false,
    ...props
}) => {
    const onChange = props?.onChange
        ? props.onChange
        : (selectedOption) => {
              formik.setFieldValue(name, selectedOption.value);
          };
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className="w-full"
        >
            <div className="flex items-center gap-2  mb-1">
                <label className="block text-sm font-medium text-slate-200" htmlFor={name}>
                    {label}{' '}
                    {required && <span className="text-rose-500">*</span>}
                </label>
                {refetch && (
                    <p onClick={refetch} className="text-xs underline">
                        Refetch
                    </p>
                )}
            </div>
            <Select
                name={name}
                value={computeValue(options, _.at(formik.values, name)[0])}
                options={options}
                onBlur={formik.handleBlur}
                onChange={onChange}
                className='text-slate-200 border-0 rounded-md'
                styles={customStyles}
                classNamePrefix="react-select"
                menuPortalTarget={document.body} 
                menuPosition="absolute"
                menuPlacement="auto"
                {...props}
            />
            {formik?.errors?.[name] && formik?.touched?.[name] ? (
                <p className="text-xs text-red-500">{formik.errors[name]}</p>
            ) : null}
        </div>
    );
};

export default FormikSelectGroup;
