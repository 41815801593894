import { createSlice } from "@reduxjs/toolkit"
import { generateStateSingle } from "../../generators/generateState";
import { createData, deleteData, editData, fetchData, fetchEditData } from "../../generators/generateThunks";

const SLICE_NAME = 'years';

export const createYear = createData.generateThunk(SLICE_NAME, SLICE_NAME);

export const fetchYears = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);

export const fetchYear = fetchEditData.generateThunk(SLICE_NAME. SLICE_NAME);

export const editYear = editData.generateThunk(SLICE_NAME,SLICE_NAME);

export const deleteYear = deleteData.generateThunk(SLICE_NAME,SLICE_NAME);

const yearsSlice = createSlice({
    name : SLICE_NAME,
    initialState : generateStateSingle(SLICE_NAME),
    extraReducers :{
        ...createData.generateExtraReducers(createYear,SLICE_NAME),
        ...fetchData.generateExtraReducers(fetchYears,SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchYear,SLICE_NAME),
        ...editData.generateExtraReducers(editYear,SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteYear,SLICE_NAME),
    }
});
export const getYears= (state) => state.years;
export default yearsSlice.reducer;