import { createSlice } from "@reduxjs/toolkit";
import { createData, editData, fetchData, fetchEditData } from "../../generators/generateThunks";
import { generateStateSingle } from "../../generators/generateState";

const SLICE_NAME = 'scoringTemplate';

export const createScoringTemplate = createData.generateThunk(SLICE_NAME, SLICE_NAME);

export const fetchScoringTemplates = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);

export const editScoringTemplate = editData.generateThunk(SLICE_NAME, SLICE_NAME);

export const fetchScoringTemplate = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);

const scoringTemplateSlice = createSlice({
    name : SLICE_NAME,
    initialState : generateStateSingle(SLICE_NAME),
    extraReducers : {
        ...createData.generateExtraReducers(createScoringTemplate, SLICE_NAME),
        ...fetchData.generateExtraReducers(fetchScoringTemplates, SLICE_NAME),
        ...editData.generateExtraReducers(editScoringTemplate, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchScoringTemplate, SLICE_NAME),
    }
});

export const getScoringTemplates = (state) => state.scoringTemplate;

export default scoringTemplateSlice.reducer;