import React, { useEffect, useMemo, useState } from 'react'
import PageWithCard from '../../components/infrastructure/PageWithCard'
import { useDispatch, useSelector } from 'react-redux'
import { fetchScorecards, getScorecards } from '../../app/reducers/Scorecard/scorecardSlice'
import TableWithHeading from '../../components/Table/TableWithHeading'
import DangerButton from '../../components/infrastructure/Buttons/DangerButton'
import AdjustmentModal from './AdjustmentModal'

const ViewScorecard = () => {
    const { scorecard } = useSelector(getScorecards);
    console.log('scorecard', scorecard?.docs);
    const [requestData,setRequestData] = useState();
    const [showAdjustmentModal,setShowAdjustmentModal] = useState(false);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(fetchScorecards({status : 'uploaded', populate : true}))
    },[]);

    const scorecardCols =[
        {
            Header : 'Committee',
            Cell : ({row})=>{
                return (
                    <span>{row?.original?.committee?.name}</span>
                )
            },
            cellStyle:{
                width : '30%'
            }
        },
        {
            Header : 'Event',
            Cell : ({row})=>{
                return (
                    <span>{row?.original?.event?.name}</span>
                )
            },
            cellStyle:{
                width : '30%'
            }
        },
        {
            Header : ' ',
            Cell : ({row})=>{
                return (
                    <div className='flex flex-row items-center gap-2'>
                        <DangerButton
                            onClick={async (e) => {
                                e.stopPropagation();
                                setRequestData(row?.original);
                                setShowAdjustmentModal(true);
                            }}
                        >
                            Adjustment
                        </DangerButton>
                    </div>
                )
            },
            cellStyle:{
                width : '40%'
            }
        },
    ];

    const scorecardMemo = useMemo(
        ()=>(scorecard?.docs ? scorecard?.docs : []),[scorecard]
    )
  return (
    <PageWithCard heading='Scorecard Adjustment'>
        <AdjustmentModal 
            requestData={requestData}   
            showAdjustmentModal={showAdjustmentModal} 
            setShowAdjustmentModal={setShowAdjustmentModal}    
        />
        <TableWithHeading 
            columns={scorecardCols}
            data={scorecardMemo}
        />
      
    </PageWithCard>
  )
}

export default ViewScorecard
