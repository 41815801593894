import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
    editEmployee,
    fetchEmployee,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { array, mixed, object, string } from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import {
    editFestival,
    fetchFestival,
    getFestivals,
} from '../../app/reducers/Festival/festivalSlice';
import {
    fetchColleges,
    getColleges,
} from '../../app/reducers/College/collegeSlice';
import { generateOptions } from '../../utils/Utils';

const EditFestival = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { elementEditData, elementEditDataLoading } =
        useSelector(getFestivals);
    const { college, loading } = useSelector(getColleges);
    useEffect(() => {
        dispatch(fetchFestival({ _id: id }));
        dispatch(fetchColleges({ limit: 300 }));
    }, []);
    const collegeData = useMemo(
        () => (college?.docs ? college.docs : []),
        [college]
    );
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...elementEditData,
        },
        validationSchema: object({
            name: string().required(),
            startDate: mixed().required(),
            endDate: mixed().required(),
            status: string().required(),
            college: string().required(),
            photos: array(),
        }),
        onSubmit: (values, { resetForm }) => {
            dispatch(editFestival(values));
        },
    });
    return (
        <PageWithCard heading="Edit Festival">
            {elementEditDataLoading ? (
                <ClipLoader color="#FFFFFF" size={18} />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4"
                >
                    <FormikInputGroup
                        name="name"
                        label="Festival Name"
                        formik={formik}
                        required
                    />
                    <FormikInputDateGroup
                        name="startDate"
                        label="Start Date"
                        formik={formik}
                        required
                    />
                    <FormikInputDateGroup
                        name="endDate"
                        label="End Date"
                        formik={formik}
                        required
                    />
                    {loading ? (
                        <ClipLoader color="#FFFFFF" size={18} />
                    ) : (
                        <FormikSelectGroup
                            name="college"
                            label="College"
                            formik={formik}
                            options={generateOptions({
                                array: collegeData,
                                valueField: '_id',
                                labelField: 'name',
                            })}
                        />
                    )}
                    <FormikProvider value={formik}>
                        <div className="text-bold">Photos</div>
                        <FieldArray
                            name="photos"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col gap-2">
                                        {/* <p>Address</p> */}
                                        <div>
                                            {formik.values?.photos?.map(
                                                (ele, index) => (
                                                    <div
                                                        className="relative p-4 mb-2"
                                                        style={{
                                                            border: '1px solid #d6c7c7',
                                                            borderRadius: '5px',
                                                        }}
                                                        key={index}
                                                    >
                                                        <FormikDirectFileUpload
                                                            name={`photos.${index}`}
                                                            formik={formik}
                                                            randomize={true}
                                                            location={`1648_entertainment/festivals/`}
                                                        />
                                                        <div>
                                                            <DangerButton
                                                                className="mt-3"
                                                                onClick={() => {
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    );
                                                                }}
                                                                type="button"
                                                            >
                                                                Remove
                                                            </DangerButton>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div>
                                            <SecondaryButton
                                                onClick={() => {
                                                    arrayHelpers.push('');
                                                }}
                                                type="button"
                                            >
                                                Add More
                                            </SecondaryButton>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>
                    <FormikCheckbox
                        name="status"
                        label="Status"
                        formik={formik}
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </PageWithCard>
    );
};

export default EditFestival;
