import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useFormik } from 'formik';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import {
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';
import { generateOptions } from '../../utils/Utils';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import { editEmployee, fetchEmployees } from '../../app/reducers/Users/EmployeeSlice';
import { useParams } from 'react-router-dom';

const PromoteApprovedVolunteerVolunteer = ({
    filterValues,
    requestData,
    showApprovedModal,
    setShowApprovedModal,
}) => {
    console.log('reqData',requestData)
    const { id }  = useParams();
    const { committee } = useSelector(getCommittees);
    const { role } = useSelector(getRoles);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCommittees({populate:true,sort :{name:1}}));
        dispatch(fetchRoles({type : 'Internal',populate:true,sort :{name:1}}));
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            userId: requestData && requestData?._id,
            firstName: requestData && requestData?.firstName,
            lastName: requestData && requestData?.lastName,
            assignCommittee:
                requestData && requestData?.committee?.map((ele) => ele.name),
            assignRole: requestData && requestData?.role?.name,
            role: '',
            seats: '',
        },
        onSubmit: async (values) => {
            console.log('Promote submit', values);
            let canSubmit = false;
            let assignCommittee = requestData && requestData?.committee[0];

            const roleOptions = committee?.docs?.find(
                (ele) => ele._id === assignCommittee._id
            );
            console.log('roleOption', roleOptions)
            const seatAvailable = roleOptions?.members?.find(
                (ele) => ele.role?._id === values.role
            );
            console.log('seats', seatAvailable.seats);
            const payload = {
                role: values?.role,
                committee: assignCommittee._id,
            };

            const string = QueryString.stringify(payload);
            const allUsers = await authAxiosInstance.get(
                `/users/employee?${string}`
            );
            const userGet = allUsers?.data?.data?.docs;
            console.log('userGet', userGet)
            if (seatAvailable.seats > userGet.length) {
                canSubmit = true;
                console.log('canSubmitTrue', canSubmit);
            } else {
                canSubmit = false;
                console.log('canSubmitFalse', canSubmit);
                toast.error(`Seats are not available for selected role`);
                return;
            }
            if (canSubmit) {
                const obj = {
                    _id: values?.userId,
                    isApproved : "pending",
                    requestedRole : values?.role,
                };
                console.log('obj', obj);
                await dispatch(editEmployee(obj));
                // resetForm();
                if(filterValues?.committee?.length){
                    await dispatch(
                        fetchEmployees({
                            populate: true,
                            'role.name': 'Volunteer',
                            'committee._id': {$in : [ requestData?.committee[0]?._id ]},
                            sort : {'committee.name' : 1, firstName : 1}
                        })
                    );
                }
                else{
                    await dispatch(
                        fetchEmployees({
                            populate: true,
                            'role.name': 'Volunteer',
                            sort : {'committee.name' : 1, firstName : 1}
                        })
                    );
                }
                setShowApprovedModal(false);
               
            }
        },
    });
    return (
        <ModalBasic
            modalOpen={showApprovedModal}
            setModalOpen={setShowApprovedModal}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 p-4"
            >
                <FormikInputGroup
                    label="User ID"
                    name="userId"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="First Name"
                    name="firstName"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="Last Name"
                    name="lastName"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="Assign Committee"
                    name="assignCommittee"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="Assign Role"
                    name="assignRole"
                    formik={formik}
                    readOnly
                />
                <FormikSelectGroup
                    formik={formik}
                    label="Promoted to Role"
                    name="role"
                    options={generateOptions({
                        array: role
                            ? role?.docs?.filter(
                                  (ele) => !['Super User', 'Developer'].includes(ele?.name)
                              )
                            : [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                    onChange={async (selectedOption) => {
                        const sharedRoleOption = role?.docs?.find(
                            (ele) => ele._id == selectedOption.value
                        );
                        // console.log('shared promote', sharedRoleOption)
                        let assignCommittee =
                            requestData && requestData?.committee[0];
                        // console.log('committee',assignCommittee)
                        const payload = {
                            _id: assignCommittee._id,
                            populate: true,
                        };
                        const string = QueryString.stringify(payload);
                        const res = await authAxiosInstance.get(
                            `/committee?${string}`
                        );
                        const committeeData = res?.data?.data?.docs?.[0];
                        // console.log(committeeData, 'data');
                        const memberObj = committeeData?.members?.find(
                            (ele) => ele.role._id == selectedOption.value
                        );
                        // console.log('memberObj', memberObj);
                        if (!memberObj?.seats) {
                            toast.error('Seats are not allocated');
                            return;
                        }
                        formik.setFieldValue('seats', memberObj?.seats);

                        formik.setFieldValue('role', selectedOption.value);
                    }}
                />
                <FormikInputGroup
                    name="seats"
                    readOnly
                    label="Seats"
                    formik={formik}
                />
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default PromoteApprovedVolunteerVolunteer;
