import React from 'react';

const DangerButton = ({ children, className, type = 'button', ...props }) => {
    return (
        <button
            className={`btn bg-red-500 hover:bg-red-600 text-white border-0 px-4 py-1.5 font-medium ${className}`}
            type={type}
            {...props}
        >
            {children}
        </button>
    );
};

export default DangerButton;
