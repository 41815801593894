import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    editEmployee,
    fetchEmployee,
    getEmployees,
} from '../../app/reducers/Users/EmployeeSlice';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { array, object, string, number } from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import {
    editCommittee,
    fetchCommittee,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { COMMITTEE_TYPE } from '../../utils/dropdownOptions';
import { ChevronRight } from 'react-feather';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import { createEvent } from '../../app/reducers/Event/eventSlice';

const EditCommittee = () => {
    const { id } = useParams();
    const { role, loading } = useSelector(getRoles);
    const dispatch = useDispatch();
    const { elementEditData, elementEditDataLoading } =
        useSelector(getCommittees);
    useEffect(() => {
        dispatch(fetchCommittee({ _id: id }));
        dispatch(fetchRoles({ type: 'Internal',sort :{name:1} }));
    }, []);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...elementEditData,
        },
        validationSchema: object({
            name: string().required(),
            type: string().required(),
            hexCode:string(),
            members: array(),
        }),
        onSubmit: async (values, { resetForm }) => {
            if(values?.type === 'Event'){
                const committeePayload = {committee : elementEditData?._id};
                const committeeString = QueryString.stringify(committeePayload);
                const resp = await authAxiosInstance.get(`/event?${committeeString}`);
                const committeeEvent = resp?.data?.data?.docs
                let eventDifference = values?.numberOfEvents - committeeEvent.length;;
                if(values?.numberOfEvents > committeeEvent.length){
                    for(let i = 0; i < eventDifference ; i++){
                        const newEventObj = {
                            name : `${elementEditData?.name} ${committeeEvent.length + i + 1}`,
                            committee : elementEditData?._id,
                        }
                        dispatch(createEvent(newEventObj));
                    }
                }
                if(values?.numberOfEvents < committeeEvent.length){
                    toast.error('Invalid number of events');
                    return
                }
            }
            await dispatch(editCommittee(values));
            await dispatch(fetchCommittee({ _id: id }));
        
        },
    });

    
    const data = useMemo(() => (role?.docs ? role.docs : []), [role]);
    // useEffect(() => {
    //     dispatch(fetchRoles({ type: 'Internal' }));
    // }, []);

    return (
        <PageWithCard heading={
            <>
            <Link to={'/committee/view'}>Committees</Link>
            <span className="text-slate-200 inline-block px-1"><ChevronRight size={14} className='-mb-0.5' /> </span>
            <span>{'Edit Committee'}</span>
            </>
        }>
            {elementEditDataLoading ? (
                <ClipLoader color="#FFFFFF" size={18} />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4  bg-black p-4 rounded sm:rounded-md"
                >
                    <FormikInputGroup
                        name="name"
                        label="Category Name"
                        formik={formik}
                        required
                    />

                    <FormikSelectGroup
                        name="type"
                        label="Type"
                        formik={formik}
                        options={COMMITTEE_TYPE}
                    />
                    <FormikInputGroup
                        name="hexCode"
                        label="Color Code"
                        formik={formik}
                        // required
                    />
                    {formik.values.type == 'Event' ? (
                        <FormikInputGroup
                            name="numberOfEvents"
                            label="No. of Events"
                            required
                            formik={formik}
                        />
                    ) : (
                        ''
                    )}
                    <FormikProvider value={formik}>
                        <div className="text-bold text-slate-200">Members</div>
                        <FieldArray
                            name="members"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col gap-2">
                                        {/* <p>Address</p> */}
                                        <div>
                                            {formik.values.members?.map(
                                                (ele, index) => (
                                                    <div
                                                        className="relative p-4 mb-2"
                                                        style={{
                                                            border: '1px solid #d6c7c7',
                                                            borderRadius: '5px',
                                                        }}
                                                        key={index}
                                                    >
                                                        <div className="flex gap-2 ">
                                                            <FormikSelectGroup
                                                                name={`members.${index}.role`}
                                                                label="Role"
                                                                formik={formik}
                                                                options={
                                                                    data?.map
                                                                        ? data?.map(
                                                                              (
                                                                                  ele
                                                                              ) => ({
                                                                                  label: ele.name,
                                                                                  value: ele._id,
                                                                              })
                                                                          )
                                                                        : []
                                                                }
                                                            />
                                                            <FormikInputGroup
                                                                type="number"
                                                                name={`members.${index}.seats`}
                                                                formik={formik}
                                                                label="Seats"
                                                            />
                                                        </div>
                                                        <div>
                                                            <DangerButton
                                                                className="mt-3"
                                                                onClick={() => {
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    );
                                                                }}
                                                                type="button"
                                                            >
                                                                Remove
                                                            </DangerButton>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div>
                                            <PrimaryButton
                                                onClick={() => {
                                                    arrayHelpers.push({});
                                                }}
                                                type="button"
                                            >
                                                Add More
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>

                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </PageWithCard>
    );
};

export default EditCommittee;
