export const generateStateArray = (elementsArr) => {
    const state = {
        elements: {},
        elementsLoading: {},
        editElementLoading: {},
        elementEditId: {},
        elementEditData: {},
    };
    elementsArr.forEach((ele) => {
        state.elements[ele] = {};
        state.elementsLoading[ele] = false;
        state.editElementLoading[ele] = false;
        state.elementEditId[ele] = null;
        state.elementEditData[ele] = null;
    });
    return state;
};

export const generateStateSingle = (element) => {
    const state = {
        [element]: {},
        loading: false,
        editDataLoading: false,
        elementEditId: null,
        elementEditData: null,
    };

    return state;
};
