import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    editData,
    deleteData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';

const SLICE_NAME = 'role';

export const fetchRoles = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createRole = createData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchRole = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editRole = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteRole = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);

const roleSlice = createSlice({
    initialState: generateStateSingle(SLICE_NAME),
    name: SLICE_NAME,
    extraReducers: {
        ...fetchData.generateExtraReducers(fetchRoles, SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchRole, SLICE_NAME),
        ...createData.generateExtraReducers(createRole, SLICE_NAME),
        ...editData.generateExtraReducers(editRole, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteRole, SLICE_NAME),
    },
});

export const getRoles = (state) => state.roles;

export default roleSlice.reducer;
