import React, { useEffect, useMemo, useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCommittees,
    getCommittees,
} from '../../app/reducers/Committee/committeeSlice';
import { generateOptions } from '../../utils/Utils';
import { fetchRoles, getRoles } from '../../app/reducers/Role/roleSlice';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { authAxiosInstance, axiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import {
    editRegistrationRequest,
    fetchRegistrationRequests,
} from '../../app/reducers/RegistrationRequest/registrationRequestSlice';
import { getEmployees } from '../../app/reducers/Users/EmployeeSlice';
import QueryString from 'qs';
import { getUsers } from '../../app/reducers/Users/usersSlice';

const PendingReqPromote = ({
    requestData,
    showApprovedModal,
    setShowApprovedModal,
}) => {
    console.log('req data', requestData)
    const { committee } = useSelector(getCommittees);
    const { role } = useSelector(getRoles);

    const dispatch = useDispatch();
   
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            userId: requestData && requestData._id,
            firstName: requestData && requestData?.firstName,
            lastName: requestData && requestData?.lastName,
            committees: requestData
                ? [
                      requestData?.committee1?.name,
                      requestData?.committee2?.name,
                      requestData?.committee3?.name,
                  ]
                : [],
            role: '',
            committee: [],
            seats: '',
        },
        validationSchema: Yup.object({
            role: Yup.string().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('values', values);
            let canSubmit = false;
            let errorCommittee = null;
            for (let i = 0; i < values.committee.length; i++) {
                const roleOptions = committee?.docs?.find(
                    (ele) => ele?._id === values.committee[i]
                );
                console.log('role opt', roleOptions)
                const seatAvailable = roleOptions?.members?.find(
                    (ele) => ele?.role?._id === values.role
                );
                console.log('seats', seatAvailable?.seats)
                const payload = {
                    role: values?.role,
                    committee: values.committee[i],
                };

                const string = QueryString.stringify(payload);
                const allUsers = await authAxiosInstance.get(
                    `/users/employee?${string}`
                );
                const userGet = allUsers?.data?.data?.docs;
                console.log('users', userGet)
                if (seatAvailable?.seats > userGet?.length) {
                    canSubmit = true;
                    console.log('canSubmitTrue', canSubmit);
                } else {
                    canSubmit = false;
                    console.log('canSubmitFalse', !canSubmit);
                    errorCommittee = committee?.docs?.find(
                        (ele) => ele._id === values.committee[i]
                    );
                    console.log(errorCommittee);
                    toast.error(
                        `${errorCommittee.name} Committee is not assign to selected role`
                    );
                    break;
                }
            }
            if (canSubmit) {
                const data = {
                    userId: values.userId,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    role: values.role,
                    committee: values.committee,
                    // preferredCommittee: `${requestData?.committee1?._id},${requestData?.committee2?._id},${requestData?.committee3?._id}`,
                };
                console.log('dataObj', data);
                await dispatch(editRegistrationRequest(data));
                await dispatch(
                    fetchRegistrationRequests({
                        populate: true,
                        status: 'pending',
                        sort : {'committee1.name' : 1, firstName : 1}
                        // 'committee1._id': { $in: values.committee },
                    })
                );
                resetForm();
                setShowApprovedModal(false);
            }
        },
    });
    return (
        <ModalBasic
            modalOpen={showApprovedModal}
            setModalOpen={setShowApprovedModal}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 p-4"
            >
                <FormikInputGroup
                    label="First Name"
                    name="firstName"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="Last Name"
                    name="lastName"
                    formik={formik}
                    readOnly
                />
                <FormikInputGroup
                    label="Selected Committees"
                    name="committees"
                    formik={formik}
                    readOnly
                />
                <FormikMultiSelect
                    required
                    label="Committee"
                    name="committee"
                    formik={formik}
                    options={generateOptions({
                        array: committee ? committee?.docs : [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                />
                <FormikSelectGroup
                    required
                    label="Role"
                    name="role"
                    formik={formik}
                    options={generateOptions({
                        array: role
                            ? role?.docs?.filter(
                                  (ele) => !['Super User', 'Developer'].includes(ele?.name)
                              )
                            : [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                    onChange={async (selectedOption) => {
                        const sharedRoleOption = role?.docs?.find(
                            (ele) => ele._id == selectedOption.value
                        );
                        console.log(sharedRoleOption.sharedRole);

                        if (formik?.values?.committee?.length !== 1) {
                            formik.setFieldValue('seats', 'NA');
                            if (sharedRoleOption.sharedRole) {
                                formik.setFieldValue(
                                    'role',
                                    selectedOption.value
                                );
                            } else {
                                alert(
                                    'Role not allowed to share between committee'
                                );
                                formik.setFieldValue('role', '');
                            }
                        } else {
                            let selectedCommittee =
                                formik?.values?.committee?.[0];
                            const payload = {
                                _id: selectedCommittee,
                                populate: true,
                            };
                            const string = QueryString.stringify(payload);
                            const res = await authAxiosInstance.get(
                                `/committee?${string}`
                            );
                            const committeeData = res?.data?.data?.docs?.[0];
                            console.log(committeeData);
                            const memberObj = committeeData?.members?.find(
                                (ele) => ele.role._id == selectedOption.value
                            );
                            if (!memberObj?.seats) {
                                toast.error('Seats are not allocated');
                                return;
                            }
                            formik.setFieldValue('seats', memberObj.seats);

                            formik.setFieldValue('role', selectedOption.value);
                        }
                    }}
                />
                <FormikInputGroup
                    name="seats"
                    readOnly
                    label="Seats"
                    formik={formik}
                />
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default PendingReqPromote;
