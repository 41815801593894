import React, { useEffect, useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikTime from '../../components/formik/FormikTime';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { editEvent, fetchEvents } from '../../app/reducers/Event/eventSlice';
import { toast } from 'react-toastify';

const EditEventSchedule = ({
    requestData,
    showApproveModal,
    setShowApproveModal,
    setRequestData
}) => {
    console.log('req date Edit', requestData);
    const dispatch = useDispatch();
    const convertCustomTimeToDate = (selectedDate, customTime) => {
        const milliseconds = parseInt(selectedDate, 10);
        const date = new Date(milliseconds);
        const formattedDateString = date.toString();
        console.log('values', formattedDateString);
        console.log('values1', customTime);
        const timeString = customTime.split(' ')[4];

        const [hours, minutes, seconds] = timeString.split(':').map(Number);

        const newDate = new Date(formattedDateString);

        newDate.setHours(hours);
        newDate.setMinutes(minutes);
        newDate.setSeconds(seconds);

        return newDate;
    };
    const [initialValues, setInitialValues] = useState({
        date: moment(requestData?.startTime).startOf('day').valueOf(),
        startTime: moment(requestData?.startTime).toDate(),
        endTime: moment(requestData?.endTime).toDate(),
        venue: requestData?.venue || '',
    });

    useEffect(() => {
        setInitialValues({
            date: moment(requestData?.startTime).startOf('day').valueOf(),
            startTime: moment(requestData?.startTime).toDate(),
            endTime: moment(requestData?.endTime).toDate(),
            venue: requestData?.venue || '',
        });
    }, [requestData]);
    useEffect(()=>{
        if(!showApproveModal){
            setRequestData({})
        }
    },[showApproveModal])
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: object({
            date: string().required(),
            startTime: string().required(),
            endTime: string().required(),
            venue: string().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('schedule', values);
            let newStartTime = convertCustomTimeToDate(
                values?.date,
                values?.startTime.toString()
            );
            let newEndTime = convertCustomTimeToDate(
                values?.date,
                values?.endTime.toString()
            );
            if(newEndTime <= newStartTime){
                toast.error('End-Time must be after the start-Time');
                return
            }
            let editObj = {
                ...requestData,
                status: 'scheduled',
                startTime: newStartTime,
                endTime: newEndTime,
                venue: values?.venue,
            };
            console.log('eventScheduleObj', editObj);
            await dispatch(editEvent(editObj));
            dispatch(fetchEvents({ populate: true, sort: { startTime: 1 } }));
            setShowApproveModal(false);
        },
    });
    return (
        <ModalBasic
            modalOpen={showApproveModal}
            setModalOpen={setShowApproveModal}
        >
            <form
                className="flex flex-col gap-4 p-4"
                onSubmit={formik.handleSubmit}
            >
                <FormikInputDateGroup
                    required
                    formik={formik}
                    label="Date"
                    name="date"
                />
                <FormikTime
                    required
                    formik={formik}
                    showTimeInput
                    label="Start Time"
                    name="startTime"
                />
                <FormikTime
                    required
                    formik={formik}
                    showTimeInput
                    label="End Time"
                    name="endTime"
                />
                <FormikInputGroup
                    required
                    formik={formik}
                    label="Venue"
                    name="venue"
                    className="w-auto rounded-md border-0 text-slate-200"
                />
                <div>
                    <PrimaryButton type="submit">Update</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default EditEventSchedule;
