import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikINPhoneInput from '../../components/formik/FormikINPhoneInput';
import { object, string, array, ref } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
    editParticipant,
    editParticipantSubstitute,
    fetchParticipants,
} from '../../app/reducers/Participant/teamParticipantSlice';
import { fetchScoringTemplates } from '../../app/reducers/ScoringTemplate/scoringTemplateSlice';
import { createScoreBoard } from '../../app/reducers/Scoreboard/scoreboardSlice';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { useParams } from 'react-router-dom';
import { data } from 'autoprefixer';
import { toast } from 'react-toastify';

const SubstituteModal = ({
    requestData,
    participantTeamData,
    showSubstituteModal,
    setShowSubstituteModal,
    eventElementData,
    computeTeamMaxParticipants
}) => {
    console.log('modal req', requestData);
    console.log('modal parti', participantTeamData);
    console.log('modal eventEle', eventElementData);
    const { user } = useSelector(getAuth);

    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            contactNumber: '',
        },
        validationSchema: object({
            firstName: string()
                .matches(
                    /^[A-Z][a-z]*$/,
                    'Case sensitive & can not contain spaces. i.e. Hello'
                )
                .label('First Name')
                .required(),
            lastName: string()
                .matches(
                    /^[A-Z][a-z]*$/,
                    'Case sensitive & can not contain spaces. i.e. Hello'
                )
                .label('Last Name')
                .required(),
            contactNumber: string()
                .matches(
                    /^[0-9]{10}$/,
                    'Contact Number must be numbers (exact 10 digits)'
                )
                .min(10, 'Min 10 digits')
                .max(10, 'Max 10 digits')
                .label('Contact Number')
                .required(),
        }),
        onSubmit: async(values, { resetForm }) => {
            console.log('substitute values', values);

            // let updateTeamData = participantTeamData.participants.map(
            //     (participant) =>
            //         participant._id === requestData._id
            //             ? { ...participant, participantStatus: 'substituted' }
            //             : participant
            // );
            // // console.log('edit2', updateTeamData)

            // let obj = {
            //     _id: participantTeamData?._id,
            //     participants: updateTeamData,
            // };
            // console.log('edit3', obj);
            // await dispatch(
            //     editParticipant({
            //         _id: participantTeamData?._id,
            //         participants: updateTeamData,
            //     })
            // );
            const resp = await dispatch(
                editParticipantSubstitute({
                    _id: participantTeamData?._id,
                    participantTeamData,
                    requestData,
                    values : { ...values, participantStatus: 'substitute'},
                })
            );

            console.log('resp', resp)
            
            console.log('check', resp?.payload?.data?.substitutes?.length, '>', eventElementData?.noOfSubstitution)
            if( resp?.payload?.data?.substitutes?.length > eventElementData?.noOfSubstitution){
                const eventScoreName = (level, type) => {
                    let capitalLevel =
                        level.charAt(0).toUpperCase() + level.slice(1);
                    let capitalType =
                        type.charAt(0).toUpperCase() + type.slice(1);
                    return `substitution${capitalLevel}${capitalType}`;
                };
                let  eventScoreType = eventScoreName(eventElementData.level, participantTeamData?.type);
                const resp = await dispatch(fetchScoringTemplates({ name : eventScoreType}));
                const scoringData = resp?.payload?.data?.docs[0]
                console.log('scoring name data', scoringData)
                console.log('rank', eventScoreType)
                let scoreObj = {
                    college :participantTeamData?.college,
                    team :participantTeamData?._id,
                    rank : eventScoreType,
                    points : scoringData?.points,
                    type: participantTeamData?.type,
                    notes : 'Substitutes ranks',
                    event : participantTeamData?.event,
                    status : 'approved',
                }
                console.log('score obj', scoreObj)

                const dataResp = await dispatch(createScoreBoard(scoreObj));
                if(dataResp?.payload?.success){
                    toast.success(`Deducted ${dataResp?.payload?.data?.points} points for participant substitution`)
                }
            }
            await dispatch(
                fetchParticipants({
                    event: eventElementData?._id,
                    college: user?.college?._id,
                    'participants.participantStatus' : {$in : ['regular', 'substitute']}
                })
            );
            
            resetForm();
            setShowSubstituteModal(false);
        },
    });
    return (
        <ModalBasic
            modalOpen={showSubstituteModal}
            setModalOpen={setShowSubstituteModal}
        >
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 pb-4 bg-black p-4 rounded sm:rounded-md"
            >
                <div className="flex flex-col gap-4 sm:flex-row w-full">
                    <div className="w-full">
                        <FormikInputGroup
                            required
                            formik={formik}
                            label="First Name"
                            name="firstName"
                        />
                    </div>
                    <div className="w-full">
                        <FormikInputGroup
                            required
                            formik={formik}
                            label="Last Name"
                            name="lastName"
                        />
                    </div>
                    <div className="w-full">
                        <FormikINPhoneInput
                            required
                            formik={formik}
                            name="contactNumber"
                            label="Phone No."
                        />
                    </div>
                </div>
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default SubstituteModal;
