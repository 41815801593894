export const findRelational = (schema) => {
    const relational = [];
    for (let i = 0; i < Object.keys(schema).length; i++) {
        const key = Object.keys(schema)[i];
        const relationalObj = computeRelationalStatus(schema[key], key);
        if (relationalObj?.length) {
            relational.push(...relationalObj);
        }
    }
    return relational;
};

const computeRelationalStatus = (fieldData, key) => {
    let output = [];

    if (fieldData?.fields) {
        for (let i = 0; i < Object.keys(fieldData.fields).length; i++) {
            const element = Object.keys(fieldData.fields)[i];
            let data = computeRelationalStatus(
                fieldData.fields[element],
                `${key}.${element}`
            );

            output.push(...data);
        }
    } else if (['relational', 'relationalArray'].includes(fieldData.type)) {
        let name = key;
        let ref = fieldData.ref;
        output.push({ name, ref });
    }
    return output;
};
