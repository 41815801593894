import { createSlice } from "@reduxjs/toolkit";
import { generateStateSingle } from "../../generators/generateState";
import { createData, deleteData, editData, fetchData, fetchEditData } from "../../generators/generateThunks";


const SLICE_NAME = 'scorecard';

export const createScorecard = createData.generateThunk(SLICE_NAME,SLICE_NAME);

export const fetchScorecards = fetchData.generateThunk(SLICE_NAME,SLICE_NAME);

export const fetchScorecard = fetchEditData.generateThunk(SLICE_NAME,SLICE_NAME);

export const editScorecard = editData.generateThunk(SLICE_NAME,SLICE_NAME);

export const deleteScorecard = deleteData.generateThunk(SLICE_NAME,SLICE_NAME);

const scorecardSlice = createSlice({
    name : SLICE_NAME,
    initialState : generateStateSingle(SLICE_NAME),
    extraReducers:{
        ...createData.generateExtraReducers(createScorecard,SLICE_NAME),
        ...fetchData.generateExtraReducers(fetchScorecards ,SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchScorecard, SLICE_NAME),
        ...editData.generateExtraReducers(editScorecard, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteScorecard,SLICE_NAME)
    }
});

export const getScorecards = (state) => state.scorecard;

export default scorecardSlice.reducer;