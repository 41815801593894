import { FormikProvider } from 'formik';
import { Reorder } from 'framer-motion';

const FormikDraggableProvider = ({ formik, children, axis = 'y', name }) => {
    return (
        <FormikProvider value={formik}>
            <Reorder.Group
                axis={axis}
                values={
                    _.at(formik.values, name)?.[0]
                        ? _.at(formik.values, name)?.[0]
                        : []
                }
                onReorder={(reOrderedArray) => {
                    console.log(reOrderedArray);
                    formik.setFieldValue(name, reOrderedArray);
                }}
            >
                {children}
            </Reorder.Group>
        </FormikProvider>
    );
};

export default FormikDraggableProvider;