import {Link, useLocation} from "react-router-dom";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ChevronDown, ChevronRight, MoreHorizontal } from "react-feather";

const ExpandableMenuLinks = ({
  link,
  setOpenExpMenu,
  openExpMenu,
  setCurrentItemOpen,
  currentItemOpen,
  ...props
}) => {
  // console.log("currentItemOpen", link._id)
  const [isActiveMobile, setIsActiveMobile] = useState(false);
  const [expItemOpen, setExpItemOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const slug = searchParams.get('slug');
  const {pathname} = location;
  // const router = useRouter();
  // console.log("link", link);
  // console.log("setopen", currentItemOpen);
  return (
    <>
      {true ? (
        <div>
          <div className="div flex flex-col  p-8  ">
            <div className="  dropdown cursor-pointer font-normal text-base uppercase tracking-wide ">
              <div className="flex items-center gap-2">
                <Link href={link?.href ? link.href : "#!"} passHref>
                  <a
                    onClick={() => setOpenExpMenu(false)}
                    className={`text-xl md:text-base my-4 md:my-0 text-[#000000ee] md:text-[#000000] ${
                      pathname === link.href
                        ? "md:border-b-2 border-[#96BC70]"
                        : slug == link.href.slice(1)
                        ? "md:border-b-2 border-[#96BC70]"
                        : {}
                    } `}
                    target={link.target === "new_page" ? "_blank" : "_self"}
                  >
                    {link.title}
                  </a>
                </Link>
                {link.title && link  && (
                  <>
                    {link._id === currentItemOpen ? (
                      <ChevronRight onClick={() => setCurrentItemOpen("")} />
                    ) : (
                      <ChevronDown
                        onClick={() => setCurrentItemOpen(link._id)}
                      />
                    )}
                  </>
                )}
              </div>
              <div></div>
            </div>
          </div>
          <div>
            {link._id === currentItemOpen && (
              <div className="hidden md:block absolute right-0 top-24  h-screen w-[60%] lg:w-[75%] bg-white shadow p-8 ">
                <h1 className="m-2 ml-4    text-2xl font-normal uppercase">
                  {link.title}
                </h1>
                <div className="  font-normal p-2 bg-white pr-8 flex flex-wrap ">
                  {link.links.length > 0 &&
                    link.links.map((el) => (
                      <ul
                        key={el._id}
                        className="  list-none top-0 w-[25%] "
                        style={{
                          all: "unset",
                          listStyle: "none",
                          width: "25%",
                        }}
                      >
                        <li className=" p-2 pl-6 hover:text-[#96BC70]">
                          <Link to={el?.href ? el.href : "#!"} passHref>
                            <a
                              onClick={() => setOpenExpMenu(false)}
                              className={`flex flex-col pb-2 hover:text-[#96BC70] md:my-0 text-[#333] ${
                                router.pathname === el.href
                                  ? "md:border-b-2 border-[#96BC70]"
                                  : {}
                              } `}
                              target={
                                el.target === "new_page" ? "_blank" : "_self"
                              }
                            >
                              {el?.image?.url && (
                                <Link href={el?.href ? el.href : "#!"} passHref>
                                  <Image
                                    height={150}
                                    width={150}
                                    objectFit="cover"
                                    src={el?.image?.url}
                                    alt=""
                                    style={{ paddingBottom: ".45rem" }}
                                  />
                                </Link>
                              )}
                              {el.title}
                            </a>
                          </Link>
                        </li>
                      </ul>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="div flex flex-col ">
          <div className="group  relative dropdown cursor-pointer font-normal text-base uppercase tracking-wide ">
            <Link href={link?.href ? link.href : "#!"} passHref>
              <a
                className={`text-xl md:text-base my-4 md:my-0 text-[#000c47ee] md:text-[#001547] ${
                  router.pathname === link.href
                    ? "md:border-b-2 border-[#96BC70]"
                    : router.query.slug == link.href.slice(1)
                    ? "md:border-b-2 border-[#96BC70]"
                    : {}
                } `}
                target={link.target === "new_page" ? "_blank" : "_self"}
              >
                {link.title && link.links.length > 0 ? (
                  <span className="flex flex-row">
                    {link.title} <ChevronDown />
                  </span>
                ) : (
                  link.title
                )}
              </a>
            </Link>
            <div className="group-hover:block dropdown-menu absolute hidden h-auto w-60 pt-8  ">
              {link.links.length > 0 && (
                <div className="w-60 bg-white  p-2 rounded-lg">
                  {link.links.map((el) => (
                    <ul key={el._id} className="list-none top-0 p-1">
                      <li className="top-0 transition  delay-150 hover:translate-x-4  duration-300 ">
                        <Link href={el?.href ? el.href : "#!"} passHref>
                          <a
                            className={`text-sm md:text-md  md:my-0 text-[#333] ${
                              router.pathname === el.href
                                ? "md:border-b-2 border-[#96BC70]"
                                : {}
                              // : router.query.slug == el.href.slice(1)
                              // ? "md:border-b-2 border-blue-900"
                              // : {}
                            } `}
                            target={
                              el.target === "new_page" ? "_blank" : "_self"
                            }
                          >
                            {el.title}
                          </a>
                        </Link>
                      </li>
                    </ul>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExpandableMenuLinks;
